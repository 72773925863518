(function () {
  'use strict';

  angular
    .module('ss.client.public-pages')
    .component('publicPageComponent', {
      controller: PublicPageComponent,
      bindings: {},
      templateUrl: 'app/client/public-pages/public-page/public-page.component.html'
    });

  PublicPageComponent.$inject = ['APP_CONFIG', 'authService'];

  function PublicPageComponent(APP_CONFIG, authService) {
    const me = this;

    me.$onInit = onInit;
    me.login = login;

    function onInit() {
      me.logoLinkHref = APP_CONFIG.ROUTE_PREFIX;
    }

    function login() {
      authService.login();
    }

  }
})();
