(function () {
  'use strict';
  angular
    .module('ss.client.pages')
    .component('authCallbackPageComponent', {
      controller: function () {},
      bindings: {},
      templateUrl: 'app/client/pages/auth-callback-page/auth-callback-page.component.html'
    });
})();
