(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .provider('customEcommerceAnalytics', customEcommerceAnalyticsProvider);

  function customEcommerceAnalyticsProvider() {
    let ecommerceOn = false;
    this.enableEcommerce = enableEcommerce;

    function enableEcommerce(value) {
      ecommerceOn = value;
    }

    this.$get = customEcommerceAnalyticsService;

    customEcommerceAnalyticsService.$inject = [];

    function customEcommerceAnalyticsService() {
      const service = {
        addTransaction: addTransaction,
        addItem: addItem,
        trackTrans: trackTrans,
        clearTrans: clearTrans
      };
      return service;

      ///////////////////////////////

      function addTransaction(id, affiliation, revenue, shipping, tax) {
        const transaction = {
          id: id,
          affiliation: affiliation,
          revenue: revenue,
          shipping: shipping,
          tax: tax
        };
        if (ecommerceOn) {
          ga('ecommerce:addTransaction', transaction);
          ga('require', 'ecommerce');
        }
      }

      function addItem(id, planEnum, price, quantity, skuSuffix) {
        const item = {
          id: id,
          name: planEnum,
          sku: planEnum + skuSuffix,
          category: planEnum,
          price: price,
          quantity: quantity
        };
        if (ecommerceOn) {
          ga('require', 'ecommerce');
          ga('ecommerce:addItem', item);
        }
      }

      function trackTrans() {
        if (ecommerceOn) {
          ga('ecommerce:send');
        }
      }

      function clearTrans() {
        ga('ecommerce:clear');
      }
    }

  }
})();
