(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('projectDetailViewPageComponent', {
      controller: ProjectDetailViewPageComponent,
      bindings: {},
      templateUrl: 'app/admin/pages/project-detail-page/project-detail-view-page.component.html'
    });

  ProjectDetailViewPageComponent.$inject = ['stateService', '$stateParams', 'adminUserService', '$uibModal', 'sharedService',
    'projectAdminService', 'notificationService', 'sharedProjectsService', 'adminTeamService', 'timecodeHelper', 'projectService'
  ];

  function ProjectDetailViewPageComponent(stateService, $stateParams, adminUserService, $uibModal, sharedService,
    projectAdminService, notificationService, sharedProjectsService, adminTeamService, timecodeHelper, projectService) {

    const me = this;
    const projectId = $stateParams.projectId;
    const temp = [];
    let duration;
    let teamdetails = [];
    me.usersListing = [];
    let usersList = [];
    let projectOwnerEmail;
    me.retryJobFlag = false;
    me.frameRateOptions = ['23.976', '24', '25', '29.97', '30', '50', '59.94', '60'];
    me.project = null;
    me.selectedUserItem = null;
    me.files = [];
    me.assignToNewUser = false;

    me.$onInit = onInit;
    me.deleteProject = deleteProject;
    me.getName = getName;
    me.getFrameSeparatorString = getFrameSeparatorString;
    me.updateMediaDetails = updateMediaDetails;
    me.deleteMediaFile = deleteMediaFile;
    me.updateProject = updateProject;
    me.userDetailedView = userDetailedView;
    me.openExportModal = openExportModal;
    me.isTeamMember = isTeamMember;
    me.shareProject = shareProject;
    me.retryJob = retryJob;
    me.mergeProject = mergeProject;
    me.goToTranscript = goToTranscript;
    me.loadOriginalProjectDetailView = loadOriginalProjectDetailView;
    me.querySearch = querySearch;
    me.selectedItemChange = selectedItemChange;
    me.retryTranscoding = retryTranscoding;

    function onInit() {
      projectAdminService.getProject(projectId).then(response => {
        me.project = response;
        me.project.failedMediaEventLogs = response.failedMediaEventLogs.map(eventLog => {
          return {
            logData: angular.fromJson(eventLog.logData),
            timestampInUtc: eventLog.timestampInUtc,
          };
        });
        projectOwnerEmail = me.project.owner.email;
        me.selectedUserItem = me.project.owner;
        me.files = response.files;
        me.projectName = response.name;
        me.project.publishedUrl = sharedProjectsService.getProjectPublicLink(me.project);
        for (let i = 0; i < me.files.length; i++) {
          const file = me.files[i];
          if (me.project.status === 'failed' && file.status === 'failed') {
            me.retryJobFlag = true;
          }
          file.fps = String(file.fps);
          file.mediaTimecode = timecodeHelper.getMediaTimeCode(file);
          duration = me.files[i].duration;
          file.duration_ = timecodeHelper.toHHMMSSFormat(duration);
          temp.push(file);
        }
        me.files = temp;
      });
      adminTeamService.getTeams().then(response => teamdetails = response);
      adminUserService.getAll().then(response => {
        me.usersListing = response.filter(user => user.fullName && user.email).map(user => ({ fullName: user.fullName, email: user.email }));
        usersList = me.usersListing;
      });
    }

    function getFrameSeparatorString(media) {
      return timecodeHelper.getFrameSeparatorString(media)
    }

    function deleteProject(projectId) {
      notificationService.confirm('Are you sure you want to delete this project?')
        .then(() => projectService.removeProject(projectId).then(() => latestTransaction()))
        .catch(() => { });
    }

    function getName(item) {
      return `${item.fullName} (${item.email})`;
    }

    function updateMediaDetails(file) {
      const time = file.mediaTimecode.time;
      if (time) {
        file.userDefinedTimeCode = moment.duration(time)._milliseconds;
        file.userDefinedTimeCodeFrames = parseInt(file.mediaTimecode.frames, 10);
      }
      projectAdminService.updateMedia(file.id, file);
    }

    function deleteMediaFile(file) {
      notificationService.confirm('Are you sure you want to delete this media?')
        .then(() => projectAdminService.deleteMediaFile(file.id))
        .catch(() => { });
    }

    function updateProject(users) {
      const payload = {
        name: me.projectName,
        userEmail: ''
      };
      if (me.selectedUserItem && me.selectedUserItem.email !== null && typeof me.selectedUserItem.email !== 'undefined') {
        payload.userEmail = me.selectedUserItem.email;
      }

      if (users) {
        projectAdminService.updateProject(payload, projectId);
        me.usersListing = angular.copy(usersList);
      } else {
        projectAdminService.updateProject(payload, projectId).then(() => onInit());
      }
    }

    function userDetailedView(id) {
      stateService.goTo('app.admin.userManagementItem', {
        userId: id
      });
    }

    function latestTransaction() {
      stateService.goTo('admin.latestTransaction');
    }

    function openExportModal() {
      let shortestMediaDuration = Number.MAX_VALUE;
      let shortestMedia;
      if (me.project.files && me.project.files.length) {
        me.project.files.filter(media => media.status && media.status !== 'empty' && media.duration > 0).forEach(media => {
          if (media.duration < shortestMediaDuration) {
            shortestMediaDuration = media.duration;
            shortestMedia = media;
          }
        });
      }
      const currentMedia = shortestMedia;
      $uibModal.open({
        animation: true,
        component: 'exportTranscriptionModal',
        resolve: {
          mediaCount: () => angular.isArray(me.project.medias) ? me.project.medias.length : 0,
          currentMediaId: () => currentMedia.id,
          project: () => me.project
        }
      });
    }

    function isTeamMember() {
      if (me.project && me.project.owner && me.project.owner.team) {
        return true;
      }
      if (teamdetails && teamdetails.length) {
        for (let i = 0; i < teamdetails.length; i++) {
          if (projectOwnerEmail === teamdetails[i].owner) {
            return true;
          }
        }
      }
    }

    function shareProject() {
      $uibModal.open({
        animation: true,
        backdrop: 'static',
        component: 'shareProjectModal',
        resolve: {
          project: () => me.project,
          isProjectOwner: true,
          isTeamMember: isTeamMember,
          isFromAdmin: true
        }
      });
    }

    function retryJob() {
      projectAdminService.retryJobRequest(projectId);
    }

    function retryTranscoding(mediaId) {
      projectAdminService.retryTranscoding(mediaId);
    }

    function mergeProject() {
      stateService.goTo('app.admin.projectMerging', {
        projectId: projectId,
        userId: me.project.owner.id
      });
    }

    function loadOriginalProjectDetailView() {
      stateService.goTo('app.admin.projectManagementItem', {
        projectId: me.project.translatedFromProjectId
      });
    }

    function goToTranscript() {
      sharedService.redirectToProject(me.project);
    }

    function querySearch(searchText) {
      if (searchText) {
        me.usersListing = usersList.filter(item => item.fullName.includes(searchText) || item.email.includes(searchText))
      } else {
        me.usersListing = angular.copy(usersList);
      }
    }

    function selectedItemChange(item) {
      if (item) {
        me.selectedUserItem = item;
        me.assignToNewUser = false;
        updateProject(true);
      }
    }

  }
})();
