(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('blackWhiteListPageComponent', {
      controller: BlackWhiteListPageComponent,
      bindings: {},
      templateUrl: 'app/admin/pages/black-white-list-page/black-white-list-page.component.html'
    });

  BlackWhiteListPageComponent.$inject = ['blackWhiteListService', 'defaultConfigService', 'notificationService', 'sharedService'];

  function BlackWhiteListPageComponent(blackWhiteListService, defaultConfigService, notificationService, sharedService) {
    const me = this;

    me.$onInit = onInit;
    me.addBlackList = addBlackList;
    me.addBlackListIps = addBlackListIps;
    me.addWhiteList = addWhiteList;
    me.blacklistSwitch = blacklistSwitch;
    me.updateConfig = updateConfig;
    me.whitelistSwitch = whitelistSwitch;
    me.removeFromBlackList = removeFromBlackList;
    me.removeFromWhiteList = removeFromWhiteList;
    me.removeFromBlackListIps = removeFromBlackListIps;
    me.user = sharedService.getUser();
    me.cancelUpdate = cancelUpdate;
    me.blackList = [];
    me.whiteList = [];
    me.blackListIps = [];
    me.event = {};
    me.blacklistTxt = '';
    me.blacklistIpsTxt = '';
    me.whitelistTxt = '';
    me.defaultConfig = {};

    let blackListWhiteListConfigId = '';
    let savedDefaultConfig = {};

    function onInit() {
      load();
      defaultConfigService.getDefaultConfig().then(response => {
        if (response) {
          me.defaultConfig = response;
          savedDefaultConfig = angular.copy(me.defaultConfig);
        }
      });
    }

    function load() {
      blackWhiteListService.getAll().then(response => {
        me.whiteList = response[0].whiteListIds;
        me.blackList = response[0].blackListIds;
        me.blackListIps = response[0].blackListIps;
        blackListWhiteListConfigId = response[0].id;
        me.event = {
          type: {
            checked: response[0].blackListAll
          }
        };
        me.whitelist = {
          type: {
            checked: response[0].whiteListAll
          }
        };
        me.addBlackListBtn = me.event.type.checked ? true : false;
        me.addWhiteListBtn = me.whitelist.type.checked ? true : false;
      });
    }

    function addBlackList() {
      blackWhiteListService.save({
        blackListIds: me.blacklistTxt,
        id: blackListWhiteListConfigId
      }).then(() => {
        me.blacklistTxt = '';
        load();
      });
    }

    function addBlackListIps() {
      blackWhiteListService.save({
        blackListIps: me.blacklistIpsTxt,
        id: blackListWhiteListConfigId
      }).then(() => {
        me.blacklistIpsTxt = '';
        load();
      });
    }

    function addWhiteList() {
      blackWhiteListService.save({
        whiteListIds: me.whitelistTxt,
        id: blackListWhiteListConfigId
      }).then(() => {
        me.whitelistTxt = '';
        load();
      });
    }

    function blacklistSwitch() {
      let blacklistSwitchVal = '';
      let whitelistSwitchVal = '';
      if (me.event.type.checked) {
        me.addBlackListBtn = true;
        blacklistSwitchVal = 1;
        whitelistSwitchVal = 0;
        if (me.whitelist.type.checked) {
          blacklistSwitchVal = 1;
          whitelistSwitchVal = 0;
        }
      } else {
        me.addBlackListBtn = false;
        blacklistSwitchVal = 0;
        whitelistSwitchVal = 1;
        if (!me.whitelist.type.checked) {
          blacklistSwitchVal = 0;
          whitelistSwitchVal = 0;
        }
      }
      const payload = {
        blackListAll: blacklistSwitchVal,
        whiteListAll: whitelistSwitchVal,
        id: blackListWhiteListConfigId
      };
      blackWhiteListService.save(payload).then(() => {
        me.whitelistTxt = '';
        load();
      });
    }

    function whitelistSwitch() {
      let blacklistSwitchVal = '';
      let whitelistSwitchVal = '';
      if (me.whitelist.type.checked) {
        me.addWhiteListBtn = true;
        whitelistSwitchVal = 1;
        blacklistSwitchVal = 0;
        if (me.event.type.checked) {
          whitelistSwitchVal = 1;
          blacklistSwitchVal = 0;
        }
      } else {
        me.addWhiteListBtn = false;
        blacklistSwitchVal = 1;
        whitelistSwitchVal = 0;
        if (!me.event.type.checked) {
          blacklistSwitchVal = 0;
          whitelistSwitchVal = 0;
        }
      }
      const payload = {
        blackListAll: blacklistSwitchVal,
        whiteListAll: whitelistSwitchVal,
        id: blackListWhiteListConfigId
      };
      blackWhiteListService.save(payload).then(() => {
        me.whitelistTxt = '';
        load();
      });
    }

    function updateConfig() {
      const {
        ipNumOfUsers,
        ipHours
      } = savedDefaultConfig;

      if (ipNumOfUsers !== me.defaultConfig.ipNumOfUsers || ipHours !== me.defaultConfig.ipHours) {
        defaultConfigService.updateDefaultConfig(me.defaultConfig).then(() => {
          notificationService.success('Detail have been updated successfully.');
          savedDefaultConfig = angular.copy(me.defaultConfig);
          me.defaultConfigForm.$setPristine();
        });
      }
    }

    function removeFromBlackList(blackListAll) {
      blackWhiteListService.deleteReq({
        blackListIds: blackListAll,
        id: blackListWhiteListConfigId
      }).then(() => {
        me.whitelistTxt = '';
        load();
      });
    }

    function removeFromWhiteList(whiteListAll) {
      blackWhiteListService.deleteReq({
        whiteListIds: whiteListAll,
        id: blackListWhiteListConfigId
      }).then(() => {
        me.whitelistTxt = '';
        load();
      });
    }

    function removeFromBlackListIps(blackListAll) {
      blackWhiteListService.deleteReq({
        blackListIps: blackListAll,
        id: blackListWhiteListConfigId
      }).then(() => {
        me.blacklistIpsTxt = '';
        load();
      });
    }

    function cancelUpdate() {
      me.defaultConfigForm.$setPristine();
      me.defaultConfig = angular.copy(savedDefaultConfig);
    }

  }
}());
