(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('publicTranscriptModal', {
      controller: publicTranscriptModalComponent,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/public-transcript-modal/public-transcript-modal.component.html'
    });

  publicTranscriptModalComponent.$inject = ['$uibModal', 'sharedProjectsService', 'publicTranscriptService', 'dataService', 'projectService', 'notificationService', 'usersService', 'stateService'];

  function publicTranscriptModalComponent($uibModal, sharedProjectsService, publicTranscriptService, dataService, projectService, notificationService, usersService, stateService) {
    const me = this;

    me.$onInit = onInit;
    me.cancel = cancel;
    me.publicTranscript = publicTranscript;
    me.checkAudioOnly = checkAudioOnly;
    me.checkVideo = checkVideo;
    me.useDefaultImage = useDefaultImage;
    me.editTitle = editTitle
    me.editDescription = editDescription
    me.editDateGiven = editDateGiven


    function onInit() {
      me.project = me.resolve.project;
      me.formatVideo = 'video'
      me.isCheckedVideo = false
      me.isCheckedAudioOnly = false
      me.isCheckedUseDefaultImage = false
      me.showError = false
      me.titleMissing = false
      me.descriptionMissing = false
      me.dateGivenMissing = false
    }

    function editTitle () {
      me.titleMissing = false
    }

    function editDateGiven () {
      me.dateGivenMissing = false
    }

    function editDescription () {
      me.titleMissing = false
    }

    function checkAudioOnly() {
      me.formatVideo = 'audio only'
      me.isCheckedVideo = false
      me.isCheckedAudioOnly = !me.isCheckedAudioOnly
      me.showError = false
    }
    function checkVideo() {
      me.formatVideo = 'video'
      me.isCheckedVideo = !me.isCheckedVideo
      me.isCheckedAudioOnly = false
      me.showError = false
      me.isCheckedUseDefaultImage = false
    }

    function useDefaultImage() {

      me.isCheckedUseDefaultImage = !me.isCheckedUseDefaultImage
      console.log('go ghere', me.isCheckedUseDefaultImage)
    }

    function publicTranscript(projectId) {
      let projectPublic = projectService.get(projectId).then(response => {

        if (response.medias) {
          console.log(response.medias)
          let link = sharedProjectsService.getProjectTranscriptPublicLink(response)
          let dateGiven = me.dateGiven ? `${new Date(me.dateGiven).getTime()}` : response.creationDate
          let thumbnailUrl = null
          if (me.isCheckedUseDefaultImage) {
            thumbnailUrl = null
          }
          else {
            thumbnailUrl = me.thumbnail
          }

          if ((!me.isCheckedVideo && !me.isCheckedAudioOnly) || !me.title || !me.description || !me.dateGiven) {
            if(!me.isCheckedVideo && !me.isCheckedAudioOnly) {
              me.showError = true
            }
            if (!me.title) {
              me.titleMissing = true
            }
            if(!me.description) {
              me.descriptionMissing = true
            }
            if(!me.dateGiven) {
              me.dateGivenMissing = true
            }
          }
          else {
            let publicTranscrip = publicTranscriptService.publicTranscript(
              response.medias[0].id,
              me.title,
              response.medias[0].name,
              response.medias[0].frameFilePath,
              response.medias[0].videoFilePath,
              response.projectName,
              dateGiven,
              link,
              me.description,
              thumbnailUrl,
              me.formatVideo
            )
            cancel()
          }
        }
      })
    }

    function cancel() {
      me.dismiss();
    }
  }
})();
