(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .factory('languagesService', languagesService);

  languagesService.$inject = ['dataService', 'storageService'];

  function languagesService(dataService, storageService) {
    const endPoint = 'languages';
    const service = {
      getAll: getAll,
    };
    return service;

    ////////////////

    function getAll() {
      return dataService.get(endPoint).then(languages => {
        storageService.set('languages', languages);
        return languages;
      });
    }

  }
})();
