(function () {
  'use strict';

  angular
    .module('ss.common.services')
    .factory('storageService', storageService);

  storageService.$inject = ['store'];

  function storageService(store) {
    return store.getNamespacedStore('ss.ai');
  }

})();
