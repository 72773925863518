(function () {
  'use strict';

  angular
    .module('ss.admin.helpers')
    .factory('timecodeHelper', timecodeHelper);

  timecodeHelper.$inject = [];

  function timecodeHelper() {
    const helper = {
      getMediaTimeCode: getMediaTimeCode,
      toHHMMSSFormat: toHHMMSSFormat,
      getFrameSeparatorString: getFrameSeparatorString
    };
    return helper;

    //////////////////////

    function getMediaTimeCode(media) {
      const mediaTimecode = {};
      if (media.userDefinedTimeCode || media.userDefinedTimeCodeFrames) {
        if (media.userDefinedTimeCode) {
          const momObj = moment.duration(media.userDefinedTimeCode);
          let hours = momObj._data.hours;
          if (momObj._data.days) {
            hours += 24 * momObj._data.days;
          }
          mediaTimecode.time = hours + ':' + momObj._data.minutes + ':' + momObj._data.seconds;
        }
        if (media.userDefinedTimeCodeFrames) {
          mediaTimecode.frames = String(media.userDefinedTimeCodeFrames);
        }
      } else if (media.timeCode) {
        mediaTimecode.time = media.timeCode.substring(0, 8);
        mediaTimecode.frames = media.timeCode.substring(9, 11);
      }
      if (!mediaTimecode.time) {
        mediaTimecode.time = '00:00:00';
      }
      if (!mediaTimecode.frames) {
        mediaTimecode.frames = '00';
      }

      return mediaTimecode;
    }

    function toHHMMSSFormat(duration) {
      const sec_num = parseInt(duration, 10);
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }

      return [hours, minutes, seconds].join(':');
    }

    function getFrameSeparatorString(media) {
      let frameSeparatorString = ';';
      if (media && (media.fps % 1 === 0 || media.fps === 23.976)) {
        frameSeparatorString = ':';
      }
      return frameSeparatorString;
    }

  }
})();
