(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('teamListPageComponent', {
      controller: TeamListPageComponent,
      bindings: {},
      templateUrl: 'app/admin/pages/team-list-page/team-list-page.component.html'
    });

  TeamListPageComponent.$inject = ['adminTeamService', 'notificationService', 'emailHelper'];

  function TeamListPageComponent(adminTeamService, notificationService, emailHelper) {
    const me = this;

    me.$onInit = onInit;
    me.createNewTeam = createNewTeam;
    me.deleteTeam = deleteTeam;

    function onInit() {
      adminTeamService.getTeams().then(response => me.teamList = response);
    }

    function createNewTeam() {
      if (emailHelper.validate(me.userEmail)) {
        adminTeamService.createNewTeam(me.userEmail).then(() => {
          me.userEmail = '';
          onInit()
        });
      } else {
        notificationService.error('Email is invalid.');
      }
    }

    function deleteTeam(ownerEmail) {
      notificationService.confirm(`Are you sure you want to delete the team ${ownerEmail}?`)
        .then(() => adminTeamService.deleteTeam(ownerEmail).then(() => onInit()))
        .catch(() => {});
    }

  }
})();
