(function () {
  'use strict';
  angular
    .module('ss.client.components')
    .component('modalHeader', {
      controller: function () {},
      bindings: {
        title: '@?',
        cancel: '&',
        closeActionDisabled: '&?'
      },
      templateUrl: 'app/client/components/modal-header/modal-header.component.html'
    });
})();
