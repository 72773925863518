(function () {
  'use strict';
  angular
    .module('ss.admin.components')
    .component('adminHeader', {
      controller: function () {},
      bindings: {
        link: '@',
        title: '@'
      },
      templateUrl: 'app/admin/components/admin-header/admin-header.component.html'
    });
})();
