(function () {
    'use strict';

    angular
        .module('ss.client.directives')
        .directive('dashboardPageCssClasses', dashboardPageCssClasses);

    dashboardPageCssClasses.$inject = ['$document'];

    function dashboardPageCssClasses($document) {
        return {
            restrict: 'EA',
            scope: {
                tabs: '<',
                activeTab: '<'
            },
            link: link
        };

        function link(scope, element, attrs) {
            const mainCssClass = 'dashboard-page';
            const bodyEl = $document.find('body');
            const tabs = scope.tabs.map(t => {
                const cssClass = t.url.toLowerCase().replace(/\s+/g, '-');
                return { url: t.url, cssClass }
            });

            bodyEl.addClass(mainCssClass);

            const cleanupWatch = scope.$watch('activeTab', (newActiveTab, oldActiveTab) => {
                const oldTab = tabs.find(t => t.url === oldActiveTab.url);
                const newTab = tabs.find(t => t.url === newActiveTab.url);

                if (oldTab && oldTab !== newTab) {
                    bodyEl.removeClass(oldTab.cssClass);
                }

                if (newTab) {
                    bodyEl.addClass(newTab.cssClass);
                }
            });

            element.on('$destroy', () => {
                cleanupWatch();
                const cssClasses = [mainCssClass].concat(tabs.map(t => t.cssClass))
                bodyEl.removeClass(cssClasses.join(' '));
            });
        }
    }

})();
