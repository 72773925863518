(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('superAdminPageComponent', {
      controller: SuperAdminPageComponent,
      bindings: {},
      templateUrl: 'app/admin/pages/super-admin-page/super-admin-page.component.html'
    });

  SuperAdminPageComponent.$inject = ['superAdminService', 'sharedService', 'notificationService'];

  function SuperAdminPageComponent(superAdminService, sharedService, notificationService) {
    const me = this;
    me.user = sharedService.getUser();
    me.selectedAdmin = null;
    me.$onInit = onInit;
    me.removeAdmin = removeAdmin;
    me.saveAdmin = saveAdmin;
    me.getName = getName;
    me.querySearch = querySearch;
    me.selectedItemChange = selectedItemChange;

    function onInit() {
      me.selectedAdmin = null;
      superAdminService.getAllAdmin().then(response => me.adminList = response);
    }

    function querySearch(searchText) {
      if (searchText) {
        superAdminService.getAdmin(searchText).then(response => me.userMatches = response);
      }
    }

    function selectedItemChange(item) {
      if (item) {
        me.selectedAdmin = item;
      }
    }

    function getName(item) {
      return `${item.fullName} (${item.email})`;
    }

    function saveAdmin() {
      if (me.selectedAdmin.id !== 'undefined') {
        superAdminService.saveAdmin(me.selectedAdmin.id).then(() => onInit());
      }
    }

    function removeAdmin(id) {
      notificationService.confirm('Are you sure you want to remove this user?')
        .then(() => superAdminService.updateAdminById(id).then(() => onInit()))
        .catch(() => { });
    }

  }
}());
