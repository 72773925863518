(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('userDetailPageComponent', {
      controller: UserDetailPageComponent,
      bindings: {
        userId: '<'
      },
      templateUrl: 'app/admin/pages/user-detail-page/user-detail-page.component.html'
    });

  UserDetailPageComponent.$inject = ['stateService', '$window', '$uibModal', 'sharedProjectsService', 'projectService', 'publicTranscriptService', 'adminUserService', 'subscriptionAdminService', 'notificationService', '$timeout'];

  function UserDetailPageComponent(stateService, $window, $uibModal, sharedProjectsService, projectService, publicTranscriptService, adminUserService, subscriptionAdminService, notificationService, $timeout) {
    const me = this;
    let backupFormData;
    const pageSize = 20;
    me.dateOptions = {
      showWeeks: false
    };
    me.subscriptionCreditExpireDate;
    me.subscriptionCreditExpireDateOpen = { opened: false };

    me.$onInit = onInit;
    me.$postLink = postLink;
    me.changeProjectPage = changeProjectPage;
    me.changeTransactionPage = changeTransactionPage;
    me.isSubscriptionCreditValid = isSubscriptionCreditValid;
    me.saveChanges = saveChanges;
    me.cancelChanges = cancelChanges;
    me.toggleUserActivation = toggleUserActivation;
    me.goToDetailedTransactionViewLink = goToDetailedTransactionViewLink;
    me.goToProjectDetailViewLink = goToProjectDetailViewLink;
    me.deleteUser = deleteUser;
    me.synchronizingSubscription = synchronizingSubscription;
    me.unlockProject = unlockProject;
    me.openPublicTranscriptModal = openPublicTranscriptModal;
    me.exportUserData = exportUserData;

    function onInit() {
      retrieveUserInfo();
      retrieveTransactions();
      retrieveProjects();
    }

    function postLink() {
      $timeout(() => {
        setTopScrollBar('projects');
        setTopScrollBar('transactions');
      }, 1000);
    }

    function makeBackupData() {
      backupFormData = Object.assign({}, me.user);
    }

    function goToDetailedTransactionViewLink(projectId, disabled) {
      if (projectId && !disabled) {
        const url = stateService.href('app.admin.detailedTransaction', {
          projectId: projectId
        });
        $window.open(url, '_blank', 'noopener noreferrer');
      }
    }

    function goToProjectDetailViewLink(projectId) {
      if (projectId) {
        const url = stateService.href('app.admin.projectManagementItem', {
          projectId: projectId
        });
        $window.open(url, '_blank', 'noopener noreferrer');
      }
    }

    function retrieveUserInfo() {
      adminUserService.getUserById(me.userId).then(response => {
        me.user = response;
        me.subscriptionCreditExpireDate = new Date(me.user.subscriptionCreditExpireDate);
        makeBackupData();
      }).catch(() => me.user = null);

      subscriptionAdminService.fetchUserSubscription(me.userId).then(response => me.stripeSubscriptionDetail = response);
      adminUserService.fetchUserStripeInfo(me.userId).then(response => me.userStripeInfo = response);
    }

    function retrieveTransactions(pageNumber) {
      if (!pageNumber || pageNumber < 0) {
        pageNumber = 0;
      }
      adminUserService.getUserTransactions(me.userId, pageNumber, pageSize).then(response => me.transactionPage = response);
    }

    function retrieveProjects(pageNumber) {
      if (!pageNumber || pageNumber < 0) {
        pageNumber = 0;
      }
      adminUserService.getUserProjects(me.userId, pageNumber, pageSize).then(response => me.projectPage = response);
    }

    function unlockProject(projectId) {
      adminUserService.unlockProject(projectId);
    }
    function openPublicTranscriptModal(event, project) {
      event.stopPropagation();
      let projectPublic = projectService.get(project.id).then(response => {
        let dataPublic = publicTranscriptService.get(response.medias[0].id)
        $uibModal.open({
          animation: true,
          backdrop: 'static',
          component: 'publicTranscriptModal',
          resolve: {
            project: () => project
          }
        });
      })
     
      event.stopPropagation();
    }

    function exportUserData(userId) {
      adminUserService.exportUserData(userId);
    }

    function changeProjectPage() {
      retrieveProjects(me.projectPage.number - 1);
    }

    function changeTransactionPage() {
      retrieveTransactions(me.transactionPage.number - 1);
    }

    function isSubscriptionCreditValid() {
      return me.user ? !me.user.subscriptionCredit || (me.subscriptionCreditExpireDate && me.subscriptionCreditExpireDate > new Date()) : false;
    }

    function saveChanges(propertiesToChange) {
      const defaultPropertiesToChange = ['credit', 'subscriptionCredit', 'subscriptionCreditExpireDate'];
      me.user.subscriptionCreditExpireDate = me.subscriptionCreditExpireDate ? Date.parse(me.subscriptionCreditExpireDate) : Date.parse(new Date(null));
      const updatesForUser = _.pick(me.user, propertiesToChange || defaultPropertiesToChange);
      adminUserService.updateUser(me.user.id, updatesForUser).then(response => {
        me.user = response;
        makeBackupData();
        me.userDetailsForm.$setPristine();
      });
    }

    function cancelChanges() {
      me.userDetailsForm.$setPristine();
      me.user = Object.assign({}, backupFormData);
    }

    function deleteUser() {
      notificationService.confirm('Are you sure you want to delete this user?')
        .then(() => adminUserService.deleteUser(me.user.id, me.user.email))
        .catch(() => { });
    }

    function synchronizingSubscription() {
      adminUserService.synchronizingSubscription(me.userId, me.user.email).then(() => retrieveUserInfo());
    }

    function toggleUserActivation() {
      const confirmMessage = me.user.active ? 'Are you sure to Activate this account?' : 'Are you sure to put this account on HOLD?';
      notificationService.confirm(confirmMessage)
        .then(() => saveChanges(['active']))
        .catch(() => cancelChanges());
    }

    function setTopScrollBar(elementId) {
      $('#' + elementId + ' .scrollbar-top-wrapper').scroll(() => { // sync the real scrollbar with the virtual one.
        $('#' + elementId + ' .scrollbar-wrapper').scrollLeft($('#' + elementId + ' .scrollbar-top-wrapper').scrollLeft());
      });
      $('#' + elementId + ' .scrollbar-wrapper').scroll(() => { // sync the virtual scrollbar with the real one.
        $('#' + elementId + ' .scrollbar-top-wrapper').scrollLeft($('#' + elementId + ' .scrollbar-wrapper').scrollLeft());
      });
    }

  }
})();
