(function () {
  'use strict';

  angular
    .module('ss.admin.services')
    .factory('adminTeamService', adminTeamService);

  adminTeamService.$inject = ['dataService'];

  function adminTeamService(dataService) {
    const endPoint = 'admin/teams/';
    const service = {
      getTeams: getTeams,
      getTeam: getTeam,
      getOwnerByTeamId: getOwnerByTeamId,
      getMembersByTeamId: getMembersByTeamId,
      getTeamTransactions: getTeamTransactions,
      updateTeam: updateTeam,
      deleteTeam: deleteTeam,
      createNewTeam: createNewTeam,
      removeTeamMember: removeTeamMember,
      addNewTeamMember: addNewTeamMember
    };
    return service;

    ////////////////

    function getTeams() {
      return dataService.get(endPoint);
    }

    function getTeam(id) {
      return dataService.get(endPoint, id);
    }

    function updateTeam(id, payload) {
      return dataService.put(endPoint, payload, id).catch(reason => dataService.throwException(reason, 'There was a problem updating team details.'));
    }

    function createNewTeam(userEmail) {
      return dataService.post(endPoint, {
        email: userEmail
      }).catch(reason => dataService.throwException(reason, reason.data.message || 'There was an error create team.'));
    }

    function deleteTeam(id) {
      return dataService.delete(endPoint, id).catch(reason => dataService.throwException(reason, reason.data.message || 'There was an error delete team.'));
    }

    function getOwnerByTeamId(id) {
      return dataService.get(endPoint, id + '/owner').catch(reason => dataService.logException(reason));
    }

    function getMembersByTeamId(id) {
      return dataService.get(endPoint, id + '/members');
    }

    function removeTeamMember(id, memberId) {
      return dataService.delete(endPoint, id + '/members/' + memberId).catch(reason => dataService.throwException(reason, 'There was an error removing teammate.'));
    }

    function addNewTeamMember(id, newUserEmail) {
      return dataService.post(endPoint, {
        email: newUserEmail
      }, id + '/members').catch(reason => dataService.throwException(reason, "There was an error adding teammate."));
    }

    function getTeamTransactions(id) {
      return dataService.get(endPoint, id + '/transactions');
    }

  }
})();
