(function () {
    'use strict';
  
    angular
      .module('ss.client.services')
      .factory('publicTranscriptService', publicTranscriptService);
  
      publicTranscriptService.$inject = ['dataService', 'notificationService', 'customAnalyticsService', 'sharedService', 'stateService'];
  
    function publicTranscriptService(dataService, notificationService, customAnalyticsService, sharedService, stateService) {
      const endPoint = 'public';
  
      const service = {
        publicTranscript: publicTranscript,
        get: get
      };
      return service;

      function get(mediaId) {
        return dataService.get(endPoint, '/' + mediaId).catch(error => dataService.logException(error))
      }

      function publicTranscript(mediaId, title, fileName, videoPreview, videoLink, projectName, createdAt, link, description, thumbnail, format) {
        let payload = {
          title: title,
          fileName: fileName,
          videoPreview: videoPreview,
          videoLink: videoLink,
          projectName: projectName,
          createdAt: createdAt,
          link: link,
          description: description,
          thumbnail: thumbnail,
          format: format
        }
        return dataService.post(endPoint, payload, '/' + mediaId).catch(error => dataService.logException(error));
      }
    }
})();