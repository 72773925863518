(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('transcribeProjectModal', {
      controller: TranscribeProjectModalComponent,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/transcribe-project-modal/transcribe-project-modal.component.html'
    });

  TranscribeProjectModalComponent.$inject = ['languagesService', 'mediaService', 'customEcommerceAnalytics', '$timeout',
    'projectService', 'customAnalyticsService', 'notificationService', 'dictionaryService', 'stateService',
    'SS_CONSTANT'
  ];

  function TranscribeProjectModalComponent(languagesService, mediaService, customEcommerceAnalytics, $timeout,
    projectService, customAnalyticsService, notificationService, dictionaryService, stateService,
    SS_CONSTANT) {

    const me = this;

    me.$onInit = onInit;
    me.changeLanguage = changeLanguage;
    me.changeDictionary = changeDictionary;
    me.groupByFavorite = groupByFavorite;
    me.openCreateDictionaryPage = openCreateDictionaryPage;
    me.transcribe = transcribe;
    me.cancel = cancel;
    me.showInvalidDictionaryError = false;
    me.dictionariesLimitsInfoLink = SS_CONSTANT.DICTIONARIES_LIMITS_INFO_LINK;
    me.hasLockedDictionaries = false;

    function onInit() {
      me.price = me.resolve.price;
      me.project = me.resolve.project;
      me.selectedLanguageRegion = {};
      me.selectedDictionary = {};
      me.allDictionaries = [];

      languagesService.getAll().then(languages => {
        me.languageRegions = buildLanguageRegionsToDisplay(languages);
        if (me.project.languageRegionCode) {
          me.selectedLanguageRegion.value = _.find(me.languageRegions, {
            code: me.project.languageRegionCode,
            defaultSpeechApi: me.project.defaultSpeechApi
          });
        }
        filterDictionariesByLanguage();
      });

      dictionaryService.getAll().then(dictionaries => {
        me.allDictionaries = dictionaries;
        if (me.project.dictionaryId) {
          me.selectedDictionary.value = _.find(me.allDictionaries, {
            id: me.project.dictionaryId
          });
        }
        filterDictionariesByLanguage();
      })
    }

    function filterDictionariesByLanguage() {
      me.hasLockedDictionaries = me.allDictionaries.some(d => d.locked === true);
      const projectLanguage = me.selectedLanguageRegion.value;
      if (projectLanguage) {
        const languageDictionaries = me.allDictionaries.filter(d => d.languageLabel === projectLanguage.languageLabel);
        me.filteredDictionaries = languageDictionaries.filter(d => d.locked === false);
      } else {
        me.filteredDictionaries = me.allDictionaries.filter(d => d.locked === false);
      }
      me.filteredDictionaries.push({ id: 0, name: `<em>Create your dictionary <i class='fas fa-external-link-square-alt'></i></em>` });
    }

    function buildLanguageRegionsToDisplay(languages) {
      let languagesToDisplay = [];
      languages.forEach(language =>
        language.regions.forEach(region =>
          languagesToDisplay.push({
            languageId: language.id,
            languageLabel: language.label,
            label: language.label + ' (' + region.name + ')',
            code: language.code + (region.code ? '-' + region.code : ''),
            defaultSpeechApi: region.defaultSpeechApi,
            isFavorite: region.favorite,
            isLastUsed: region.id === me.resolve.project.owner.lastUsedTranscriptionLanguageRegion
          })));

      languagesToDisplay = _.orderBy(languagesToDisplay, ['label'])
      const lastUsed = _.filter(languagesToDisplay, {
        isLastUsed: true
      });
      const frequentlyUsed = _.filter(languagesToDisplay, {
        isFavorite: true, isLastUsed: false
      });
      const others = _.filter(languagesToDisplay, {
        isLastUsed: false, isFavorite: false
      });
      return _.concat(lastUsed, frequentlyUsed, others);
    }

    function changeLanguage() {
      me.disabledSubmitButton = true;
      me.project.languageRegionCode = me.selectedLanguageRegion.value.code;
      me.project.defaultSpeechApi = me.selectedLanguageRegion.value.defaultSpeechApi;

      if (me.project.languageRegionCode !== 'en-US') {
        me.project.dictionaryId = null;
      }

      projectService.save(me.project)
        .then(() => {
          me.disabledSubmitButton = false;
          filterDictionariesByLanguage();
          changeDictionary();
        })
        .catch(() => me.disabledSubmitButton = false);
    }

    function changeDictionary() {
      if (me.selectedDictionary.value.id === 0) {
        openCreateDictionaryPage();
      } else {
        const selectedDictionary = me.selectedDictionary.value;
        const selectedLanguageRegion = me.selectedLanguageRegion.value;

        if (selectedDictionary && selectedLanguageRegion && selectedLanguageRegion.languageId !== selectedDictionary.languageId) {
          me.showInvalidDictionaryError = true;
          me.selectedDictionary = { value: null };
        } else {
          me.showInvalidDictionaryError = false;
        }
      }
    }

    function groupByFavorite(languageToDisplayItem) {
      if (languageToDisplayItem.isLastUsed) return "Last Used";
      return languageToDisplayItem.isFavorite ? 'Frequently Used' : 'Others';
    }

    function transcribe(useSavedCard, card, shouldSaveCard) {
      if (!me.project.languageRegionCode || !me.selectedLanguageRegion.value) {
        notificationService.warning('Select a transcription language');
        return;
      }
      customAnalyticsService.trackEvent('Transcribe', 'Click pay', null, null);
      me.disabledSubmitButton = true;
      const params = {
        useSavedCard: useSavedCard,
        card: card,
        shouldSaveCard: shouldSaveCard
      };
      if (me.price.price && !useSavedCard) {
        customAnalyticsService.trackEvent('Transcribe', 'Starting payment - data verified', null, null);
        params.withQueryParams = true;
        transcribeAction(params);
      } else {
        params.withQueryParams = false;
        transcribeAction(params);
      }
    }

    function transcribeAction(params) {
      mediaService.checkoutTranscribe(me.project.id, params.useSavedCard, params.shouldSaveCard, params.card, params.withQueryParams).then(response => {
        const data = response.response;
        if (data === 'OK') {
          try {
            customEcommerceAnalytics.clearTrans();
            if (!params.withQueryParams) {
              customEcommerceAnalytics.addTransaction(me.project.id, me.resolve.planEnum, me.price, '0', '0');
            }
            if (me.price.credit === 0) {
              customEcommerceAnalytics.addItem(me.project.id, me.resolve.planEnum, me.price.price / me.price.totalTimeInMinutes, me.price.totalTimeInMinutes, '-TX');
            } else {
              const quantityToPass = getQuantityToPass();
              customEcommerceAnalytics.addItem(me.project.id, me.resolve.planEnum, 0, quantityToPass, '-SUB');
              if (me.price.chargeableTimeInMinutes !== null && me.price.chargeableTimeInMinutes > 0) {
                customEcommerceAnalytics.addItem(me.project.id, me.resolve.planEnum, me.price.price / me.price.chargeableTimeInMinutes, me.price.chargeableTimeInMinutes, '-TX');
              }
            }
            // Complete transaction
            customEcommerceAnalytics.trackTrans();
          } catch (error) {
            notificationService.error(error);
          }
          customAnalyticsService.trackEvent('Transcribe', 'Payment success', null, null);
          me.close();
          $('#modal-success').modal('show');
          let transcribePayload = null;
          if (me.selectedDictionary.value) {
            transcribePayload = { dictionaryId: me.selectedDictionary.value.id };
          }
          $timeout(() => projectService.transcribe(me.project.id, transcribePayload).catch(() => me.disabledSubmitButton = false), 1500);
        } else {
          transcribeError(response);
        }
      }).catch(reason => transcribeError(reason, '2'));
    }

    function transcribeError(reason, errorNumber) {
      me.disabledSubmitButton = false;
      customAnalyticsService.trackEvent('Transcribe', 'Payment error' + errorNumber, null, reason);
    }

    function getQuantityToPass() {
      let quantityToPass = '';
      if (me.price.totalTimeInMinutes > me.price.credit) {
        quantityToPass = me.price.credit;
      }
      if (me.price.totalTimeInMinutes === me.price.credit || me.price.totalTimeInMinutes < me.price.credit) {
        quantityToPass = me.price.totalTimeInMinutes;
      }
      return quantityToPass;
    }

    function openCreateDictionaryPage() {
      cancel();
      stateService.goTo('app.dashboard', { activeTab: 'my-dictionaries' });
    }

    function cancel() {
      me.dismiss();
    }

  }
})();
