(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .factory('authInterceptor', authInterceptor);

  authInterceptor.$inject = ['$q', 'authService', 'httpErrorHandler']

  function authInterceptor($q, authService, httpErrorHandler) {
    const service = {
      request: request,
      responseError: responseError
    };
    return service;

    //////////////////////////

    function constructToken(accessToken) {
      return authService.getTokenType() + ' ' + accessToken;
    }

    function setAuthHeader(config) {
      if (config.url.indexOf('amazonaws.com') === -1) {
        const token = authService.getAccessToken();
        config.headers[authService.getAuthHeaderName()] = constructToken(token);
      }
    }

    function request(config) {
      config.headers = config.headers || {};
      setAuthHeader(config);
      return config || $q.when(config);
    }

    function responseError(rejection) {
      const {
        status,
        data
      } = rejection;

      let error = null;
      if (data && data.error) {
        error = data.error;
      }
      if (data && data.message) {
        error = data.message;
      }
      httpErrorHandler.manageHttpCode(status, error);
      return $q.reject(rejection) || {};
    }

  }
})();
