(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('plansPageComponent', {
      controller: PlansPageComponent,
      bindings: {},
      templateUrl: 'app/admin/pages/plans-page/plans-page.component.html'
    });

  PlansPageComponent.$inject = ['plansService'];

  function PlansPageComponent(plansService) {
    const me = this;

    me.$onInit = onInit;

    function onInit() {
      plansService.getAll().then(response => me.plans = response);
    }

  }
}());
