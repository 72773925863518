(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('subscribeModal', {
      controller: SubscribeModalComponent,
      bindings: {
        resolve: '<',
        showHeader: '<?',
        allowUpgrade: '@?',
        mode: '@?',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/subscribe-modal/subscribe-modal.component.html'
    });

  SubscribeModalComponent.$inject = ['customEcommerceAnalytics', 'sharedService', 'subscriptionService', 'plansService', 'SS_CONSTANT', '$filter'];

  function SubscribeModalComponent(customEcommerceAnalytics, sharedService, subscriptionService, plansService, SS_CONSTANT, $filter) {
    const me = this;
    me.user = sharedService.getUser();

    me.$onInit = onInit;
    me.isCurrentUserPlan = isCurrentUserPlan;
    me.isSubscriptionOptionAllowed = isSubscriptionOptionAllowed;
    me.isSubscribeButtonAvailable = isSubscribeButtonAvailable;
    me.getMaxDiscountInAnnual = getMaxDiscountInAnnual;
    me.getSubscriptionPricePerMonthLabel = getSubscriptionPricePerMonthLabel;
    me.getDiscountLabel = getDiscountLabel;
    me.selectNewPlan = selectNewPlan;
    me.onPlanSelected = onPlanSelected;
    me.confirmSelectPlan = confirmSelectPlan;
    me.subscribe = subscribe;
    me.cancel = cancel;
    me.preSelectedNewPlan = {};
    me.selectedPlan = {};
    const RENDES_MODES = { UPGRADE: 1, DOWNGRADE: 2 };
    const notUpgradableMessageMonthly = 'To upgrade, choose an annual plan';
    const notUpgradableMessage = 'You already subscribe to the top plan 💪🏼';

    function onInit() {
      me.PLANS = SS_CONSTANT.PLANS;
      me.isAnnualIntervalSelected = isAnnual();
      me.isCurrentPlanAnnualInterval = me.isAnnualIntervalSelected;
      me.renderMode = `${me.mode || 'upgrade'}`.toLowerCase() === 'upgrade' ? RENDES_MODES.UPGRADE : RENDES_MODES.DOWNGRADE;
      me.actionButtonText = me.renderMode === RENDES_MODES.UPGRADE ? 'Upgrade' : 'Downgrade';
      me.showHeader = me.showHeader === false ? false : true;

      retrievePlans();
    }

    /**
     * As per Roderick's request:
     * 'monthly/yearly toggle is disabled on the subscription/upgrade window (and it defaults to monthly when attempting to upgrade, even if you are currently on a yearly plan)'
     * @returns is plan Annual or Monthly
     */

    /**
     * As per Shamir's request:
     * 'If you are currently on a yearly plan, you can downgrade to monthly'
     * @returns is plan Annual or Monthly
     */
    function isAnnual() {
      // return false;
      return (me.user && me.user.plan.interval === me.PLANS.INTERVAL_TYPES.YEAR) || false;
    }

    function retrievePlans() {
      plansService.getAll().then(response => {
        me.planList = response;
        me.planListToDisplay = me.planList.filter(plan => plan.active && plan.interval);
        const list = me.planListToDisplay.map(plan => plan.name);
        me.plansName = list.filter((item, i) => list.indexOf(item) === i);
        selectNewPlan();

        if (me.preSelectedNewPlan) {
          // To show the month/year switch accurately based on the pre-selected next plan to upgrade
          me.isAnnualIntervalSelected = me.preSelectedNewPlan.interval === me.PLANS.INTERVAL_TYPES.YEAR;
        }
      });
    }

    function selectNewPlan() {
      const currentPlan = me.user.plan;
      let plansToSelect = [];
      if (me.isAnnualIntervalSelected) {
        plansToSelect = currentPlan.upgradeOptions.filter(u => u.includes('ANNUAL'));
        me.notUpgradableMessage = notUpgradableMessage;
      } else {
        plansToSelect = currentPlan.upgradeOptions.filter(u => u.includes('MONTHLY'));
        me.notUpgradableMessage = notUpgradableMessageMonthly;
      }

      if (me.renderMode === RENDES_MODES.DOWNGRADE) {
        const firstDowngradeOption = _.last(currentPlan.downgradeOptions)
        me.preSelectedNewPlan = me.planListToDisplay.find(plan => plan.planEnum === firstDowngradeOption);
      } else if (me.renderMode === RENDES_MODES.UPGRADE) {
        const planSelected = plansToSelect.find(plan => plan.includes('PRO'));
        me.preSelectedNewPlan = me.planListToDisplay.find(plan => plan.planEnum === planSelected);
        if (!me.preSelectedNewPlan && plansToSelect.length > 0) {
          me.preSelectedNewPlan = me.planListToDisplay.find(plan => plan.planEnum === plansToSelect[0]);
        }
      }

      if (me.preSelectedNewPlan) {
        onPlanSelected(me.preSelectedNewPlan);
        confirmSelectPlan(me.preSelectedNewPlan);
        me.disabledSubmitButton = false;
      } else {
        me.disabledSubmitButton = true;
        me.selectedPlan = {};
      }
    }

    function onPlanSelected(plan) {
      if (plan.subscriptionCharge < me.user.plan.subscriptionCharge && plan.interval === me.user.plan.interval) {
        me.actionButtonText = 'Downgrade';
      } else {
        me.actionButtonText = 'Upgrade';
      }
      confirmSelectPlan(plan);
    }

    function subscribe(plan, useSavedCard, card) {
      me.disabledSubmitButton = true;
      const planData = me.selectedPlan;
      subscriptionService.subscriptionAction(plan, card, 'subscribe', { useSavedCard }).then(() => {
        const userRandomId = me.user.id + (Math.random() * 10);
        customEcommerceAnalytics.clearTrans();
        customEcommerceAnalytics.addTransaction(userRandomId, planData.planEnum, planData.subscriptionCharge, '0', '0');
        customEcommerceAnalytics.addItem(userRandomId, planData.planEnum, planData.subscriptionCharge, 1, '-SUB');
        customEcommerceAnalytics.trackTrans();
        me.disabledSubmitButton = false;
        me.close({
          $value: {
            planName: me.selectedPlan.fullLabel
          }
        });
      }).catch(() => {
        me.disabledSubmitButton = false;
      });
    }

    function isCurrentUserPlan(plan) {
      return me.user.plan.planEnum === plan.planEnum;
    }

    function isSubscribeButtonAvailable(plan) {
      return me.user && plan.subscriptionCharge && isSubscriptionAllowed(plan);
    }

    function isSubscriptionOptionAllowed(plan, interval) {
      if (interval === me.PLANS.INTERVAL_TYPES.MONTH && plan.interval === interval) {
        return !me.isAnnualIntervalSelected && !me.isCurrentPlanAnnualInterval && isSubscriptionAllowed(plan);
      }

      if (interval === me.PLANS.INTERVAL_TYPES.YEAR && plan.interval === interval) {
        return me.isAnnualIntervalSelected && isSubscriptionAllowed(plan)
      }

      return false;
    }

    function isSubscriptionAllowed(plan) {
      if (!me.user.plan) {
        return true;
      }

      if (me.renderMode === RENDES_MODES.DOWNGRADE && (me.user.plan.downgradeOptions.includes(plan.planEnum) || shouldAllowUpgradeInDowngradeMode(plan))) {
        return true;
      }

      if (me.renderMode === RENDES_MODES.UPGRADE && me.user.plan.upgradeOptions.includes(plan.planEnum)) {
        return true;
      }

      return false;
    }

    function shouldAllowUpgradeInDowngradeMode(plan) {
      const allowUpgrade = (me.allowUpgrade || '').toLowerCase() === 'true';

      return allowUpgrade && me.user.plan.upgradeOptions.includes(plan.planEnum);
    }

    function getMaxDiscountInAnnual() {
      return _.chain(me.planList)
        .filter(plan => plan.active && plan.interval === me.PLANS.INTERVAL_TYPES.YEAR)
        .map(annualPlan => {
          const discount = getDiscount(annualPlan, true);
          if (discount && !isNaN(discount)) {
            return Math.round(discount);
          }
        }).max().value();
    }

    function calculateMonthlyDiscount(payAsYouGo, monthlyPlan) {
      return 100 - (100 * (monthlyPlan.pricePerHour / payAsYouGo.pricePerHour));
    }

    function getDiscount(plan) {
      const payAsYouGo = me.planList.find(planItem => planItem.active && planItem.planEnum === me.PLANS.NO_SUBSCRIPTION_PLAN);
      if (payAsYouGo) {
        return calculateMonthlyDiscount(payAsYouGo, plan);
      }
    }

    function getSubscriptionPricePerMonthLabel(plan) {
      const type = me.isAnnualIntervalSelected ? '/yr' : '/mo';
      if (plan && plan.subscriptionCharge) {
        const chargePerMonth = plan.subscriptionCharge;
        return `$${chargePerMonth}${type}`;
      }
      return me.isAnnualIntervalSelected ? 'No annual cost' : 'No monthly cost';
    }

    function getDiscountLabel(plan, byMonth) {
      const discount = getDiscount(plan, byMonth);
      return discount && !isNaN(discount) ? `(save ${$filter('number')(discount, 0)}%)` : '';
    }

    function confirmSelectPlan(plan) {
      me.selectedPlan = plan;
      // TODO - Removed once the info is on the BE
      switch (me.selectedPlan.planEnum) {
        case 'STARTER_MONTHLY':
          me.selectedPlan.creditInHours = me.selectedPlan.creditInMinutes / 60 || 2;
          break;
        case 'PRO_MONTHLY':
          me.selectedPlan.creditInHours = me.selectedPlan.creditInMinutes / 60 || 4;
          break;
        case 'STARTER_ANNUAL':
          me.selectedPlan.creditInHours = me.selectedPlan.creditInMinutes / 60 || 24;
          break;
        case 'PRO_ANNUAL':
          me.selectedPlan.creditInHours = me.selectedPlan.creditInMinutes / 60 || 48;
          break;
        default: me.selectedPlan.creditInHours = me.selectedPlan.creditInMinutes / 60 || 10;
      }
    }

    function cancel() {
      me.dismiss();
    }

  }
})();
