(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('quickTipsForUploadModal', {
      controller: QuickTipsForUploadModalComponent,
      bindings: {
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/quick-tips-for-upload-modal/quick-tips-for-upload-modal.component.html'
    });

  function QuickTipsForUploadModalComponent() {
    const me = this;

    me.cancel = cancel;

    function cancel() {
      me.dismiss();
    }

  }
})();
