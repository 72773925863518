(function () {
  'use strict';

  angular
    .module('ss.client.public-pages', [])
    .config(clientPagesConfig);

  clientPagesConfig.$inject = ['$stateProvider'];

  function clientPagesConfig($stateProvider) {
    $stateProvider
      .state('public', {
        url: '',
        abstract: true,
        component: 'publicPageComponent'
      });
  }

})();
