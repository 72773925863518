(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('changeUserEmailModal', {
      controller: ChangeUserEmailComponent,
      bindings: {
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/change-user-email-modal/change-user-email-modal.component.html'
    });

  ChangeUserEmailComponent.$inject = ['usersService'];

  function ChangeUserEmailComponent(usersService) {
    const me = this;

    me.$onInit = onInit;
    me.cancel = cancel;
    me.update = update;

    function onInit() {
      me.updateUser = {};
    }

    function update() {
      usersService.updateEmail(me.updateUser).then(() => me.close());
    }

    function cancel() {
      me.dismiss();
    }

  }
})();
