(function () {
  'use strict';
  angular
    .module('ss.admin.services')
    .factory('publishedProjectsService', publishedProjectsService);

  publishedProjectsService.$inject = ['dataService'];

  function publishedProjectsService(dataService) {
    const endPoint = 'published-projects';
    const endPointAnnotations = 'published-projects/annotations/';
    const service = {
      getAll: getAll,
      getAnnotations: getAnnotations,
      updateAnnotation: updateAnnotation,
      removeAnnotation: removeAnnotation
    };
    return service;

    //////////////////////////////

    function getAll() {
      return dataService.get(endPoint);
    }

    function getAnnotations() {
      return dataService.get(endPointAnnotations);
    }

    function updateAnnotation(payload) {
      return dataService.put(endPointAnnotations, payload, payload.transcriptionId);
    }

    function removeAnnotation(payload) {
      return dataService.delete(endPointAnnotations, payload.transcriptionId);
    }

  }
})();
