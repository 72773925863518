(function () {
  'use strict';

  angular
    .module('ss.admin.services')
    .factory('superAdminService', superAdminService);

  superAdminService.$inject = ['dataService'];

  function superAdminService(dataService) {
    const endPoint = 'super-admin/admin/';
    const service = {
      getAdmin: getAdmin,
      saveAdmin: saveAdmin,
      updateAdminById: updateAdminById,
      getAllAdmin: getAllAdmin
    };
    return service;

    ////////////////////////////

    function getAdmin(name) {
      return dataService.get(endPoint, name);
    }

    function getAllAdmin() {
      return dataService.get(endPoint, 'admins');
    }

    function saveAdmin(id) {
      return dataService.post(endPoint, id).catch(reason => dataService.throwException(reason));
    }

    function updateAdminById(id) {
      return dataService.put(endPoint, id).catch(reason => dataService.throwException(reason));
    }

  }
})();
