(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('publishedProjectsPageComponent', {
      controller: PublishedProjectsPageComponent,
      bindings: {
        publishedProjects: '<'
      },
      templateUrl: 'app/admin/pages/published-projects-page/published-projects-page.component.html'
    });

  PublishedProjectsPageComponent.$inject = ['sharedProjectsService', 'publishedProjectsService', 'notificationService'];

  function PublishedProjectsPageComponent(sharedProjectsService, publishedProjectsService, notificationService) {
    const me = this;

    me.$onInit = onInit;
    me.saveAnnotationAdminComment = saveAnnotationAdminComment;
    me.removeAnnotation = removeAnnotation;
    me.loadMoreAnnotations = loadMoreAnnotations;
    me.loadMoreProjects = loadMoreProjects;
    me.getPublicProjectLink = getPublicProjectLink;
    me.getPublicProjectLinkFromProject = getPublicProjectLinkFromProject;

    function onInit() {
      me.annotationListPageSize = 15;
      me.publishedProjectListPageSize = 15;
      getPublishedAnnotations();
    }

    function getPublishedAnnotations() {
      publishedProjectsService.getAnnotations()
        .then(response => me.annotations = response.sort((a, b) => new Date(b.lastUpdateIsoUtcDate) - new Date(a.lastUpdateIsoUtcDate)));
    }

    function saveAnnotationAdminComment(annotation) {
      publishedProjectsService.updateAnnotation(annotation);
    }

    function removeAnnotation(annotation) {
      notificationService.confirm('Are you sure you want to delete this comment?')
        .then(() => publishedProjectsService.removeAnnotation(annotation).then(() => _.remove(me.annotations, item => item.transcriptionId === annotation.transcriptionId)))
        .catch(() => { });
    }

    function loadMoreAnnotations() {
      me.annotationListPageSize += me.annotationListPageSize;
    }

    function loadMoreProjects() {
      me.publishedProjectListPageSize += me.publishedProjectListPageSize;
    }

    function getPublicProjectLinkFromProject(project) {
      return sharedProjectsService.getProjectPublicLink(project);
    }

    function getPublicProjectLink(annotation) {
      const project = {
        id: annotation.projectId,
        name: annotation.projectName,
        owner: {
          id: annotation.projectOwnerId
        }
      };
      return sharedProjectsService.getProjectPublicLink(project);
    }
  }

})();
