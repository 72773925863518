(function () {
  'use strict';

  angular
    .module('ss.client.pages')
    .component('projectTranscriptsPageComponent', {
      controller: ProjectTranscriptsPageComponent,
      bindings: {
        project: '<',
        user: '<'
      },
      templateUrl: 'app/client/pages/project-transcripts-page/project-transcripts-page.component.html'
    });

  ProjectTranscriptsPageComponent.$inject = ['$location', 'stateService', 'authService', '$rootScope', '$uibModal', '$q', '$window',
    'SS_CONSTANT', 'transcriptionService', 'customAnalyticsService', 'mediaService', 'sharedProjectsService', 'speakerService',
    'storageService', 'errorService', 'notificationService', 'projectService', 'webSocketProxy', 'sharedService', 'languagesService'
  ];

  function ProjectTranscriptsPageComponent($location, stateService, authService, $rootScope, $uibModal, $q, $window,
    SS_CONSTANT, transcriptionService, customAnalyticsService, mediaService, sharedProjectsService, speakerService,
    storageService, errorService, notificationService, projectService, webSocketProxy, sharedService, languagesService) {

    const me = this;

    me.$onInit = onInit;
    me.$onDestroy = onDestroy;
    me.disconnectionAlertId = null;
    me.reconnectCount = 0;
    me.isReconnecting = false;
    me.isMacApp = false;
    me.onProjectChange = onProjectChange;
    me.onProjectFpsChange = onProjectFpsChange;
    me.onProjectVideoDimensionsChange = onProjectVideoDimensionsChange;
    me.onProjectTranscribed = onProjectTranscribed;
    me.onTranscriptionChange = onTranscriptionChange;
    me.getProjectLanguageDirection = getProjectLanguageDirection;
    me.onSaveTranscript4 = onSaveTranscript4;
    me.onBookmarkChange = onBookmarkChange;
    me.onClickHome = onClickHome;
    me.onTranscriptionAdd = onTranscriptionAdd;
    me.onTranscriptionDelete = onTranscriptionDelete;
    me.onProjectUpdate = onProjectUpdate;
    me.onProjectFpsUpdate = onProjectFpsUpdate;
    me.onProjectVideoDimensionsUpdate = onProjectVideoDimensionsUpdate;
    me.onMediaChange = onMediaChange;
    me.onMediaSelect = onMediaSelect;
    me.onClickShareButton = onClickShareButton;
    me.onClickTranslateButton = onClickTranslateButton;
    me.onClickExportButton = onClickExportButton;
    me.onSpeakerAdd = onSpeakerAdd;
    me.onSpeakerDelete = onSpeakerDelete;
    me.onSpeakerUpdate = onSpeakerUpdate;
    me.isAdmin = isAdmin;
    me.isProjectOwner = isProjectOwner;
    me.isTeamMember = isTeamMember;
    me.tryToLock = tryToLock;
    me.forcePs4Save = forcePs4Save;
    me.isStickyActive = true;
    me.waveSoundOptions = {
      waveColor: '#BAA6CB',
      progressColor: '#7e56a2'
    };
    me.onGetVersions = getTranscriptHistories
    me.onGetTranscriptByVersion = getTranscriptByVersion
    me.onRevertVersion = revertVersion

    function onInit() {
      if (localStorage.getItem('platform')) {
        me.isMacApp = true;
      }
      $rootScope.isStickyActive = false;
      retrieveLanguages();
      checkIfProjectIsSharedWithTeam();
      checkIfProjectIsPublic();
      isReadOnly();

      let currentMedia = null;
      const searchObject = $location.search();
      if (searchObject.fileId) {
        currentMedia = getMediaFromId(me.project.medias, searchObject.fileId);
      }
      if (!currentMedia) {
        currentMedia = sharedService.getShortestMedia(me.project);
      }
      if (currentMedia) {
        onMediaSelect(currentMedia);
      }

      isCurrentMediaLockByOtherUser();
      setInitialRemainingTime();
      subscribeToEvents();
    }

    function retrieveLanguages() {
      const languages = storageService.get('languages');
      if (languages) {
        me.languages = languages
      } else {
        languagesService.getAll().then(languages => me.languages = languages);
      }
    }

    function checkIfProjectIsSharedWithTeam() {
      sharedProjectsService.getAll().then(response => me.isProjectShared = _.some(response, {
        id: me.project.id
      }));
    }

    function checkIfProjectIsPublic() {
      sharedProjectsService.isPublic(me.project.id).then(response => me.isProjectPublished = response && response.isPublic === true);
    }

    function isReadOnly() {
      return sharedProjectsService.isReadOnly(me.project.id).then(response => {
        me.isReadOnly = response;
        return me.isReadOnly;
      });
    }

    function onDestroy() {
      $rootScope.isStickyActive = false;
      unsubscribeFromEvents();
      beforeUnload();
    }

    function onClickHome() {
      stateService.goTo('app.dashboard', { directory: me.project.directory });
    }

    function subscribeToEvents() {
      webSocketProxy.subscribe(webSocketProxy.type.projectMediaUpdate, me.project.id, updateForMedia => {
        if (!authService.isAuthenticated()) return;
        notificationService.log('updateForMedia', updateForMedia);
        const media = me.project.medias.find(mediaItem => updateForMedia.id === mediaItem.id);
        if (me.currentMedia.id === updateForMedia.id) {
          if (me.currentMedia.status !== updateForMedia.status) {
            showMediaTranscriptions(updateForMedia);
          }
          const oldIsLockState = isCurrentMediaLockByOtherUser();
          const oldLockUser = currentMediaLockByUser();
          angular.merge(me.currentMedia, updateForMedia);
          const newIsLockState = isCurrentMediaLockByOtherUser();
          const newLockUser = currentMediaLockByUser();
          if (!oldIsLockState && newLockUser && newLockUser !== me.user.email) {
            me.forcePs4Save(me.currentMedia.id);
            notificationService.warning(`${newLockUser} has taken over editing this file.`);
          }
          if (!me.isReadOnly && !newIsLockState && oldIsLockState) {
            if (me.currentMedia.lockByUserId === me.user.id) {
              notificationService.confirm(`Reload the page to get the latest changes from ${oldLockUser}`).then(() => {
                // reload transcription to get the latest edits
                showMediaTranscriptions(media);
              }).catch(() => { });
            } else {
              notificationService.warning(`This file has been unlocked for editing.`);
              mediaService.tryToLock(media.id);
              // reload transcription to get the latest edits
              showMediaTranscriptions(media);
            }
          }
        }
        if (media) {
          angular.merge(media, updateForMedia);
        }
      });

      const longestMedia = getLongestDurationMedia();
      if (longestMedia) {
        webSocketProxy.subscribe(webSocketProxy.type.mediaTranscodingSpeed, longestMedia.id, data => me.endTimeForTranscribing = getEndTimeForTranscribing(data.speed, data.leftToTranscribeInSeconds));
      }

      if (!isProjectOwner()) {
        webSocketProxy.subscribe(webSocketProxy.type.unsharedProject, me.user.id, unsharedProjectId => {
          if (me.project.id === unsharedProjectId) {
            notificationService.warning('This project has been deleted or unshared with you.');
            onClickHome();
          }
        });
        webSocketProxy.subscribe(webSocketProxy.type.changedRightSharedProjectWith, me.user.id, () => {
          isReadOnly().then(showMediaLockedByOtherUserWarning);
        });
      }

      errorService.subscribeMediaErrors(me.project.id, error => notificationService.errorCatcher(error.message)(error.reason));
      webSocketProxy.subscribe(webSocketProxy.type.projectStatusUpdate, me.project.id, data => {
        if (data && data.status) {
          if (data.status === 'failed') {
            stateService.reload();
          }
          if (me.project.status !== data.status && (data.status === 'translated' || data.status === 'subtitled' || data.status === 'ready')) {
            if (data.status === 'translated') {
              notificationService.success('Project is translated 🚀');
            } else if (data.status === 'subtitled') {
              notificationService.success('Project has been subtitled.');
            } else {
              onProjectTranscribed();
            }
          }
          me.project.status = data.status;
          sharedService.redirectToProject(me.project);
        }
      });

      webSocketProxy.setDisconnectCallback(() => {
        me.reconnectCount = 1;
        me.isReconnecting = true;
        let disconnectNotice = notificationService.error('Internet connection lost. Edits made now will not save until reconnected. Reconnecting... (attempt #1)');
        me.disconnectionAlertId = disconnectNotice.id;
      });
      webSocketProxy.setReconnectTickCallback(() => {
        me.reconnectCount++;
        notificationService.remove(me.disconnectionAlertId);
        let disconnectNotice = notificationService.error(`Internet connection lost. Edits made now will not save until reconnected. Reconnecting... (attempt # ${me.reconnectCount})`);
        me.disconnectionAlertId = disconnectNotice.id;
      });
      webSocketProxy.setReconnectCallback(() => {
        me.isReconnecting = false;
        notificationService.remove(me.disconnectionAlertId);
        notificationService.success(`Hooray, we're back online.`, 5000);
      });
    }

    function beforeUnload() {
      if (isCurrentMediaLockByCurrentUser()) {
        mediaService.tryToUnLockSync(me.currentMedia.id);
      }
    }

    function unsubscribeFromEvents() {
      webSocketProxy.unsubscribe(webSocketProxy.type.projectMediaUpdate, me.project.id);
      if (!isProjectOwner()) {
        webSocketProxy.unsubscribe(webSocketProxy.type.unsharedProject, me.user.id);
      }
      webSocketProxy.unsubscribe(webSocketProxy.type.mediaTranscodingSpeed, getLongestDurationMedia().id);
      if (me.project && me.project.medias) {
        me.project.medias.forEach(media => mediaService.tryToUnLock(media.id));
      }
      errorService.unsubscribeFromMediaErrors(me.project.id);
      if (me.project && me.project.id) {
        webSocketProxy.unsubscribe(webSocketProxy.type.projectStatusUpdate, me.project.id);
      }
      webSocketProxy.setDisconnectCallback(null);
      webSocketProxy.setReconnectCallback(null);
      webSocketProxy.setReconnectTickCallback(null);
    }

    function onSpeakerAdd(newSpeaker, mediaId) {
      return speakerService.add(newSpeaker, mediaId).then(createdSpeaker => {
        $rootScope.$broadcast('projectTranscripts.createdSpeaker', {
          mediaId: mediaId,
          createdSpeaker: createdSpeaker
        });
        return createdSpeaker;
      });
    }

    function onSpeakerDelete(speakerToDelete, mediaId) {
      speakerService.remove(speakerToDelete, mediaId).then(() => {
        $rootScope.$broadcast('projectTranscripts.deletedSpeaker', {
          mediaId: mediaId,
          speakerToDeleteId: speakerToDelete.id
        });
      });
    }

    function onSpeakerUpdate(speakerToUpdate, mediaId) {
      speakerService.update(speakerToUpdate, mediaId);
    }

    function isProjectOwner() {
      return me.user && me.project && me.project.owner ? me.project.owner.id === me.user.id : false;
    }

    function isAdmin() {
      return me.user && ['ROLE_SUPERADMIN', 'ROLE_ADMIN'].indexOf(me.user.role.name) !== -1 ? true : false;
    }

    function isTeamMember() {
      return me.user && me.user.team && me.project.owner && me.project.owner.team ? me.user.team.id === me.project.owner.team.id : false;
    }

    function isCurrentMediaLockByCurrentUser() {
      return me.user && me.currentMedia && me.currentMedia.lockByUserId ? me.currentMedia.lockByUserId === me.user.id : false;
    }

    function getMediaFromId(medias, mediaId) {
      let media = null
      if (medias && medias.length) {
        medias.forEach(mediaItem => {
          if (mediaItem.id === mediaId)
            media = mediaItem;
        });
      }
      return media;
    }

    function getEndTimeForTranscribing(transcriptionSpeed, leftToTranscribeInSeconds) {
      const transcriptionProcessDuration = transcriptionSpeed * leftToTranscribeInSeconds;
      const now = new Date();
      const endTranscriptionDate = $window.moment(now).add(transcriptionProcessDuration, 'seconds').toDate();
      return endTranscriptionDate.getTime();
    }

    function getLongestDurationMedia() {
      return _.maxBy(me.project.medias, 'duration');
    }

    function isCurrentMediaLockByOtherUser() {
      me.isCurrentMediaLockByOtherUser = !me.isAdmin() && me.currentMedia && me.currentMedia.lockByUserId ? me.currentMedia.lockByUserId !== me.user.id : false;
      return me.isCurrentMediaLockByOtherUser;
    }

    function currentMediaLockByUser() {
      return me.isAdmin() || (me.currentMedia && me.user.email === me.currentMedia.lockByUser) ? null : me.currentMedia.lockByUser;
    }

    function getProjectLanguageDirection(project) {
      if (angular.isArray(me.languages) && project.languageRegionCode) {
        const languageCode = project.languageRegionCode.split(/-(.+)/)[0];
        const language = me.languages.find(languageItem => languageItem.code === languageCode);
        if (language) {
          return language.direction;
        }
      }
      return '';
    }

    function onProjectFpsChange(projectId, fps) {
      const payload = {
        fps: fps
      };
      projectService.updateToAllMedias(projectId, payload);
    }

    function onProjectVideoDimensionsChange(projectId, width, height) {
      const payload = {
        width: width,
        height: height
      };
      projectService.updateToAllMedias(projectId, payload);
    }

    function onProjectChange(updateForProject) {
      angular.merge(me.project, updateForProject);
      projectService.save(me.project);
    }

    function onProjectUpdate(updatedProject) {
      me.onProjectChange({
        updatedProject: updatedProject
      });
    }

    function onProjectFpsUpdate(projectId, fps) {
      me.onProjectFpsChange(projectId, fps);
    }

    function onProjectVideoDimensionsUpdate(projectId, width, height) {
      me.onProjectVideoDimensionsChange(projectId, width, height);
    }

    function onProjectTranscribed() {
      let transcribedMessageInfo = `TaDa! ${me.project.name} transcribed. For subtitles/captions: export to the <a style="text-decoration: underline; color: white;" target="_blank" rel="noopener noreferrer" href="https://help.simonsaysai.com/en/articles/3870087-visual-subtitle-editor">Visual Subtitle Editor</a>. For audio/video edit mode: <a style="text-decoration: underline; color: white;" target="_blank" rel="noopener noreferrer" href="https://help.simonsaysai.com/en/articles/4694359-walk-through-of-simon-says-assemble-video">launch Assemble</a>.`;
      notificationService.success(transcribedMessageInfo, true);
      // This is a hack because of production sometimes lost real time connection
      if (me.currentMedia.status !== 'transcribed' && me.currentMedia.status !== 'translated' && me.currentMedia.status !== 'subtitled') {
        stateService.reload();
      }
    }

    function onClickExportButton(preSelectedSoftwareAppName) {
      $uibModal.open({
        animation: true,
        component: 'exportTranscriptionModal',
        resolve: {
          mediaCount: () => angular.isArray(me.project.medias) ? me.project.medias.length : 0,
          currentMediaId: () => me.currentMedia.id,
          project: () => me.project,
          currentMediaName: () => me.currentMedia.name,
          selectedSoftwareAppName: () => preSelectedSoftwareAppName
        }
      });
    }

    function onClickShareButton() {
      if (isProjectOwner()) {
        const modalInstance = $uibModal.open({
          animation: true,
          backdrop: 'static',
          component: 'shareProjectModal',
          resolve: {
            project: () => me.project,
            isProjectOwner: isProjectOwner,
            isTeamMember: isTeamMember
          }
        });

        modalInstance.result.then(response => {
          // In order to save the public description
          if (me.project.publicDescription !== response.publicDescription) {
            me.project.publicDescription = response.publicDescription;
            me.onProjectChange(me.project);
          }
        });

      }
    }

    function onClickTranslateButton() {
      if (isProjectOwner()) {
        const sourceProjectLanguageCode = me.project.languageRegionCode.split(/-(.+)/)[0];
        const availableTranslateLanguages = me.languages.filter(language => language.translationCode && language.code !== sourceProjectLanguageCode);

        $uibModal.open({
          animation: true,
          backdrop: 'static',
          component: 'translateProjectModal',
          resolve: {
            availableTranslateLanguages: () => availableTranslateLanguages,
            projectId: () => me.project.id,
            user: me.user
          }
        });
      }
    }

    function onMediaChange(updatedMedia) {
      mediaService.saveMediaData(updatedMedia);
    }

    function onMediaSelect(selectedMedia) {
      const previousMediaId = me.currentMedia ? me.currentMedia.id : null;
      me.currentMedia = selectedMedia;
      isCurrentMediaLockByOtherUser();
      $location.search('fileId', me.currentMedia.id);
      me.project.medias.forEach(mediaItem => {
        if (mediaItem.id === previousMediaId) {
          mediaService.tryToUnLock(mediaItem.id);
        }
      });

      mediaService.tryToLock(me.currentMedia.id);
      showMediaTranscriptions(me.currentMedia);
      showMediaLockedByOtherUserWarning();
    }

    function tryToLock() {
      mediaService.tryToLock(me.currentMedia.id, true);
    }

    function forcePs4Save() {
      $rootScope.$broadcast('ps4.forceSave', me.currentMedia.id);
    }

    function showMediaTranscriptions(media) {
      if (media.status === 'transcribed' || media.status === 'translated' || media.status === 'subtitled') {
        transcriptionService.getByMedia(media.id).then(response => me.transcriptions = clearTranscript(response));
      } else {
        me.transcriptions = [];
      }
    }

    function showMediaLockedByOtherUserWarning() {
      const warningMessage = 'This file is locked as a colleague has it open. To edit and unlock it, click the lock icon next to the file name.';
      const isLockedByOtherUser = isCurrentMediaLockByOtherUser();
      const lockedByUser = currentMediaLockByUser();

      if (isLockedByOtherUser && lockedByUser && lockedByUser !== me.user.email) {
        if (me.isReadOnly === undefined) {
          isReadOnly().then((_isReadOnly) => {
            if (!_isReadOnly) {
              notificationService.warning(warningMessage);
            }
          });
        } else if (!me.isReadOnly) {
          notificationService.warning(warningMessage);
        }
      }
    }

    function getTranscriptHistories(mediaId) {
      return transcriptionService.getTranscriptHistories(mediaId)
    }

    function getTranscriptByVersion(mediaId, version) {
      return transcriptionService.getByMedia(mediaId, new URLSearchParams({ version }))
    }

    function revertVersion(transcriptRows, mediaId, version) {
      return onSaveTranscript4(transcriptRows, mediaId, new URLSearchParams({ revert: version }))
    }

    function clearTranscript(transcriptionRows) {
      const transcriptions = transcriptionRows.map(transcription => addUIPropertiesToTranscription(transcription));
      return _.filter(transcriptions, transcriptionItem => !_.isNil(transcriptionItem.startTime));
    }

    function addUIPropertiesToTranscription(transcription) {
      transcription.words = [];
      if (transcription.wordsJson) {
        try {
          transcription.words = angular.fromJson(transcription.wordsJson);
          transcription.readOnlyText = transcription.words.map(word => word ? word.text : '').join('');
        } catch (error) {
          notificationService.log(error);
        }
      }
      if (angular.isArray(transcription.words) && transcription.words.length &&
        transcription.words[0] && transcription.words[transcription.words.length - 1]) {
        transcription.startTime = transcription.words[0].startTime;
        transcription.endTime = transcription.words[transcription.words.length - 1].endTime;
      }
      return transcription;
    }

    function onSaveTranscript4(transcript, mediaId, params = new URLSearchParams({})) {
      const transcriptRows = _.values(transcript).map(transcriptRow => {
        transcriptRow.wordsJson = JSON.stringify(transcriptRow.wordsJson)
        return transcriptRow;
      });
      return transcriptionService.saveTranscript(transcriptRows, mediaId, params).then(() => clearTranscript(transcriptRows));
    }

    function onBookmarkChange(transcript) {
      const transcriptRows = _.values(transcript).map(transcriptRow => {
        transcriptRow.wordsJson = JSON.stringify(transcriptRow.wordsJson)
        return transcriptRow;
      });
      return $q.resolve(clearTranscript(transcriptRows));
    }

    function onTranscriptionChange(updatedTranscription, mediaId) {
      saveTranscription(updatedTranscription, mediaId);
    }

    function onTranscriptionAdd(newTranscription, mediaId) {
      transcriptionService.save(newTranscription, mediaId);
    }

    function onTranscriptionDelete(transcriptionToDeleteId, mediaId) {
      transcriptionService.delete(mediaId, transcriptionToDeleteId);
    }

    function saveTranscription(transcription, mediaId) {
      if (transcription) {
        customAnalyticsService.trackEvent('Transcriptions', 'Save Transcription', null, null);
        transcriptionService.save(transcription, mediaId)
          .then(() => customAnalyticsService.trackEvent('Transcriptions', 'Save Transcription success', null, null))
          .catch(reason => {
            customAnalyticsService.trackEvent('Transcriptions', 'Save Transcription error', null, reason);
            notificationService.errorCatcher('There was an error while saving the transcript. Your latest changes or edits have not saved. Try again by clicking "Save Now". If the error persists or you cannot see "Save Now": contact Customer Support and keep this window open.')(reason);
          });
      }
    }

    function setInitialRemainingTime() {
      let projectTranscriptionSpeed = 1;
      if (me.project && me.project.defaultSpeechApi) {
        const speechApiSpeed = SS_CONSTANT.SPEECH_API_SPEED[me.project.defaultSpeechApi];
        projectTranscriptionSpeed = speechApiSpeed ? speechApiSpeed : 1;
      }
      me.endTimeForTranscribing = getEndTimeForTranscribing(projectTranscriptionSpeed, getLeftTimeToTranscribe());
    }

    function getLeftTimeToTranscribe() {
      if (getLastTranscriptionEndTime()) {
        return getLongestDurationMedia().duration - getLastTranscriptionEndTime();
      }
      let startDate = new Date();
      if (me.currentMedia && me.currentMedia.startedTranscriptionTimeInUtc) {
        startDate = new Date(me.currentMedia.startedTranscriptionTimeInUtc);
      }
      const expectedEndDate = $window.moment(startDate).add(getLongestDurationMedia().duration, 'seconds').toDate();
      const now = new Date();

      return (expectedEndDate.getTime() - now.getTime()) / 1000;
    }

    function getLastTranscriptionEndTime() {
      return me.transcriptions && me.transcriptions.length ? parseFloat(me.transcriptions[me.transcriptions.length - 1].endTime) : 0;
    }

  }
})();
