(function () {
  'use strict';

  angular
    .module('ss.admin.services')
    .factory('defaultConfigService', defaultConfigService);

  defaultConfigService.$inject = ['dataService'];

  function defaultConfigService(dataService) {
    const endPoint = 'admin/config';
    const service = {
      getDefaultConfig: getDefaultConfig,
      updateDefaultConfig: updateDefaultConfig
    };
    return service;

    //////////////////

    function getDefaultConfig() {
      return dataService.get(endPoint).catch(() => dataService.throwException('Error getting default config info.'));
    }

    function updateDefaultConfig(payload) {
      return dataService.put(endPoint, payload).catch(() => dataService.throwException('Error updating default config info.'));
    }

  }
})();
