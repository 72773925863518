(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('updateProfilePicModal', {
      controller: UpdateProfilePicModalComponent,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/update-profile-pic-modal/update-profile-pic-modal.component.html'
    });

  UpdateProfilePicModalComponent.$inject = ['SS_CONSTANT', 'notificationService', 'usersService', 'fileHelperService'
  ];

  function UpdateProfilePicModalComponent(SS_CONSTANT, notificationService, usersService, fileHelperService) {

    const me = this;
    const SELECT_CUSTOM_IMAGE = '../../../img/noneProfilePic.png';

    me.$onInit = onInit;
    me.ok = ok;
    me.cancel = cancel;
    me.uploadLocalFile = uploadLocalFile;
    me.profilePicChanged = profilePicChanged;

    function onInit() {
      me.customProfilePic = {
        id: 0,
        url: SELECT_CUSTOM_IMAGE,
        previewUrl: SELECT_CUSTOM_IMAGE
      };
      setDefaultProfilePics();
      me.updateDisabled = false;
    }

    function setDefaultProfilePics() {
      usersService.getDefaultAvatars().then(response => {
        me.defaultProfilePics = response;
        me.defaultProfilePics = updateProfilePics(me.defaultProfilePics);
      });
    }

    function updateProfilePics(defaultProfilePics) {
      if (!defaultProfilePics) {
        defaultProfilePics = [];
      }

      defaultProfilePics = defaultProfilePics.map(defaultProfilePic => ({
        id: defaultProfilePic.id,
        url: defaultProfilePic.url
      }));

      defaultProfilePics.push(me.customProfilePic);
      return defaultProfilePics;
    }

    function ok() {
      const profilePicUrl = me.selectedDefaultProfilePic !== SELECT_CUSTOM_IMAGE ? me.selectedDefaultProfilePic : me.customProfilePic.url;
      me.updateDisabled = false;
      const payload = {
        avatarUrl: profilePicUrl
      };
      usersService.saveUserFullNameAndAvatar(payload).then(() => me.close());
    }

    function cancel() {
      me.dismiss();
    }

    function profilePicChanged() {
      if (me.selectedDefaultProfilePic === SELECT_CUSTOM_IMAGE && me.customProfilePic.url === '../../../img/noneProfilePic.png') {
        me.updateDisabled = true;
        me.exportErrorMessage = 'Please select a custom file or a default pic to update your profile pic.'
      } else {
        me.updateDisabled = false;
      }
    }

    function uploadLocalFile(file) {
      const payload = generatePayload(file);
      if (payload) {
        if (payload.size > 300001) {
          notificationService.error(`Gosh. ${payload.name} is greater than 300Kb.`);
        } else {
          uploadLocalProcess(file, payload);
        }
      }
    }

    function generatePayload(file) {
      const fileName = file.name || file.fileName;
      const fileExtension = fileHelperService.getFileExtension(fileName);
      if (SS_CONSTANT.SUPPORTED_IMAGE_FILES[fileExtension]) {
        const mediaType = SS_CONSTANT.SUPPORTED_IMAGE_FILES[fileExtension].type;
        return { name: fileName, type: mediaType, size: file.size };
      } else {
        notificationService.error(`Gosh. ${fileName} is an unsupported format. See FAQ for acceptable formats.`);
      }
    }

    function uploadLocalProcess(file, payload) {
      const preSignedUrlParams = {
        fileName: payload.name,
        mimeType: file.type
      };

      usersService.getSignedUrlForImage(preSignedUrlParams)
        .then(signedResponse => {
          const config = {
            headers: {
              'Content-Type': file.type
            }
          };

          usersService.uploadAvatarToServer(signedResponse.url, file, config).then(() => {
            me.customProfilePic.url = signedResponse.url;
            me.customProfilePic.previewUrl = getPreviewUrl(signedResponse.url);
            me.customProfilePic.url = getPreviewUrl(signedResponse.url);
            profilePicChanged();
          });
        });
    }

    function getPreviewUrl(url) {
      return url.replace(/(\?[^/?]+)+$/, '');
    }

  }
})();
