(function () {
  'use strict';

  angular
    .module('ss.common.services')
    .factory('sharedProjectsService', sharedProjectsService);

  sharedProjectsService.$inject = ['dataService', 'PUBLIC_SITE_URL', 'PUBLIC_SITE_TRANSCRIPT_URL', 'stringHelperService'];

  function sharedProjectsService(dataService, PUBLIC_SITE_URL, PUBLIC_SITE_TRANSCRIPT_URL, stringHelperService) {
    const endPoint = 'shared-projects';
    const service = {
      getAll: getAll,
      add: add,
      getAllSharedWithMe: getAllSharedWithMe,
      getPeopleISharedProjectWith: getPeopleISharedProjectWith,
      getPeopleUserSharedProjectWith: getPeopleUserSharedProjectWith,
      isPublic: isPublic,
      isReadOnly: isReadOnly,
      removeShareWithMe: removeShareWithMe,
      getUserNamePartLink: getUserNamePartLink,
      getProjectNamePartLink: getProjectNamePartLink,
      getProjectPublicLink: getProjectPublicLink,
      getProjectTranscriptPublicLink: getProjectTranscriptPublicLink
    };
    return service;
    ////////////////

    function getAll() {
      return dataService.get(endPoint);
    }

    function add(payload) {
      return dataService.post(endPoint, payload).catch(reason => dataService.throwException(reason, 'There was an error sharing this project.'));
    }

    function getAllSharedWithMe() {
      return dataService.get(endPoint, '-with-me');
    }

    function getPeopleISharedProjectWith(id) {
      const path = `/${id}/members`;
      return dataService.get(endPoint, path);
    }

    function getPeopleUserSharedProjectWith(id) {
      const path = `/${id}/project-members`;
      return dataService.get(endPoint, path);
    }

    function isPublic(id) {
      const path = `/${id}/isPublic`;
      return dataService.get(endPoint, path);
    }

    function isReadOnly(id) {
      const path = `/${id}/isReadOnly`;
      return dataService.get(endPoint, path);
    }

    function removeShareWithMe(id) {
      const path = `/${id}/remove-share-with-me`;
      return dataService.post(endPoint, path);
    }

    function removeUnsafeChars(stringToReplace) {
      let result = stringHelperService.removeAllSpecialChars(stringToReplace, true);
      result = stringHelperService.removeExtraWhiteSpaces(result);
      result = result.trim().toLowerCase();
      result = stringHelperService.replaceAll(result, ' ', '-');

      return result;
    }

    function getUserNamePartLink(user) {
      const usernamePart = user.fullName;
      return removeUnsafeChars(usernamePart);
    }

    function getProjectNamePartLink(project) {
      return removeUnsafeChars(project.name);
    }

    function getProjectPublicLink(project) {
      const projectPart = getProjectNamePartLink(project);
      return PUBLIC_SITE_URL + '/' + project.owner.id + '/' + projectPart + '/' + project.id;
    }

    function getProjectTranscriptPublicLink(project) {
      const projectPart = getProjectNamePartLink(project);
      return PUBLIC_SITE_TRANSCRIPT_URL + '/' + project.owner.id + '/' + projectPart + '/' + project.id;
    }

  }
})();
