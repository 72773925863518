(function () {
  'use strict';

  angular
    .module('ss.admin.services')
    .factory('subscriptionAdminService', subscriptionAdminService);

  subscriptionAdminService.$inject = ['dataService'];

  function subscriptionAdminService(dataService) {
    const endPoint = 'admin/subscriptions/users/';
    const service = {
      fetchUserSubscription: fetchUserSubscription,
    };
    return service;

    ///////////////////////////

    function fetchUserSubscription(userId) {
      return dataService.get(endPoint, userId);
    }

  }
})();
