(function () {
  'use strict';

  angular
    .module('ss.admin.services')
    .factory('blackWhiteListService', blackWhiteListService);

  blackWhiteListService.$inject = ['dataService'];

  function blackWhiteListService(dataService) {
    const endPoint = 'admin/blacklist-whitelist';
    const service = {
      getAll: getAll,
      save: save,
      deleteReq: deleteReq
    };
    return service;

    /////////////////////

    function getAll() {
      return dataService.get(endPoint);
    }

    function save(payload) {
      return dataService.post(endPoint, payload);
    }

    function deleteReq(payload) {
      return dataService.post(endPoint, payload, '/delete');
    }

  }
})();
