(function () {

  'use strict';

  angular
    .module('ss.client.services')
    .factory('authService', authService);

  authService.$inject = ['$injector', 'stateService', 'APP_CONFIG', 'angularAuth0', 'storageService', '$window', '$document'];

  function authService($injector, stateService, APP_CONFIG, angularAuth0, storageService, $window, $document) {
    const service = {
      getAccessToken: getAccessToken,
      getAuthHeaderName: getAuthHeaderName,
      getTokenType: getTokenType,
      handleAuthentication: handleAuthentication,
      login: login,
      loginWithGoogle: loginWithGoogle,
      createPassword: createPassword,
      changePassword: changePassword,
      renewTokens: renewTokens,
      logout: logout,
      isAuthenticated: isAuthenticated,
      isAdmin: isAdmin,
      isSuperAdmin: isSuperAdmin,
    };
    return service;

    ////////////////////////////////////////////

    function getExpiresAt() {
      return storageService.get('expiresAt');
    }

    function goHomePage() {
      stateService.goTo('app.dashboard');
    }

    function goSharingProject() {
      const projectId = $document[0].cookie.replace(/(?:(?:^|.*;\s*)sharing\s*\=\s*([^;]*).*$)|^.*$/, '$1');
      if (projectId) {
        $document[0].cookie = 'sharing= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
        $window.location.replace('/app/projects/' + projectId);
      }
    }

    function localLogin(authResult) {
      storageService.set('accessToken', authResult.accessToken);
      storageService.set('expiresAt', (authResult.expiresIn * 1000) + new Date().getTime());
    }

    function getAccessToken() {
      return storageService.get('accessToken');
    }

    function getAuthHeaderName() {
      return 'Authorization';
    }

    function getTokenType() {
      return 'Bearer';
    }

    function handleAuthentication() {
      const notificationService = $injector.get('notificationService');
      angularAuth0.parseHash((reason, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken && authResult.idTokenPayload) {
          localLogin(authResult);
          goSharingProject();
          goHomePage();
        } else if (reason) {
          console.error(reason);
          let errorMessage = `This user can't access to the site. For more info please contact Support.`;
          if (reason.errorDescription) {
            errorMessage = reason.errorDescription;
          } else if (reason.error) {
            errorMessage = reason.error;
          }
          notificationService.error(errorMessage, true);
          logout();
          stateService.goTo('LandingPageComponent');
        }
      });
    }

    function login() {
      angularAuth0.authorize();
    }

    function loginWithGoogle() {
      angularAuth0.authorize({
        prompt: 'login',
        connection: 'google-oauth2'
      });
    }

    function createPassword(options) {
      return new Promise((resolve, reject) => {
        angularAuth0.signup({
          email: options.email,
          password: options.password,
          userMetadata: options.userMetadata,
          connection: 'Username-Password-Authentication'
        }, error => {
          if (error) {
            reject(error);
          }
          resolve(options.email);
        })
      });
    }

    function changePassword(email) {
      return new Promise((resolve, reject) => {
        angularAuth0.changePassword({
          email: email,
          connection: 'Username-Password-Authentication'
        }, error => {
          if (error) {
            return reject(error);
          }
          resolve(email);
        })
      });
    }

    function renewTokens() {
      const notificationService = $injector.get('notificationService');
      return new Promise((resolve, reject) => {
        angularAuth0.checkSession({}, (error, result) => {
          if (error) {
            notificationService.log(error);
            logout();
          } else {
            localLogin(result);
            if (stateService.getCurrentName() === 'AuthCallbackPageComponent') {
              goHomePage();
            } else {
              resolve();
            }
          }
        });
      });
    }

    function logout() {
      storageService.remove('accessToken');
      storageService.remove('expiresAt');
      storageService.remove('isLogged');
      storageService.remove('languages');
      angularAuth0.logout({
        returnTo: APP_CONFIG.LOGOUT_REDIRECT_TO,
      });
    }

    function isAuthenticated() {
      return getAccessToken() && new Date().getTime() < getExpiresAt();
    }

    function isAdmin(user) {
      return user && user.role && ['ROLE_SUPERADMIN', 'ROLE_ADMIN'].indexOf(user.role.name) !== -1 ? true : false;
    }

    function isSuperAdmin(user) {
      return user && user.role && user.role.name === 'ROLE_SUPERADMIN' ? true : false;
    }

  }
})();
