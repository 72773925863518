(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('mergeProjectPageComponent', {
      controller: MergeProjectPageComponent,
      bindings: {},
      templateUrl: 'app/admin/pages/merge-project-page/merge-project-page.controller.html'
    });

  MergeProjectPageComponent.$inject = ['stateService', '$stateParams', 'projectAdminService', 'adminUserService', 'timecodeHelper', 'sharedService'];

  function MergeProjectPageComponent(stateService, $stateParams, projectAdminService, adminUserService, timecodeHelper, sharedService) {
    const me = this;
    const {
      projectId,
      userId
    } = $stateParams;
    const temp = [];
    let duration;
    me.$onInit = onInit;
    me.userDetailedView = userDetailedView;
    me.checkIteams = checkIteams;
    me.getFrameSeparatorString = getFrameSeparatorString;
    me.goToTranscript = goToTranscript;
    me.selectedList = {};
    me.project = {};
    me.sourceProjectListing = [];
    me.projectName = '';

    function onInit() {
      adminUserService.loadSourceProjects(projectId, userId).then(response => me.sourceProjectListing = response);
      projectAdminService.getProject(projectId).then(response => {
        me.project = response;
        me.files = response.files;
        for (let i = 0; i < me.files.length; i++) {
          const file = me.files[i];
          file.fps = String(file.fps);
          file.mediaTimecode = timecodeHelper.getMediaTimeCode(file);
          duration = me.files[i].duration;
          file.duration_ = timecodeHelper.toHHMMSSFormat(duration);
          temp.push(file);
        }
        me.files = temp;
        me.projectName = response.name;
      });
    }

    function userDetailedView() {
      stateService.goTo('app.admin.userManagementItem', {
        userId: userId
      });
    }

    function getFrameSeparatorString(media) {
      return timecodeHelper.getFrameSeparatorString(media)
    }

    function checkIteams() {
      const sourceProject = [];
      angular.forEach(me.selectedList, (selected, sourceProjectIds) => {
        if (selected) {
          sourceProject.push(sourceProjectIds);
        }
      });
      projectAdminService.mergeProjectRequest({
        target: projectId,
        'source-projects': sourceProject
      }).then(() => onInit()).catch(() => onInit());
    }

    function goToTranscript() {
      sharedService.redirectToProject(me.project);
    }

  }
})();
