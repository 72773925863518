(function () {
    'use strict';
  
    angular
      .module('ss.common.services')
      .factory('dictionaryService', dictionaryService);
  
    dictionaryService.$inject = ['dataService', 'notificationService'];
  
    function dictionaryService(dataService, notificationService) {
      const endpoint = 'dictionary';
      const service = {
        getAll: getAll,
        create: createDictionary,
        update: updateDictionary,
        delete: deleteDictionary
      };
      
      return service;
  
      function getAll() {
        return dataService.get(endpoint);
      }

      function createDictionary(payload) {
        return dataService.post(endpoint, payload)
          .then(() => notificationService.success('The dictionary has been created.'))
          .catch(createErrorHandler('Error creating the dictionary.'));
      }
  
      function updateDictionary(payload) {
        return dataService.post(endpoint, payload)
          .then(() => notificationService.success('The dictionary has been updated.'))
          .catch(createErrorHandler('Failed to update the dictionary.'));
      }

      function deleteDictionary(id) {
        return dataService.delete(endpoint, '/' + id)
          .then(() => notificationService.success('The dictionary has been deleted.'))
          .catch(createErrorHandler('Failed to delete the dictionary.'));
      }

      function createErrorHandler(message) {
        return (responseError) => {
          if (responseError.status === 422) {
            throw responseError;
          }
          dataService.throwException(reason, message + dataService.getFailureCause(responseError));
        }
      }
  
    }
  })();
  