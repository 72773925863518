(function () {
  'use strict';

  angular
    .module('ss.client.components')
    .component('colorPicker', {
      controller: ColorPickerController,
      bindings: {
        color: '='
      },
      templateUrl: 'app/client/components/color-picker/color-picker.component.html'
    });

  function ColorPickerController() {
    const me = this;
    me.setColor = setColor;

    me.defaultColors = [
      { name: 'mint', color: '#1abc9c' },
      { name: 'blue', color: '#3498bd' },
      { name: 'purple', color: '#9b59b6' },
      { name: 'dark', color: '#34495e' },
      { name: 'yellow', color: '#f1c40f' },
      { name: 'orange', color: '#e67e22' },
      { name: 'red', color: '#e74c3c' },
      { name: 'grey', color: '#bdc3c7' },
      { name: 'green', color: '#2ecc71' },
      { name: 'dark-green', color: '#16a085' }
    ];

    function setColor(color) {
      me.color = color;
    }

  }
})();
