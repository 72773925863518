(function () {
  'use strict';

  angular
    .module('app')
    .config(appConfig);

  appConfig.$inject = ['$httpProvider', '$locationProvider', '$qProvider', 'AUTH0_CONFIG', 'AnalyticsProvider', 'angularAuth0Provider',
    'customEcommerceAnalyticsProvider', 'hotkeysProvider', 'toastrConfig', 'lkGoogleSettingsProvider'
  ];

  function appConfig($httpProvider, $locationProvider, $qProvider, AUTH0_CONFIG, AnalyticsProvider, angularAuth0Provider,
    customEcommerceAnalyticsProvider, hotkeysProvider, toastrConfig, lkGoogleSettingsProvider) {

    $locationProvider.html5Mode(true);
    $locationProvider.hashPrefix('');
    hotkeysProvider.templateTitle = '';
    hotkeysProvider.template =
      `<div class='cfp-hotkeys-container fade' ng-class="{in: helpVisible}" style='display: none;'><div class='cfp-hotkeys'>
        <h4 class='cfp-hotkeys-title' ng-if="!header">{{ title }}</h4>
        <div ng-bind-html="header" ng-if="header"></div>
        <ul class='pull-left' style='width: 50%'>
        <h4 class='cfp-hotkeys-title' ng-if="!header">Keyboard Shortcuts</h4>
          <li ng-repeat="hotkey in hotkeys | filter:{ description: \'!$$undefined$$\' }" style='width: 100%; display: flex;'>
            <div class='cfp-hotkeys-keys pull-left' style='width:50%;'>
              <span ng-repeat="key in hotkey.format() track by $index" class='cfp-hotkeys-key'>{{ key }}</span>
            </div>
            <div class='pull-right' style='width:50%; padding: 10px;'>
              <div class='cfp-hotkeys-text' >{{ hotkey.description }}</div>
            </div>
          </li>
        </ul>
        <div ng-bind-html="footer" ng-if="footer"></div>
        <div class='cfp-hotkeys-close' ng-click="toggleCheatSheet()">&#215;</div>
      </div></div>`;

    hotkeysProvider.templateFooter = `
    <div class='hotkeys-footer pull-right'>
      <h4 class='cfp-hotkeys-title footer-title text-left'> What can I do on this page? </h4>
      <div class='smart-commands-help-footer'>
        <ol>
            <li> <a href='https://www.youtube.com/watch?v=jE--LVWkufc&feature=emb_title' target='_blank' rel='noopener noreferrer'>
            Edit the text and add speaker names. It auto-saves every 2 mins.</a></li>
            <li> Bookmark and annotate key parts of the transcript.</li>
            <li> <a href='https://help.simonsaysai.com/en/articles/2770173-auto-sync-timecode-framerate-and-resolution' target='_blank' rel='noopener noreferrer'>
             Sync timecode. </a></li>
            <li> <a href='https://help.simonsaysai.com/en/articles/2657992-how-to-translate-your-project' target='_blank' rel='noopener noreferrer'>
                  Translate</a> the transcript. Export to the <a href='https://help.simonsaysai.com/en/articles/3870087-visual-subtitle-editor' target='_blank' rel='noopener noreferrer'>
                  Visual Subtitle Editor</a>. <a href='https://help.simonsaysai.com/en/articles/2650685-which-formats-can-i-export-my-transcript-to' target='_blank' rel='noopener noreferrer'>
                 Export to your video editing app</a>, Word, subtitles, and many other formats.
            </li>
            <li> <a href='https://help.simonsaysai.com/en/articles/4123009-how-to-share-projects' target='_blank' rel='noopener noreferrer'>
            Invite team members.</a></li>
        </ol>
      </div>
      </div>`;

    angularAuth0Provider.init(AUTH0_CONFIG);

    lkGoogleSettingsProvider.configure({
      clientId: '152544279525-ch4ilufbugjort8monvml57l14qtmhj6.apps.googleusercontent.com',
      scopes: ['https://www.googleapis.com/auth/drive.readonly'],
      features: ['SUPPORT_TEAM_DRIVES', 'MULTISELECT_ENABLED']
    });

    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    $httpProvider.interceptors.push('authInterceptor');

    // Google Analytics Configuration
    const isEcommerceEnabled = true; // Set this true on production
    AnalyticsProvider.setAccount('UA-87674016-1').logAllCalls(true).useECommerce(isEcommerceEnabled, isEcommerceEnabled);
    customEcommerceAnalyticsProvider.enableEcommerce(isEcommerceEnabled);

    $qProvider.errorOnUnhandledRejections(false);

    angular.extend(toastrConfig, {
      allowHtml: true,
      closeButton: true,
      closeHtml: '<button>&times;</button>',
      timeOut: 0,
      extendedTimeOut: 0,
      tapToDismiss: false,
      preventDuplicates: false,
      preventOpenDuplicates: true,
      positionClass: 'toast-top-center'
    });

  }
})();
