(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('teamDetailPageComponent', {
      controller: TeamDetailPageComponent,
      bindings: {
        teamId: '<'
      },
      templateUrl: 'app/admin/pages/team-detail-page/team-detail-page.component.html'
    });

  TeamDetailPageComponent.$inject = ['adminTeamService', 'subscriptionAdminService', 'notificationService', 'emailHelper'];

  function TeamDetailPageComponent(adminTeamService, subscriptionAdminService, notificationService, emailHelper) {
    const me = this;
    let backupFormData;

    me.subscriptionCreditExpireDate = {
      opened: false
    };
    me.dateOptions = {
      showWeeks: false
    };
    me.$onInit = onInit;
    me.isSubscriptionCreditValid = isSubscriptionCreditValid;
    me.removeTeamMember = removeTeamMember;
    me.saveChanges = saveChanges;
    me.cancelChanges = cancelChanges;
    me.addNewTeamMember = addNewTeamMember;
    me.openSubscriptionCreditExpireDate = openSubscriptionCreditExpireDate;

    function onInit() {
      me.loaded = false;
      retreiveTeamInfo();
      retreiveOwnerInfo();
      retreiveMembersInfo();
      retreiveTeamTransactions();
    }

    function makeBackupData() {
      backupFormData = Object.assign({}, me.team);
    }

    function retreiveTeamInfo() {
      adminTeamService.getTeam(me.teamId).then(response => {
        me.team = response;
        me.loaded = true;
        me.subscriptionCreditExpireDate = new Date(me.team.subscriptionCreditExpireDate);
        makeBackupData();
      });
    }

    function retreiveOwnerInfo() {
      adminTeamService.getOwnerByTeamId(me.teamId).then(response => {
        me.owner = response;
        return subscriptionAdminService.fetchUserSubscription(me.owner.id);
      }).then(response => me.stripeSubscriptionDetail = response);
    }

    function retreiveMembersInfo() {
      adminTeamService.getMembersByTeamId(me.teamId).then(response => me.members = response);
    }

    function retreiveTeamTransactions() {
      adminTeamService.getTeamTransactions(me.teamId).then(response => me.transactions = response);
    }

    function isSubscriptionCreditValid() {
      return me.team && !me.team.subscriptionCredit || (me.subscriptionCreditExpireDate && me.subscriptionCreditExpireDate > new Date());
    }

    function saveChanges() {
      me.team.subscriptionCreditExpireDate = me.subscriptionCreditExpireDate ? Date.parse(me.subscriptionCreditExpireDate) : Date.parse(new Date(null));
      adminTeamService.updateTeam(me.teamId, me.team).then(response => {
        me.team = response;
        makeBackupData();
        me.teamDetailsForm.$setPristine();
      });
    }

    function cancelChanges() {
      me.teamDetailsForm.$setPristine();
      me.team = Object.assign({}, backupFormData);
    }

    function removeTeamMember(member) {
      notificationService.confirm(`Are you sure you want to remove the user ${member.email} from this team?`)
        .then(() => adminTeamService.removeTeamMember(me.teamId, member.id).then(() => onInit()))
        .catch(() => { });
    }

    function addNewTeamMember() {
      if (emailHelper.validate(me.newUserEmail)) {
        adminTeamService.addNewTeamMember(me.teamId, me.newUserEmail).then(() => {
          me.newUserEmail = '';
          onInit()
        });
      } else {
        notificationService.error('Email is invalid.');
      }
    }

    function openSubscriptionCreditExpireDate() {
      me.subscriptionCreditExpireDate.opened = true;
    }

  }
})();
