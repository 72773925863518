(function () {
  'use strict';

  angular
    .module('ss.common.filters')
    .filter('estTime', estTime);

  estTime.$inject = ['$filter'];

  function estTime($filter) {
    return date => $filter('date')(date, 'short', '-0400');
  }

})();
