(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .factory('fullStoryWrapperService', fullStoryWrapperService);

  fullStoryWrapperService.$inject = [];

  function fullStoryWrapperService() {
    const service = {
      identify: identify,
      logout: logout
    };
    return service;

    //////////////////

    function identify(user) {
      if (typeof FS !== 'undefined' && user && user.id) {
        FS.identify(user.fsUid, {
          email: user.email
        });
      }
    }

    function logout() {
      if (typeof FS !== 'undefined') {
        FS.identify(false);
      }
    }

  }
})();
