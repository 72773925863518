(function () {
  'use strict';

  angular
    .module('ss.admin.helpers')
    .factory('emailHelper', emailHelper);

    emailHelper.$inject = [];

  function emailHelper() {
    const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const helper = {
      validate: validate
    };
    return helper;

    /////////////////////

    function validate(email) {
      return email && EMAIL_REGEXP.test(email) ? true : false;
    }

  }
})();
