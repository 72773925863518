(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('addCreditModal', {
      controller: AddCreditModalComponent,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/add-credit-modal/add-credit-modal.component.html'
    });

  AddCreditModalComponent.$inject = ['$uibModal', 'dataService', 'projectService', 'notificationService', 'usersService', 'stateService'];

  function AddCreditModalComponent($uibModal, dataService, projectService, notificationService, usersService, stateService) {
    const me = this;

    me.$onInit = onInit;
    me.cancel = cancel;
    me.buyCredit = buyCredit;
    me.onCreditHoursSelected = onCreditHoursSelected;
    me.openSubscribeModal = openSubscribeModal;

    function onInit() {
      me.planInfo = me.resolve.planInfo;
      me.planPricePerMinute = getPricePerMinute(me.planInfo.pricePerHour);

      me.addCreditHoursOptions = [1, 2, 5, 10, 20, 50, 100].map(hoursQuantity => ({
        label: hoursQuantity + (hoursQuantity > 1 ? ' hours' : ' hour'),
        value: hoursQuantity
      }));
      setInitialState();
    }

    function setInitialState() {
      me.addCreditTotal = 0.00;
      me.disabledSubmitButton = false;
      me.selectedAddCreditHours = me.addCreditHoursOptions[0];

      onCreditHoursSelected();
    }

    function onCreditHoursSelected() {
      me.disabledSubmitButton = true;
      const timeInSeconds = hoursToSeconds(me.selectedAddCreditHours.value);
      const queryString = dataService.httpParamSerializer({
        durationInSeconds: timeInSeconds,
        buyCredit: true
      }, '?');

      return dataService.get('price' + queryString)
        .then(response => {
          me.addCreditTotal = response.totalPrice;
          setCreditTotal(me.addCreditTotal);
          me.disabledSubmitButton = false;
        })
        .catch(reason => dataService.throwException(reason, 'Failed to calculate the price for the selected credit time.'));
    }

    function buyCredit(useSavedCard, shouldSaveCard, card) {
      me.disabledSubmitButton = true;

      const buyCreditParams = {
        buyCreditInSeconds: hoursToSeconds(me.selectedAddCreditHours.value),
      };
      let queryString = dataService.httpParamSerializer(buyCreditParams, '?');

      if (!useSavedCard) {
        const cardParams = {
          cardHolderName: card.cardHolderName,
          number: card.cardNumber,
          exp_month: card.expirationMonth,
          exp_year: card.expirationYear,
          cvc: card.cvv,
          saveCard: shouldSaveCard,
          replaceCard: true
        };

        queryString += dataService.httpParamSerializer(cardParams, '&');
      }

      return projectService.buyCredit(queryString)
        .then(() => {
          const time = me.selectedAddCreditHours.value > 1 ? 'hours' : 'hour';
          notificationService.success(`Yaay! Your credit of ${me.selectedAddCreditHours.value} ${time} was added to your account.`);
          usersService.reloadUser();
          me.close();
          if (stateService.getCurrentName() === 'app.admin.userManagementItem') {
            stateService.reload();
          }
        })
        .catch(reason => {
          me.disabledSubmitButton = false;
          notificationService.error(reason.data.message);
        });
    }

    function openSubscribeModal() {
      const modalInstance = $uibModal.open({
        animation: true,
        backdrop: 'static',
        component: 'subscribeModal',
        resolve: {
          planInfo: () => me.planInfo
        }
      });
      modalInstance.result.then(modalResponse => {
        notificationService.success(`Yaay! You subscribed to the ${modalResponse.planName} plan. We’re happy we will be seeing you around more often 🚀.`, true);
      });
      cancel();
    }

    function setCreditTotal(value) {
      me.creditTotal = { currency: '$' };
      me.creditTotal.whole = Math.floor(value);
      me.creditTotal.decimal = formatNumber(value - me.creditTotal.whole).substr(2, 2);
    }

    function formatNumber(value, fractionDigits = 2) {
      return Number(value).toFixed(fractionDigits);
    }

    function hoursToSeconds(hours) {
      return hours * 60 * 60;
    }

    function getPricePerMinute(pricePerHour) {
      const pricePerMinute = Number(pricePerHour / 60 * 100);
      const fraction = pricePerMinute % 1;

      if (fraction >= 0.6) {
        let firstDecimal = Number(`${fraction}`.substr(2, 1)) + 1;
        const roundedDownPrice = pricePerMinute - fraction;
        return `${roundedDownPrice}.${firstDecimal}`;
      }

      return pricePerMinute - fraction;
    }

    function cancel() {
      me.dismiss();
    }
  }
})();
