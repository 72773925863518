(function () {
  'use strict';

  angular
    .module('ss.client.components')
    .component('credits', {
      controller: CreditsComponent,
      bindings: {
        credit: '<'
      },
      templateUrl: 'app/client/components/credits/credits.component.html'
    });

  function CreditsComponent() {
    const me = this;

    me.getHours = getHours;

    function getHours() {
      return me.credit ? (me.credit.days * 24) + me.credit.hours : null;
    }

  }
})();
