(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .service('speakerService', speakerService);

  speakerService.$inject = ['dataService'];

  function speakerService(dataService) {
    const endPoint = 'media';
    const service = {
      add: add,
      update: update,
      remove: remove,
    };
    return service;

    //////////////////////

    function add(payload, mediaId) {
      const path = `/${mediaId}/speaker`;
      return dataService.post(endPoint, payload, path);
    }

    function update(payload, mediaId) {
      const path = `/${mediaId}/speaker/${payload.id}`;
      return dataService.put(endPoint, payload, path);
    }

    function remove(payload, mediaId) {
      const path = `/${mediaId}/speaker/${payload.id}`;
      return dataService.delete(endPoint, path);
    }

  }
})();
