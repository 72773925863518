(function () {
  'use strict';

  angular
    .module('ss.common.services')
    .factory('webSocketProxy', webSocketProxy);

  webSocketProxy.$inject = ['webSocketConnectionService'];

  function webSocketProxy(webSocketConnectionService) {
    const type = {
      newProject: 'newProject/user',
      sharedProject: 'sharedProjectWith/user',
      unsharedProject: 'unsharedProjectWith/user',
      userData: 'userData',
      errors: 'errors',
      unexpectedErrors: 'unexpectedErrors',
      uploadProgress: 'uploadProgress',
      projectStatusUpdate: 'projectStatusUpdate',
      projectMediaUpdate: 'mediaUpdate/project',
      mediaTranscodingSpeed: 'mediaTranscodingSpeed',
      mediaUpdate: 'mediaUpdate/project',
      update: 'projectUpdate',
      statusUpdate: 'projectStatusUpdate',
      changedRightSharedProjectWith: 'changedRightSharedProjectWith/user'
    };
    const service = {
      type: type,
      subscribe: subscribe,
      unsubscribe: unsubscribe,
      setDisconnectCallback: setDisconnectCallback,
      setReconnectCallback: setReconnectCallback,
      setReconnectTickCallback: setReconnectTickCallback
    };
    return service;

    //////////////////////////

    function subscribe(type, id, callback) {
      webSocketConnectionService.subscribe(type, id, callback);
    }

    function unsubscribe(type, id) {
      webSocketConnectionService.unsubscribe(type, id);
    }

    function setDisconnectCallback(callback) {
      webSocketConnectionService.setDisconnectCallback(callback);
    }

    function setReconnectCallback(callback) {
      webSocketConnectionService.setReconnectCallback(callback);
    }

    function setReconnectTickCallback(callback) {
      webSocketConnectionService.setReconnectTickCallback(callback);
    }

  }
})();
