(function () {
  'use strict';

  angular
    .module('ss.common.services')
    .factory('sharedService', sharedService);

  sharedService.$inject = ['SS_CONSTANT', 'stateService', 'storageService', 'customAnalyticsService'];

  function sharedService(SS_CONSTANT, stateService, storageService, customAnalyticsService) {
    const STATUS = SS_CONSTANT.PROJECT_STATUS;
    const uploadedStatusList = [
      STATUS.READY,
      STATUS.TRANSCRIBING,
      STATUS.TRANSLATING,
      STATUS.TRANSLATED,
      STATUS.SUBTITLING,
      STATUS.SUBTITLED
    ];
    const translatedStatus = [
      STATUS.TRANSLATED,
      STATUS.TRANSLATING
    ];
    this.user = {};
    this.credit = {};
    this.intercomInitialized = false;
    this.crispInitialized = false;
    this.stickyActive = true;
    const service = {
      setUser: setUser,
      getUser: getUser,
      setCredit: setCredit,
      getCredit: getCredit,
      getIntercomInitialized: getIntercomInitialized,
      setIntercomInitialized: setIntercomInitialized,
      getCrispInitialized: getCrispInitialized,
      setCrispInitialized: setCrispInitialized,
      getStickyActive: getStickyActive,
      setStickyActive: setStickyActive,
      redirectToProject: redirectToProject,
      getShortestMedia: getShortestMedia,
      checkIfTranscribed: checkIfTranscribed
    };
    return service;

    //////////////////

    function setUser(user) {
      this.user = user;
      customAnalyticsService.setAmplitudeUser(user);
      if (!storageService.get('isLogged')) {
        isFirstLog(user);
        customAnalyticsService.trackAmplitudeEvent(user);
      }
      storageService.set('isLogged', true);
      customAnalyticsService.setUserId(user.id);
    }

    function isFirstLog(user) {
      const regDate = new Date(user.regDate);
      const utcDate = new Date(regDate.getUTCFullYear(), regDate.getUTCMonth(), regDate.getUTCDate(), regDate.getUTCHours(), regDate.getUTCMinutes(), regDate.getUTCSeconds());

      if (moment(utcDate).isAfter(moment().subtract(1, 'minutes'))) {
        customAnalyticsService.trackRegistrationEvent(user.identities[0]);
      }
    }

    function getUser() {
      return this.user;
    }

    function setCredit(credit) {
      this.credit = credit;
    }

    function getCredit() {
      return this.credit;
    }

    function getIntercomInitialized() {
      return this.intercomInitialized;
    }

    function setIntercomInitialized(crispInitialized) {
      this.crispInitialized = crispInitialized;
    }

    function getCrispInitialized() {
      return this.crispInitialized;
    }

    function setCrispInitialized(crispInitialized) {
      this.crispInitialized = crispInitialized;
    }

    function getStickyActive() {
      return this.stickyActive;
    }

    function setStickyActive(stickyActive) {
      this.stickyActive = stickyActive;
    }

    function redirectToProject(project, mediaId) {
      if (!project || !project.id) {
        stateService.goTo('app.noFound');
      }
      if (isProjectUploaded(project)) {
        if (!mediaId) {
          mediaId = getShortestMedia(project).id;
        }
        stateService.goTo('app.projectTranscript', { projectId: project.id, fileId: mediaId });
      } else {
        stateService.goTo('app.projectUpload', { projectId: project.id });
      }
    }

    function getShortestMedia(project) {
      let shortestMediaDuration = Number.MAX_VALUE;
      let shortestMedia = {};
      if (project.medias && project.medias.length) {
        // if only one media, return it direclty
        if (project.medias.length === 1) {
          return project.medias[0];
        }
        // when translating to all languages, show the first translation file by default
        if (translatedStatus.indexOf(project.status) >= 0) {
          let firstTranslateMedia = project.medias.find(media => media.translateTargetLanguageId);
          if (firstTranslateMedia) {
            return firstTranslateMedia;
          }
        }
        // otherwise return a shortest media duration
        project.medias
          .filter(media => media.status && media.status !== STATUS.EMPTY && media.duration > 0)
          .forEach(media => {
            if (media.duration < shortestMediaDuration) {
              shortestMediaDuration = media.duration;
              shortestMedia = media;
            }
          });
      }
      return shortestMedia;
    }

    function isProjectUploaded(project) {
      return project && uploadedStatusList.indexOf(project.status) >= 0;
    }

    function checkIfTranscribed(project) {
      if (isProjectUploaded(project)) {
        stateService.goTo('app.projectTranscript', { projectId: project.id });
      }
    }

  }
})();
