(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('metricsPageComponent', {
      controller: MetricsPageComponent,
      bindings: {},
      templateUrl: 'app/admin/pages/metrics-page/metrics-page.component.html'
    });

  MetricsPageComponent.$inject = ['$filter', 'dateHelper', 'metricsService'];

  function MetricsPageComponent($filter, dateHelper, metricsService) {
    const me = this;

    me.$onInit = onInit;
    me.getMetrics = getMetrics;
    me.showDetail = showDetail;
    me.exportCsv = exportCsv;

    function onInit() {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const sevenDayAgo = new Date();
      sevenDayAgo.setDate(sevenDayAgo.getDate() - 7);
      me.startDate = $filter('date')(dateHelper.toUTCDate(sevenDayAgo), 'yyyy-MM-dd');
      me.endDate = $filter('date')(dateHelper.toUTCDate(today), 'yyyy-MM-dd');
      getMetrics();
    }

    function requestAndSetMetrics(queryParams) {
      metricsService.query(queryParams).then(res => {
        const thisWeek = res[0];
        const lastWeek = res[1];

        me.newUser = {
          count:thisWeek.newUsers.length,
          users:thisWeek.newUsers
        }

        const newUserLastWeek = lastWeek.newUsers.length;
        if (me.newUser.count - newUserLastWeek >= 0) {
          me.newUser.diff = newUserLastWeek == 0 ? 100 : (me.newUser.count - newUserLastWeek) * 100 / newUserLastWeek;
          me.newUser.colorClass = 'green';
          me.newUser.sortClass = 'fa fa-sort-asc';
        } else {
          me.newUser.diff = (-1) * (me.newUser.count - newUserLastWeek) * 100 / newUserLastWeek;
          me.newUser.colorClass = 'red';
          me.newUser.sortClass = 'fa fa-sort-desc';
        }

        me.createdProject = {
          count:thisWeek.createdProject.createdProjectsCount,
          projects:thisWeek.createdProject.projectList
        }

        const createdProjectsCountLastWeek = lastWeek.createdProject.createdProjectsCount;
          if(me.createdProject.count - createdProjectsCountLastWeek >= 0) {
            me.createdProject.diff = createdProjectsCountLastWeek == 0 ? 100 : (me.createdProject.count - createdProjectsCountLastWeek) * 100 / createdProjectsCountLastWeek;
            me.createdProject.colorClass = 'green';
            me.createdProject.sortClass = 'fa fa-sort-asc';
          } else {
            me.createdProject.diff = (-1)*(me.createdProject.count - createdProjectsCountLastWeek) * 100 / createdProjectsCountLastWeek;
            me.createdProject.colorClass = 'red';
            me.createdProject.sortClass = 'fa fa-sort-desc';
          }

          me.transcribed = {
            count:thisWeek.transcribed.transcribedFilesCount,
            totalMinutes:thisWeek.transcribed.minutesTranscribed,
            languageMap:thisWeek.transcribed.transcribedLanguageMap
          }

          let totalMinutesLastWeek = lastWeek.transcribed.minutesTranscribed;
          if(me.transcribed.totalMinutes - totalMinutesLastWeek >= 0) {
            me.transcribed.diff = totalMinutesLastWeek == 0 ? 100 : (me.transcribed.totalMinutes - totalMinutesLastWeek) * 100 / totalMinutesLastWeek;
            me.transcribed.colorClass = 'green';
            me.transcribed.sortClass = 'fa fa-sort-asc';
          } else {
            me.transcribed.diff = (-1)*(me.transcribed.totalMinutes - totalMinutesLastWeek) * 100 / totalMinutesLastWeek;
            me.transcribed.colorClass = 'red';
            me.transcribed.sortClass = 'fa fa-sort-desc';
          }

          me.translated = {
            count:thisWeek.translated.translatedFilesCount,
            totalMinutes:thisWeek.translated.minutesTranslated,
            languageMap:thisWeek.translated.translatedLanguageMap
          }

          totalMinutesLastWeek = lastWeek.translated.minutesTranslated;
          if(me.translated.totalMinutes - totalMinutesLastWeek >= 0) {
            me.translated.diff = totalMinutesLastWeek == 0 ? 100 : (me.translated.totalMinutes - totalMinutesLastWeek) * 100 / totalMinutesLastWeek;
            me.translated.colorClass = 'green';
            me.translated.sortClass = 'fa fa-sort-asc';
          } else {
            me.translated.diff = (-1)*(me.translated.totalMinutes - totalMinutesLastWeek) * 100 / totalMinutesLastWeek;
            me.translated.colorClass = 'red';
            me.translated.sortClass = 'fa fa-sort-desc';
          }

          me.burn = {
            count:thisWeek.burn.burnFilesCount,
            totalMinutes:thisWeek.burn.minutesBurn,
            languageMap:thisWeek.burn.burnLanguageMap
          }

          totalMinutesLastWeek = lastWeek.burn.minutesBurn;
          if(me.burn.totalMinutes - totalMinutesLastWeek >= 0) {
            me.burn.diff = totalMinutesLastWeek == 0 ? 100 : (me.burn.totalMinutes - totalMinutesLastWeek) * 100 / totalMinutesLastWeek;
            me.burn.colorClass = 'green';
            me.burn.sortClass = 'fa fa-sort-asc';
          } else {
            me.burn.diff = (-1)*(me.burn.totalMinutes - totalMinutesLastWeek) * 100 / totalMinutesLastWeek;
            me.burn.colorClass = 'red';
            me.burn.sortClass = 'fa fa-sort-desc';
          }

          me.exported = {
            files:thisWeek.exportedFile,
            count:_.sumBy(thisWeek.exportedFile, "count")
          }

          const exportLasWeekCount = _.sumBy(lastWeek.exportedFile, "count");
          if(me.exported.count - exportLasWeekCount >= 0) {
            me.exported.diff = exportLasWeekCount == 0 ? 100 : (me.exported.count - exportLasWeekCount) * 100 / exportLasWeekCount;
            me.exported.colorClass = 'green';
            me.exported.sortClass = 'fa fa-sort-asc';
          } else {
            me.exported.diff = (-1)*(me.exported.count - exportLasWeekCount) * 100 / exportLasWeekCount;
            me.exported.colorClass = 'red';
            me.exported.sortClass = 'fa fa-sort-desc';
          }

          me.selectedType = 'user';
          me.selectedTitle = 'New Users';
          me.selectedItems = me.newUser.users;
        })
    }

    function getMetrics() {
      const queryParams = {};
      if (me.startDate) {
        queryParams.starUtcTime = new Date(me.startDate).toISOString().slice(0, -1);
      }
      if (me.endDate) {
        queryParams.endUtcTime = new Date(me.endDate).toISOString().slice(0, -1);
      }
      requestAndSetMetrics(queryParams);
    }

    function showDetail(type) {
      if(type === 'user') {
        me.selectedType = 'user';
        me.selectedTitle = 'New Users';
        me.selectedItems = me.newUser.users;
      }
      else if(type === 'project') {
        me.selectedType = 'project';
        me.selectedTitle = 'Project created';
        me.selectedItems = me.createdProject.projects;
      }
      else if(type === 'transcribed') {
        me.selectedType = 'transcribed';
        me.selectedTitle = 'Transcribed';
        me.selectedItems = me.transcribed.languageMap;
      }
      else if(type === 'translated') {
        me.selectedType = 'translated';
        me.selectedTitle = 'Translated';
        me.selectedItems = me.translated.languageMap;
      }
      else if(type === 'burn') {
        me.selectedType = 'burn';
        me.selectedTitle = 'Burn-in';
        me.selectedItems = me.burn.languageMap;
      }
      else if(type === 'exported') {
        me.selectedType = 'exported';
        me.selectedTitle = 'Exported';
        me.selectedItems = me.exported.files;
      }
    }

    function exportCsv() {
      var csvString = '';
      csvString += "New Users," + "Projects created," + "Transcribed(" + me.transcribed.count + ")," + "Translated(" + me.translated.count + ")," + "Burn-in(" + me.burn.count + ")," + "Exported" + "\n";
      csvString += me.newUser.count + "," + me.createdProject.count + "," + me.transcribed.totalMinutes.toFixed(2) + "," + me.translated.totalMinutes.toFixed(2) + "," + me.burn.totalMinutes.toFixed(2) + "," + me.exported.count + "\n";
      csvString += me.newUser.diff.toFixed(2) + "% From previous Period," + me.createdProject.diff.toFixed(2) + "% From previous Period," + me.transcribed.diff.toFixed(2) + "% From previous Period," + me.translated.diff.toFixed(2) + "% From previous Period," + me.burn.diff.toFixed(2) + "% From previous Period," + me.exported.diff.toFixed(2) + "% From previous Period\n";
      csvString += "\n";

      csvString += "New Users\n\n";

      for(var i=0; i < me.newUser.users.length; i++) {
         csvString += me.newUser.users[i].email + "\n";
      }

      csvString += "\n\nProjects created\n\n";

      for(var i=0; i < me.createdProject.projects.length; i++) {
        csvString += me.createdProject.projects[i].name + "\n";
      }

      csvString += "\n\nTranscribed\n\n";

      for(var i=0; i < me.transcribed.languageMap.length; i++) {
        csvString += me.transcribed.languageMap[i].title + "," + me.transcribed.languageMap[i].minutes.toFixed(2) + "\n";
      }

      csvString += "\n\nTranslated\n\n";

      console.log(me.translated)
      for(var i=0; i < me.translated.languageMap.length; i++) {
        csvString += me.translated.languageMap[i].title + "," + me.translated.languageMap[i].minutes.toFixed(2) + "\n";
      }

      csvString += "\n\nBurn-in\n\n";

      for(var i=0; i < me.burn.languageMap.length; i++) {
        csvString += me.burn.languageMap[i].title + "," + me.burn.languageMap[i].minutes.toFixed(2) + "\n";
      }

      csvString += "\n\nExported\n\n";

      for (var i = 0; i < me.exported.files.length; i++) {
        csvString += me.exported.files[i].title + "," + me.exported.files[i].count + "\n";
      }
      var a = $('<a/>', {
        style: 'display:none',
        href: 'data:application/octet-stream;base64,' + btoa(csvString),
        download: 'ss-metrics.csv'
      }).appendTo('body')
      a[0].click()
      a.remove();
    }
  }
})();
