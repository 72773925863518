(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('userListPageComponent', {
      controller: UserListPageComponent,
      bindings: {},
      templateUrl: 'app/admin/pages/user-list-page/user-list-page.component.html'
    });

  UserListPageComponent.$inject = ['stateService', 'adminUserService'];

  function UserListPageComponent(stateService, adminUserService) {
    const me = this;
    const pageSize = 50;

    me.$onInit = onInit;
    me.updateFilteredList = updateFilteredList;
    me.goToUserDetailPage = goToUserDetailPage;
    me.getUserDisplayName = getUserDisplayName;
    me.querySearch = querySearch;

    function onInit() {
      me.searchText = null;
      me.filterOptions = ['recentlyActive', 'recentlyJoined', 'recentlyDeleted'];
      me.filterType = me.filterOptions[0];
      getRecentActiveUsers();
    }

    function getRecentActiveUsers() {
      adminUserService.getRecentByType(pageSize, 'recentActive').then(response => me.users = response);
    }

    function getRecentJoinedUsers() {
      adminUserService.getRecentByType(pageSize, 'recentJoined').then(response => me.users = response);
    }

    function getDeletedUsers() {
      adminUserService.getDeletedUsers().then(response => me.users = response);
    }

    function querySearch(searchText) {
      if (searchText) {
        adminUserService.getAllThatMatchesKeyword(searchText).then(response => me.userMatches = response);
      }
    }

    function updateFilteredList() {
      if (me.filterType === me.filterOptions[0]) {
        getRecentActiveUsers();
      } else if (me.filterType === me.filterOptions[1]) {
        getRecentJoinedUsers();
      } else {
        getDeletedUsers();
      }
    }

    function goToUserDetailPage(userId) {
      if (userId && me.filterType !== me.filterOptions[2]) {
        stateService.goTo('app.admin.userManagementItem', {
          userId: userId
        });
      }
    }

    function getUserDisplayName(item) {
      return `${item.fullName} (${item.email})`;
    }

  }
})();
