(function () {
  'use strict';
  angular
    .module('ss.client.components')
    .component('folderStructure', {
      controller: FolderStructureComponent,
      bindings: {
        isOpen: '<',
        project: '<',
        popoverTags: '=',
        popoverMedias: '=',
        newUserTag: '=',
        stepTag: '=',
        userTagToUpdate: '=',
        projectToTag: '=',
        userTags: '=',
        userProjectTags: '=',
        projects: '=',
        sharedProjects: '=',
        directories: '=',
        onProjectDeleted: '&?'
      },
      templateUrl: 'app/client/components/folder-structure/folder-structure.component.html'
    });

  FolderStructureComponent.$inject = ['usersService', 'notificationService', 'projectService', '$uibModal', 'sharedService'];

  function FolderStructureComponent(usersService, notificationService, projectService, $uibModal, sharedService) {
    const me = this;

    me.user = sharedService.getUser();
    me.updateLabel = updateLabel;
    me.projectTag = projectTag;
    me.prepareProjectToTag = prepareProjectToTag;
    me.prepareProjectToOpenMedias = prepareProjectToOpenMedias;
    me.toogleTagToProject = toogleTagToProject;
    me.createLabel = createLabel;
    me.deleteUserTag = deleteUserTag;
    me.showConfirmDelete = showConfirmDelete;
    me.openExportProjectModal = openExportProjectModal;
    me.isProjectExportable = isProjectExportable;
    me.setUserAvatar = setUserAvatar;
    me.openShareModal = openShareModal;
    me.goToTranscript = goToTranscript;
    me.staticImgPath = 'img/poster-video.png';

    function retrieveUserTags() {
      usersService.getUserTags().then(response => me.userTags = response);
    }

    function projectTag(projectId) {
      if (angular.isArray(me.userProjectTags)) {
        const userProjectTag = me.userProjectTags.find(userProjectTag => userProjectTag.projectId === projectId);
        if (userProjectTag) {
          if (me.userTags) {
            return me.userTags.find(userTag => userTag.id === userProjectTag.tagId);
          }
        }
      }
    }

    function updateLabel() {
      usersService.updateUserTags(me.userTagToUpdate.id, me.userTagToUpdate).then(() => retrieveUserTags()).then(() => {
        me.userTagToUpdate = {};
        me.stepTag = 0;
      });
    }

    function deleteUserTag(tagId) {
      notificationService.confirm('Are you sure you want to delete this label? This will affect all your projects that were using this label.')
        .then(() => usersService.deleteUserTags(tagId).then(() => retrieveUserTags()))
        .catch(() => { });
    }

    function toogleTagSuccess() {
      retrieveUserProjectTags();
      me.popoverTags[me.projectToTag] = false;
    }

    function retrieveUserProjectTags() {
      usersService.getUserProjectTags().then(response => me.userProjectTags = response);
    }

    function createLabel() {
      usersService.createUserTags(me.newUserTag).then(response => {
        retrieveUserTags();
        return toogleTagToProject(response);
      }).then(() => {
        me.newUserTag = {};
        me.stepTag = 0;
      });
    }

    function toogleTagToProject(userTag, isSelected) {
      if (!isSelected) {
        const payload = {
          tagId: userTag.id,
          projectId: me.projectToTag
        };
        return usersService.associateTagToProject(payload).then(() => toogleTagSuccess());
      } else {
        const userProjectTag = me.userProjectTags.find(item => item.projectId === me.projectToTag);
        return usersService.desassociateTagFromProject(userProjectTag.id).then(() => toogleTagSuccess());
      }
    }

    function prepareProjectToTag(project) {
      me.popoverTags = {};
      me.popoverTags[project] = true;
      me.newUserTag = {};
      me.userTagToUpdate = {};
      me.projectToTag = project;
      me.stepTag = 0;
    }

    function prepareProjectToOpenMedias(project) {
      me.popoverMedias = {};
      me.popoverMedias[project] = true;
      me.projectToOpenMedias = project;
    }

    function showConfirmDelete(projectId) {
      notificationService.confirm('Are you sure you want to delete this project?')
        .then(() => deleteProject(projectId))
        .catch(() => { });
    }

    function deleteProject(projectId) {
      projectService.removeFromDashboard(projectId, me.projects, me.sharedProjects, me.directories)
        .then(() => callOnProjectDeletedHandler());
    }

    function callOnProjectDeletedHandler() {
      if (angular.isFunction(me.onProjectDeleted)) {
        me.onProjectDeleted();
      }
    }

    function isProjectExportable(project) {
      return project.status !== 'open' && project.medias.length > 0;
    }

    function isProjectOwner() {
      return me.user && me.project && me.project.owner ? me.project.owner.id === me.user.id : false;
    }

    function openExportProjectModal(project) {
      $uibModal.open({
        animation: true,
        component: 'exportTranscriptionModal',
        resolve: {
          mediaCount: () => angular.isArray(project.medias) ? project.medias.length : 0,
          currentMediaId: () => project.medias[0].id,
          project: () => project,
          currentMediaName: () => project.medias[0].name
        }
      });
    }

    function openShareModal(project) {
      const modalInstance = $uibModal.open({
        animation: true,
        backdrop: 'static',
        component: 'shareProjectModal',
        resolve: {
          project: () => project,
          isProjectOwner: isProjectOwner()
        }
      });

      modalInstance.result.then(response => {
        // In order to save the public description
        if (me.project.publicDescription !== response.publicDescription) {
          me.project.publicDescription = response.publicDescription;
          projectService.save(me.project);
        }
      });
    }

    function setUserAvatar(owner) {
      return owner.avatar !== null ? owner.avatar : '../../../img/noneProfilePic.png';
    }

    function goToTranscript(mediaId) {
      sharedService.redirectToProject(me.project, mediaId);
    }

  }
})();
