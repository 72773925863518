(function () {
  'use strict';

  angular
    .module('ss.client.pages')
    .component('boxCallbackPageComponent', {
      controller: BoxCallbackPageComponent,
      bindings: {},
      templateUrl: 'app/client/pages/box-callback-page/box-callback-page.component.html'
    });

  BoxCallbackPageComponent.$inject = ['$location', 'boxAuthenticationService'];

  function BoxCallbackPageComponent($location, boxAuthenticationService) {
    const me = this;
    me.$onInit = onInit;

    function onInit() {
      boxAuthenticationService.complete($location.search().accesstoken, $location.search().refreshtoken);
    }

  }
})();
