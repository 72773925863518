(function () {
  'use strict';

  angular
    .module('ss.admin.helpers')
    .factory('dateHelper', dateHelper);

  dateHelper.$inject = [];

  function dateHelper() {
    const helper = {
      toUTCDate: toUTCDate
    };
    return helper;

    /////////////////////

    function toUTCDate(date) {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());
    }

  }
})();
