(function () {
  'use strict';

  angular
    .module('ss.admin.pages', [])
    .config(adminPagesConfig);

  adminPagesConfig.$inject = ['$stateProvider', 'APP_CONFIG'];

  function adminPagesConfig($stateProvider, APP_CONFIG) {
    $stateProvider
      .state('app.admin', {
        url: APP_CONFIG.ROUTE_ADMIN_PREFIX,
        abstract: true,
      })
      .state('app.admin.latestTransaction', {
        url: '/latestTransaction',
        component: 'latestTransactionPageComponent'
      })
      .state('app.admin.adminManagement', {
        url: '/addAdmin',
        component: 'superAdminPageComponent'
      })
      .state('app.admin.blacklistWhitelist', {
        url: '/blacklistWhitelist',
        component: 'blackWhiteListPageComponent'
      })
      .state('app.admin.detailedTransaction', {
        url: '/detailedTransaction/:projectId',
        component: 'detailedTransactionPageComponent'
      })
      .state('app.admin.userManagementList', {
        url: '/users',
        component: 'userListPageComponent'
      })
      .state('app.admin.userManagementItem', {
        url: '/users/:userId',
        component: 'userDetailPageComponent',
        resolve: {
          userId: $stateParams => $stateParams.userId
        }
      })
      .state('app.admin.projectManagementItem', {
        url: '/projectDetailView/:projectId',
        component: 'projectDetailViewPageComponent'
      })
      .state('app.admin.teamManagementList', {
        url: '/teams',
        component: 'teamListPageComponent'
      })
      .state('app.admin.teamManagementItem', {
        url: '/teams/:teamId',
        component: 'teamDetailPageComponent',
        resolve: {
          teamId: $stateParams => $stateParams.teamId
        }
      })
      .state('app.admin.plans', {
        url: '/plans',
        component: 'plansPageComponent'
      })
      .state('app.admin.defaultConfig', {
        url: '/default-config',
        component: 'defaultConfigPageComponent',
      })
      .state('app.admin.projectMerging', {
        url: '/mergeProject/:projectId/:userId',
        component: 'mergeProjectPageComponent'
      })
      .state('app.admin.metrics', {
        url: '/metrics',
        component: 'metricsPageComponent'
      })
      .state('app.admin.publishedProjects', {
        url: '/published-projects',
        component: 'publishedProjectsPageComponent',
        resolve: {
          publishedProjects: (usersService, publishedProjectsService) => usersService.getUserInfo().then(user => {
            if (['ROLE_SUPERADMIN', 'ROLE_ADMIN'].indexOf(user.role.name) === -1) {
              throw new Error('No Authorized');
            }
            return publishedProjectsService.getAll();
          })
        }
      })
      .state('app.noAuthorize', {
        url: '/no-authorize',
        component: 'noAuthorizePageComponent'
      })
      .state('boxAuthCallbackPage', {
        url: '/box-auth-completion',
        component: 'boxCallbackPageComponent'
      });
  }

})();
