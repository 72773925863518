(function () {
  "use strict";

  angular.module("ss.client.modals").component("shareProjectModal", {
    controller: ShareProjectModalComponent,
    bindings: {
      resolve: "<",
      close: "&",
      dismiss: "&",
    },
    templateUrl:
      "app/client/modals/share-project-modal/share-project-modal.component.html",
  });

  ShareProjectModalComponent.$inject = [
    "$location",
    "sharedProjectsService",
    "usersService",
    "notificationService",
    "$timeout",
    "$document",
  ];

  function ShareProjectModalComponent(
    $location,
    sharedProjectsService,
    usersService,
    notificationService,
    $timeout,
    $document
  ) {
    const me = this;

    me.$onInit = onInit;
    me.ok = ok;
    me.copy = copy;
    me.rightAccessOptions = [
      {
        name: "DISABLED",
        title: "Disabled",
        shortTitle: "disable",
      },
      {
        name: "VIEW",
        title: "Can view",
        shortTitle: "view",
      },
      {
        name: "EDIT",
        title: "Can edit",
        shortTitle: "edit",
      },
    ];
    me.publishOptions = [
      {
        name: "DISABLED",
        title: "Disabled",
        visible: true,
      },
      {
        name: "PUBLISH",
        title: "Publish",
        visible: true,
      },
      {
        name: "RE-PUBLISH",
        title: "Re-Publish",
        visible: true,
      },
    ];
    me.inviteUser = "";
    me.linkSelectedIndex = 0;
    me.team = {
      selectedIndex: 0,
    };
    me.inviteSelectedIndex = 0;
    me.inviteEmailInputFieldDisabled = true;
    me.publishSelectedIndex = 0;
    me.dropdownSelected = dropdownSelected;
    me.shareInvite = shareInvite;
    me.onChangeInviteUser = onChangeInviteUser;
    me.saveShareSetting = saveShareSetting;
    me.sercetLink = "";
    me.publishLink = "";
    const EMAIL_REGEXP =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const TEAM_MEMBER_RIGHT_LOWER_THAN_TEAM_RIGHT_WARNING =
      "The person can still {0} as user is part\n of the team and team has {0} rights.";
    const TEAM_RIGHT_LOWER_THAN_TEAM_MEMBER_RIGHT_WARNING =
      "Team members with existing {0} rights have not been updated";
    const DISABLED_SHARE_WARNING = "The user still has access through the URL";
    const MEMBER_RIGHT_LOWER_THAN_SECRET_LINK_RIGHT =
      "The user still has {0} access through the URL";

    function onInit() {
      me.isTeamMember = me.resolve.isTeamMember;
      me.publicationDescriptionLimit = 255;
      me.publicDescription = me.resolve.project.publicDescription;
      getInviteCode();
      generateEditAccessLink();
      getProjectPublicLink();

      sharedProjectsService
        .getPeopleISharedProjectWith(me.resolve.project.id)
        .then((peopleISharedWith) => {
          const memberArrayIsSharedWith = peopleISharedWith.filter(
            (people) => people.email
          );
          me.members = memberArrayIsSharedWith.map((memberItem) => {
            const _index = _.findIndex(me.rightAccessOptions, [
              "name",
              memberItem.right,
            ]);
            return {
              type: memberItem.type,
              email: memberItem.email,
              status: memberItem.status,
              style: {
                opacity: memberItem.status === "INACTIVE" ? 0.4 : 1,
              },
              right: memberItem.right,
              selectedIndex: _index,
              isTeamMember: memberItem.isTeamMember,
            };
          });

          let linkShared;
          peopleISharedWith.forEach((item) => {
            if (item.type === "LINK" && !item.email) {
              linkShared = item;
            }
          });
          me.linkSelectedIndex = linkShared
            ? _.findIndex(me.rightAccessOptions, ["name", linkShared.right])
            : 0;
          me.sercetLink = me.linkSelectedIndex === 0 ? "" : me.originShareLink;
          const publishShared = _.find(peopleISharedWith, {
            type: "PUBLISH",
          });
          if (publishShared) {
            me.publishSelectedIndex = 1;
            me.publishLink = me.originPublishLink;
          } else {
            me.publishSelectedIndex = 0;
            me.publishOptions[2].visible = false;
            me.publishLink = "";
          }
          const teamShared = _.find(peopleISharedWith, {
            type: "TEAM",
          });
          let teamSelIndex = 0;
          if (teamShared) {
            teamSelIndex = _.findIndex(me.rightAccessOptions, [
              "name",
              teamShared.right,
            ]);
          }
          me.team.selectedIndex = teamSelIndex ? teamSelIndex : 0;
          updateWarningOnChangedRight();
        });
    }

    function onChangeInviteUser() {
      const inviteEmails = me.inviteUser.split(",").slice(0, 20);
      me.inviteUser = inviteEmails.join(",");
    }

    function shareInvite() {
      if (me.rightAccessOptions[me.inviteSelectedIndex].name === "DISABLED") {
        notificationService.error("The shareable link is disabled.");
        return;
      }
      me.inviteUser = me.inviteUser
        ? me.inviteUser.trim().toLowerCase()
        : me.inviteUser;
      if (!me.inviteUser) {
        return;
      }
      const inviteEmails = me.inviteUser.split(",");
      for (let inviteEmail of inviteEmails) {
        if (!inviteEmail || !EMAIL_REGEXP.test(inviteEmail.trim())) {
          notificationService.error('Email "' + inviteEmail + '" is invalid');
          return;
        }

        if (_.findIndex(me.members, ["email", inviteEmail.trim()]) >= 0) {
          notificationService.error(
            'The shareable link is already shared with the user "' +
              inviteEmail +
              '"'
          );
          return;
        }
      }
      me.inviteUser = inviteEmails.join(",");

      const sharePayload = {
        id: me.resolve.project.id,
        invite: {
          right: me.rightAccessOptions[me.inviteSelectedIndex].name,
          email: me.inviteUser,
        },
      };
      sharedProjectsService.add(sharePayload).then(() => {
        const inviteEmails = me.inviteUser.split(",");
        inviteEmails.forEach((inviteEmail) => {
          const member = {
            type: "INVITE",
            email: inviteEmail.trim(),
            status: "INACTIVE",
            style: {
              opacity: 0.4,
            },
            right: me.rightAccessOptions[me.inviteSelectedIndex].name,
            selectedIndex: me.inviteSelectedIndex,
          };
          me.members.push(member);
        });
        me.inviteUser = "";
        updateWarningOnChangedRight();
      });
    }

    function ok() {
      me.close({
        $value: {
          publicDescription: me.publicDescription,
        },
      });
    }

    function saveShareSetting() {
      const payload = {
        id: me.resolve.project.id,
        linkRight: me.rightAccessOptions[me.linkSelectedIndex].name,
        teamRight: me.rightAccessOptions[me.team.selectedIndex].name,
        shareMember: me.members
          ? me.members.map((memberItem) => {
              const index = memberItem.selectedIndex;
              return {
                email: memberItem.email,
                right: me.rightAccessOptions[index].name,
              };
            })
          : undefined,
        publishAction: me.publishOptions[me.publishSelectedIndex].name,
        publicDescription: me.publicDescription,
      };

      sharedProjectsService.add(payload).then((response) => {
        if (response && response.length > 0) {
          response.forEach((memberItem) => {
            const newMember = {
              type: memberItem.type,
              email: memberItem.email,
              status: memberItem.status,
              style: {
                opacity: memberItem.status === "INACTIVE" ? 0.4 : 1,
              },
              right: memberItem.right,
              selectedIndex: _.findIndex(me.rightAccessOptions, [
                "name",
                memberItem.right,
              ]),
              isTeamMember: memberItem.isTeamMember,
            };
            if (newMember.email && newMember.email.trim() !== "") {
              me.members.push(newMember);
            }
          });
        }
      });
    }

    function generateEditAccessLink() {
      me.originShareLink =
        $location.protocol() +
        "://" +
        location.host +
        "/app/projects/" +
        me.resolve.project.id +
        "?sharing=" +
        me.resolve.project.id +
        (me.inviteCode ? "&inviteCode=" + me.inviteCode : "");
    }

    function getInviteCode() {
      usersService
        .getInviteCode()
        .then((response) => (me.inviteCode = response.code));
    }

    function getProjectPublicLink() {
      me.originPublishLink = sharedProjectsService.getProjectPublicLink(
        me.resolve.project
      );
    }

    function copy(id) {
      const copyText = $("#" + id);
      if (copyText[0].value.trim() !== "") {
        copyText.select();
        copyText[0].setSelectionRange(0, 99999);
        $document[0].execCommand("copy");
        const button = copyText.siblings();
        const icon = button.children()[0];

        copyText.addClass("clicked");
        button.addClass("clicked");
        icon.className = "fas fa-check";
        $timeout(() => {
          button.removeClass("clicked");
          copyText.removeClass("clicked");
          icon.className = "fas fa-copy";
        }, 3000);
      }
    }

    function updateWarningOnChangedRight() {
      me.members.forEach((member) => {
        if (member.selectedIndex === 0 && me.linkSelectedIndex !== 0) {
          member.title = DISABLED_SHARE_WARNING;
          member.warningStyle = {
            color: "#F89406",
          };
        } else if (member.selectedIndex < me.linkSelectedIndex) {
          member.title = MEMBER_RIGHT_LOWER_THAN_SECRET_LINK_RIGHT.replace(
            /\{0\}/g,
            me.rightAccessOptions[me.linkSelectedIndex].shortTitle
          );
          member.warningStyle = {
            color: "#F89406",
          };
        } else if (
          member.isTeamMember &&
          member.selectedIndex < me.team.selectedIndex
        ) {
          member.title =
            TEAM_MEMBER_RIGHT_LOWER_THAN_TEAM_RIGHT_WARNING.replace(
              /\{0\}/g,
              me.rightAccessOptions[me.team.selectedIndex].shortTitle
            );
          member.warningStyle = {
            color: "#F89406",
          };
        } else {
          delete member.title;
          delete member.warningStyle;
        }
      });

      const memIndex = _.findIndex(
        me.members,
        (member) =>
          member.isTeamMember && member.selectedIndex > me.team.selectedIndex
      );
      if (memIndex >= 0) {
        me.team.title = TEAM_RIGHT_LOWER_THAN_TEAM_MEMBER_RIGHT_WARNING.replace(
          /\{0\}/g,
          me.rightAccessOptions[me.members[memIndex].selectedIndex].shortTitle
        );
        me.team.warningStyle = {
          color: "#F89406",
        };
      } else {
        delete me.team.title;
        delete me.team.warningStyle;
      }
    }

    function updateInviteUserOptions(selectedOptionIndex) {
      me.inviteEmailInputFieldDisabled =
        me.rightAccessOptions[selectedOptionIndex].name === "DISABLED";
      if (me.inviteEmailInputFieldDisabled) {
        me.inviteUser = "";
      }
    }

    function dropdownSelected(index, type, memberIndex) {
      switch (type) {
        case "everyone":
          me.linkSelectedIndex = index;
          me.sercetLink = index === 0 ? "" : me.originShareLink;
          updateWarningOnChangedRight();
          saveShareSetting();
          break;
        case "team":
          me.team.selectedIndex = index;
          updateWarningOnChangedRight();
          saveShareSetting();
          break;
        case "invite":
          me.inviteSelectedIndex = index;
          updateInviteUserOptions(index);
          break;
        case "member":
          me.members[memberIndex].selectedIndex = index;
          updateWarningOnChangedRight();
          saveShareSetting();
          break;
        case "publish":
          me.publishSelectedIndex = index;
          me.publishLink = index === 0 ? "" : me.originPublishLink;
          saveShareSetting();
          break;
      }
    }
  }
})();
