(function () {
  'user strict';

  angular
    .module('ss.client.services')
    .factory('httpErrorHandler', httpErrorHandler);

  httpErrorHandler.$inject = ['$injector', 'authService'];

  function httpErrorHandler($injector, authService) {
    const service = {
      manageHttpCode: manageHttpCode
    };
    return service;

    //////////////////////

    function manageHttpCode(httpStatusCode, errorMessage) {
      const notificationService = $injector.get('notificationService');
      if (httpStatusCode === 401) {
        authService.logout();
        return true;
      }
      if (httpStatusCode === 403) {
        const $state = $injector.get('$state');
        notificationService.error(errorMessage || 'You are not allowed to access this resource.', true);
        $state.go('app.dashboard');
        return true;
      }
    }

  }
})();
