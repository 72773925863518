(function () {
  'use strict';
  angular
    .module('ss.client.components')
    .component('summaryCost', {
      controller: SummaryCostController,
      bindings: {
        totalTimeText: '@',
        price: '<'
      },
      templateUrl: 'app/client/components/summary-cost/summary-cost.component.html'
    });

  SummaryCostController.$inject = ['$filter'];

  function SummaryCostController($filter) {
    const me = this;
    me.formatPrice = formatPrice;

    function formatPrice(price) {
      const formattedRatePrice = formatRatePrice(price.rate);
      const [whole, fraction] = `${$filter('currency')(price.price, '$')}`.split('.');
      return {
        whole,
        fraction,
        formattedRatePrice
      };
    }

    function formatRatePrice(rate) {
      const fraction = rate % 1;
      if (fraction > 0) {
        return Number(rate).toFixed(2);
      }

      return rate - fraction;
    }
  }
})();
