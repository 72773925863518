(function () {
  'use strict';

  angular
    .module('ss.admin.services')
    .factory('adminUserService', adminUserService);

  adminUserService.$inject = ['dataService', 'stateService', 'notificationService'];

  function adminUserService(dataService, stateService, notificationService) {
    const endPoint = 'admin/users';
    const service = {
      getAll: getAll,
      getRecentByType: getRecentByType,
      getAllThatMatchesKeyword: getAllThatMatchesKeyword,
      getUserById: getUserById,
      getUserTransactions: getUserTransactions,
      getUserProjects: getUserProjects,
      updateUser: updateUser,
      deleteUser: deleteUser,
      synchronizingSubscription: synchronizingSubscription,
      fetchUserStripeInfo: fetchUserStripeInfo,
      unlockProject: unlockProject,
      exportUserData: exportUserData,
      getDeletedUsers: getDeletedUsers,
      loadSourceProjects: loadSourceProjects
    };
    return service;

    //////////////////////////////////

    function getAll(queryParams) {
      let path = '';
      if (queryParams) {
        path = dataService.httpParamSerializer(queryParams, '?');
      }
      return dataService.get(endPoint, path);
    }

    function unlockProject(id) {
      const path = `/${id}/unlock-project`;
      dataService.post(endPoint, path);
    }

    function exportUserData(id) {
      const path = `/${id}/export-data`;
      const config = {
        responseType: 'arraybuffer'
      };
      return dataService.postExport(endPoint, {}, path, config)
        .then(response => dataService.downloadFileLocally(response))
        .catch(reason => dataService.throwException(reason, 'There was a problem exporting user data.'));
    }

    function getRecentByType(topTypeCount, type) {
      return getAll({
        topTypeCount: topTypeCount,
        type: type
      });
    }

    function getAllThatMatchesKeyword(keyword) {
      return getAll({
        keyword: keyword
      });
    }

    function getUserById(id) {
      return dataService.get(endPoint, '/' + id).catch(reason => dataService.logException(reason));
    }

    function getUserTransactions(id, pageNumber, pageSize) {
      const path = buildQueryStringPath('transactions', id, pageNumber, pageSize);
      return dataService.get(endPoint, path).then(response => fixOffsetPageNumber(response));
    }

    function getUserProjects(id, pageNumber, pageSize) {
      const path = buildQueryStringPath('projects', id, pageNumber, pageSize);
      return dataService.get(endPoint, path).then(response => fixOffsetPageNumber(response));
    }

    function updateUser(id, payload) {
      return dataService.put(endPoint, payload, '/' + id).catch(reason => dataService.throwException(reason, 'There was a problem updating user details.'));
    }

    function synchronizingSubscription(id, email) {
      const path = `/${id}/subscription-synchronizing`;
      return dataService.post(endPoint, path)
        .then(() => notificationService.success(`User: ${email} Syncronization was performed succesfully.`))
        .catch(reason => dataService.throwException(reason, 'There was a problem Syncronizing with Stripe subscription.'));
    }

    function fetchUserStripeInfo(id) {
      const path = `/${id}/stripe-info`;
      return dataService.get(endPoint, path);
    }

    function getDeletedUsers() {
      return dataService.get(endPoint, '/deleted-users');
    }

    function deleteUser(id, email) {
      return dataService.delete(endPoint, '/' + id).then(() => {
        notificationService.success(`User: ${email} have been deleted successfully.`, true);
        stateService.goTo('app.admin.userManagementList');
      }).catch(reason => dataService.throwException(reason, 'There was a problem deleting this user.'));
    }

    function fixOffsetPageNumber(pageable) {
      pageable.number = pageable.number + 1;
      return pageable;
    }

    function buildQueryStringPath(type, id, pageNumber, pageSize) {
      let queryString = '';
      if (pageSize) {
        const queryParams = {
          pageNumber: pageNumber || 0,
          pageSize: pageSize
        };
        queryString = dataService.httpParamSerializer(queryParams, '?');
      }
      return `/${id}/${type}${queryString}`;
    }

    function loadSourceProjects(projectId, userId) {
      const path = `/${userId}/projects`;
      return dataService.get(endPoint, path).then(response => {
        const sourceProjects = [];
        angular.forEach(response.content, item => {
          if (projectId !== item.id) {
            sourceProjects.push(item);
          }
        });
        return sourceProjects;
      });
    }

  }
})();
