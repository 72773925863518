(function () {
  'use strict';

  angular
    .module('ss.client.pages')
    .component('landingPageComponent', {
      controller: LandingPageComponent,
      bindings: {},
      templateUrl: 'app/client/pages/landing-page/landing-page.component.html'
    });


  LandingPageComponent.$inject = ['$window', 'LANDING_PAGE_URL'];

  function LandingPageComponent($window, LANDING_PAGE_URL) {
    const me = this;

    me.$onInit = onInit;

    function onInit() {
      $window.open(LANDING_PAGE_URL, '_self');
    }

  }
})();
