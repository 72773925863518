(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('exportTranscriptionModal', {
      controller: ExportTranscriptionModalComponent,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/export-transcription-modal/export-transcription-modal.component.html'
    });

  ExportTranscriptionModalComponent.$inject = ['$uibModal', 'customAnalyticsService', 'storageService',
    'projectService', 'sharedService', 'notificationService', 'mediaService'
  ];

  function ExportTranscriptionModalComponent($uibModal, customAnalyticsService, storageService,
    projectService, sharedService, notificationService, mediaService) {

    const me = this;
    const user = sharedService.getUser();

    me.$onInit = onInit;
    me.ok = ok;
    me.cancel = cancel;
    me.validateCharPerLine = validateCharPerLine;
    me.isExportButtonEnabled = isExportButtonEnabled;
    me.isOnlyBookmarkOrWithTimecode = isOnlyBookmarkOrWithTimecode;
    me.isGapless = isGapless;
    const regex = /.*\(([a-z][a-z])\)/;

    function onInit() {
      me.isRequestInProcess = false;
      const langCode = me.resolve.project.languageRegionCode.substring(0, 2);
      me.languageCode = me.resolve.project.projectStatus !== 'translated' ? langCode : getLanguageCode(langCode);
      me.exportErrorMessage = '';
      me.mainAppNames = {
        word: 'word',
        translate: 'translate',
        adobePremiere: 'adobePremiere',
        finalCutProX: 'finalCutProX',
        avidMediaComposer: 'avidMediaComposer',
        subtitles: 'subtitles',
        visual: 'visual',
        burnIn: 'burnIn',
        share: 'share',
        other: 'other'
      };
      getAvailableVideoInProjects();
      getAvailableTranslationLanguages();
      setSoftwareApps();
      me.selectedSoftwareAppName = me.resolve.selectedSoftwareAppName || me.softwareApps[0].name;
      me.selectedOptionName = '';
      me.withTimeCode = true;
      me.onlyBookmarks = false;
      me.currentMediaId = me.resolve.currentMediaId;
      me.withSingleLine = false;
      me.withUppercase = false;
      me.withGapless = false;
      me.characterPerLine = 32;
    }

    function getLanguageCode(langCode) {
      const fileName = me.resolve.currentMediaName;
      return regex.test(fileName) ? fileName.substring(fileName.lastIndexOf('(') + 1, fileName.lastIndexOf(')')) : langCode;
    }

    function validateCharPerLine() {
      const num = parseInt($('#charsPerLine')[0].value);
      if (!isNaN(num)) {
        if (num < 20) {
          me.characterPerLine = 20;
        } else if (num > 70) {
          me.characterPerLine = 70;
        }
      } else {
        me.characterPerLine = 32;
      }
    }

    function setSoftwareApps() {
      const imgPath = '../../../img/';
      me.softwareApps = [{
        title: 'Microsoft Word',
        mobileTitle: 'Word',
        name: me.mainAppNames.word,
        fileType: 'doc',
        imgSrc: imgPath + 'word-icon.png',
        showTimecode: true
      }, {
        title: 'Translate',
        mobileTitle: 'Translate',
        name: me.mainAppNames.translate,
        imgSrc: imgPath + 'box-language.png',
        translateModal: true,
        disabled: !me.isSourceProjectLanguageSupported || me.resolve.project.status !== 'ready' && me.resolve.project.status !== 'subtitled',
        infoCondition: me.resolve.project.status === 'translated',
        infoTooltip: 'Please use the source project to translate.',
        infoConditionAlt: !me.isSourceProjectLanguageSupported && me.resolve.project.status === 'ready',
        infoTooltipAlt: me.sourceProjectLanguage.label + ' not supported yet.'
      }, {
        title: 'Adobe Premiere Pro',
        mobileTitle: 'Premiere',
        name: me.mainAppNames.adobePremiere,
        imgSrc: imgPath + 'premiere-logo.png',
        options: [{
          title: 'Markers',
          mobileTitle: 'Markers',
          name: 'markers',
          fileType: 'xml',
          showOnlyBookmarks: true
        }, {
          title: 'Captions/\nSubtitles',
          mobileTitle: 'Subtitles',
          name: 'srt',
          fileType: 'srt',
          gapless: true
        }],
      },
      {
        title: 'FCPX',
        mobileTitle: 'FCPX',
        name: me.mainAppNames.finalCutProX,
        imgSrc: imgPath + 'FCPX-icon.png',
        options: [{
          title: 'Captions',
          mobileTitle: 'Captions',
          name: 'captions',
          fileType: 'fcpXml'
        }, {
          title: 'Ranges',
          mobileTitle: 'Ranges',
          name: 'ranges',
          fileType: 'fcpXml'
        }, {
          title: 'Titles',
          mobileTitle: 'Titles',
          name: 'titles',
          fileType: 'fcpXml',
          gapless: true
        }]
      }, {
        title: 'Avid Media Composer',
        mobileTitle: 'Media Composer',
        name: me.mainAppNames.avidMediaComposer,
        imgSrc: imgPath + 'avid-media-logo.png',
        options: [{
          title: 'Markers/\n Locators\n(UTF-8)',
          mobileTitle: '(UTF-8)',
          name: 'avidLocatorUTF8',
          fileType: 'avidLocator',
          locatorType: 'avidLocatorUTF8',
          showOnlyBookmarks: true
        }, {
          title: `Markers/\n Locators\n (Localised, ${me.languageCode})`,
          mobileTitle: `(Localised, ${me.languageCode})`,
          name: 'avidLocatorMacOSRoman',
          fileType: 'avidLocator',
          locatorType: 'avidLocatorMacOSRoman',
          showOnlyBookmarks: true
        }, {
          title: 'ScriptSync',
          mobileTitle: 'ScriptSync',
          name: 'avidScriptSync',
          fileType: 'avidScriptSync',
          locatorType: 'avidScriptSync'
        }, {
          title: 'SubCap',
          mobileTitle: 'SubCap',
          name: 'subcap',
          fileType: 'stl',
          locatorType: 'stl'
        }, {
          title: 'AAF',
          mobileTitle: 'AAF',
          name: 'aaf',
          fileType: 'aaf',
          locatorType: 'aaf'
        }]
      },
      {
        title: 'Subtitles',
        mobileTitle: 'Subtitles',
        name: me.mainAppNames.subtitles,
        imgSrc: imgPath + 'box-cc.png',
        options: [{
          title: 'SRT',
          mobileTitle: 'SRT',
          name: 'srt',
          fileType: 'srt',
          gapless: true
        }, {
          title: 'Facebook/\nYouTube',
          mobileTitle: 'FB/\nYouTube',
          name: 'subRip',
          fileType: 'subRip',
          customField: 'fbYouTube'
        }, {
          title: 'WebVTT',
          mobileTitle: 'WebVTT',
          name: 'vtt',
          fileType: 'vtt',
          gapless: true
        }, {
          title: 'Avid SubCap',
          mobileTitle: 'Avid SubCap',
          name: 'stl-subcap',
          fileType: 'stl'
        }, {
          title: 'Visual Subtitle\n Editor',
          mobileTitle: 'Subtitle Editor',
          name: 'visual',
          fileType: 'visual',
          disabled: !(me.resolve.project.status === 'ready' || me.resolve.project.status === 'translated')
        }, {
          title: 'SAMI',
          mobileTitle: 'SAMI',
          name: 'sami',
          fileType: 'sami'
        }, {
          title: 'Burn-In',
          mobileTitle: 'Burn-In',
          name: 'burnInSubtitle',
          fileType: 'burnInSubtitle'
        }]
      }, {
        title: 'Visual Subtitle Editor',
        mobileTitle: 'Subtitle Editor',
        name: me.mainAppNames.visual,
        fileType: 'visual',
        imgSrc: imgPath + 'subtitle-logo.png',
        exportSubtitle: true,
        disabled: !(me.resolve.project.status === 'ready' || me.resolve.project.status === 'translated')
      }, {
        title: 'Burn-In',
        mobileTitle: 'Burn-In',
        name: me.mainAppNames.burnIn,
        imgSrc: imgPath + 'bitc-logo.png',
        paymentModal: true,
        disabled: (me.resolve.project.status !== 'ready' && me.resolve.project.status !== 'subtitled' && me.resolve.project.status !== 'translated') || !me.hasVideo,
        infoCondition: !me.hasVideo && (me.resolve.project.status === 'ready' || me.resolve.project.status === 'subtitled' || me.resolve.project.status === 'translated'),
        infoTooltip: 'There are no video in the project.',
        options: [{
          title: 'Subtitles',
          mobileTitle: 'Subtitles',
          name: 'burnInSubtitle',
          fileType: 'burnInSubtitle'
        }, {
          title: 'Timecode (BITC)',
          mobileTitle: '(BITC)',
          name: 'burnInTimecode',
          fileType: 'burnInTimecode'
        }]
      }, {
        title: 'Share Project',
        mobileTitle: 'Share',
        name: me.mainAppNames.share,
        imgSrc: imgPath + 'share-icon.png',
        shareModal: true,
        disabled: !isProjectOwner()
      }, {
        title: 'Other',
        mobileTitle: 'Other',
        name: me.mainAppNames.other,
        imgSrc: imgPath + 'other-logo.png',
        options: [{
          title: 'Avid Pro Tools AAF',
          mobileTitle: 'Avid Pro AAF',
          name: 'aaf',
          fileType: 'aaf',
          locatorType: 'aaf',
          new: true
        }, {
          title: 'DaVinci Resolve',
          mobileTitle: 'DaVinci Resolve',
          name: 'davinciResolve',
          fileType: 'edl',
          showOnlyBookmarks: true
        }, {
          title: 'Adobe Audition',
          mobileTitle: 'Adobe Audition',
          name: 'adobeAudition',
          fileType: 'xml',
          showOnlyBookmarks: true
        }, {
          title: 'Microsoft Excel',
          mobileTitle: 'Excel',
          name: 'excel',
          fileType: 'csv'
        }, {
          title: 'Json',
          mobileTitle: 'Json',
          name: 'json',
          fileType: 'json'
        }, {
          title: 'Xml',
          mobileTitle: 'Xml',
          name: 'Xml',
          fileType: 'xml',
          showOnlyBookmarks: false
        }, {
          title: 'Final Cut Pro 7',
          mobileTitle: 'FCP7',
          name: 'finalCutPro7',
          fileType: 'xml',
          showOnlyBookmarks: true
        }, {
          title: 'Text',
          mobileTitle: 'Text',
          name: 'txt',
          fileType: 'txt',
          showTimecode: true
        }, {
          title: 'Edius',
          mobileTitle: 'Edius',
          name: 'edius',
          fileType: 'edl',
          showOnlyBookmarks: false
        }, {
          title: 'Lumberjack Ready',
          mobileTitle: 'Lumberjack Ready',
          name: 'lumberjackText',
          fileType: 'lumberjackText'
        }, {
          title: 'MAXQDA',
          mobileTitle: 'MAXQDA',
          name: 'maxqda',
          fileType: 'maxqda',
          new: true
        }]
      }
      ];
    }

    function getSelectedSoftwareApp(name) {
      return _.find(me.softwareApps, {
        name: name || me.selectedSoftwareAppName
      });
    }

    function checkOptionSelected(options) {
      return options.filter(option => me.selectedOptionName === option.name).length === 0;
    }

    function getSelectedSoftwareAppOption(selectedSoftwareApp) {
      if (!me.selectedOptionName || checkOptionSelected(selectedSoftwareApp.options)) {
        me.selectedOptionName = selectedSoftwareApp.options[0].name;
      }
      return _.find(selectedSoftwareApp.options, {
        name: me.selectedOptionName
      });
    }

    function isOnlyBookmarkOrWithTimecode(type) {
      const selectedSoftwareApp = getSelectedSoftwareApp();
      let condition = false;
      if (selectedSoftwareApp) {
        if (selectedSoftwareApp.options) {
          const selectedSoftwareAppOption = getSelectedSoftwareAppOption(selectedSoftwareApp);
          condition = type === 'onlyBookmark' ? selectedSoftwareAppOption.showOnlyBookmarks : selectedSoftwareAppOption.showTimecode;
        } else {
          condition = type === 'onlyBookmark' ? selectedSoftwareApp.showOnlyBookmarks : selectedSoftwareApp.showTimecode
        }
      }
      return condition;
    }

    function isGapless() {
      const selectedSoftwareApp = getSelectedSoftwareApp();
      let condition = false;
      if (selectedSoftwareApp) {
        if (selectedSoftwareApp.options) {
          const selectedSoftwareAppOption = getSelectedSoftwareAppOption(selectedSoftwareApp);
          condition = selectedSoftwareAppOption.gapless ? true : false;
        } else {
          condition = selectedSoftwareApp.gapless ? true : false;
        }
      }
      return condition;
    }

    function getDefaultOptionByType(type) {
      const selectedSoftwareApp = getSelectedSoftwareApp(type);
      if (me.selectedSoftwareAppName === type) {
        const selectedSoftwareAppOption = getSelectedSoftwareAppOption(selectedSoftwareApp);
        return type === me.mainAppNames.avidMediaComposer ? selectedSoftwareAppOption.locatorType : selectedSoftwareAppOption.name;
      } else {
        return type === me.mainAppNames.avidMediaComposer ? selectedSoftwareApp.options[0].locatorType : selectedSoftwareApp.options[0].name;
      }
    }

    function ok() {
      const selectedSoftwareApp = getSelectedSoftwareApp();
      if (selectedSoftwareApp) {
        if (selectedSoftwareApp.paymentModal) {
          openPaymentModal();
        } else if (selectedSoftwareApp.translateModal) {
          openTranslateModal();
        } else if (selectedSoftwareApp.exportSubtitle) {
          exportSubtitle();
        } else if (selectedSoftwareApp.shareModal) {
          openShareModal();
        } else {
          const fileType = getCurrentSelectedTypeFile();
          if (fileType === me.mainAppNames.visual) {
            exportSubtitle();
          } else if (fileType === 'burnInSubtitle') {
            openPaymentModal();
          } else {
            let customField;
            let fileType = getCurrentSelectedTypeFile();
            if (fileType === 'subRip') {
              fileType = 'srt';
              customField = getSelectedSoftwareAppOption(selectedSoftwareApp).customField;
            }
            const options = {
              fileType: fileType,
              onlyBookmarks: me.onlyBookmarks,
              withTimeCode: me.withTimeCode,
              withGapless: me.withGapless,
              currentMediaId: me.currentMediaId,
              customField: customField ? customField : undefined,
              avidLocatorType: getDefaultOptionByType(me.mainAppNames.avidMediaComposer),
              jsonFileOptions: JSON.stringify({
                fcpXExportType: getDefaultOptionByType(me.mainAppNames.finalCutProX)
              })
            };
            exportTranscription(options, me.resolve.project.id);
          }
        }
      }
    }

    function cancel() {
      me.dismiss();
    }

    function getAvailableTranslationLanguages() {
      const sourceProjectLanguageCode = me.resolve.project.languageRegionCode.split(/-(.+)/)[0];
      const languages = storageService.get('languages');
      me.availableTranslateLanguages = languages.filter(language => language.translationCode && language.code !== sourceProjectLanguageCode);
      me.sourceProjectLanguage = languages.find(language => language.code === sourceProjectLanguageCode);
      me.isSourceProjectLanguageSupported = languages.find(language => language.translationCode && language.translationCode === me.sourceProjectLanguage.translationCode);
    }

    function getAvailableVideoInProjects() {
      if (me.resolve.project.files) {
        me.resolve.project.medias = angular.copy(me.resolve.project.files);
      }
      me.hasVideo = me.resolve.project.medias.find(media => media.type === 'video');
    }

    function isExportButtonEnabled() {
      return !!getCurrentSelectedTypeFile() || me.selectedSoftwareAppName === me.mainAppNames.translate ||
        me.selectedSoftwareAppName === me.mainAppNames.visual || me.selectedSoftwareAppName === me.mainAppNames.share ||
        me.selectedSoftwareAppName === me.mainAppNames.burnIn;
    }

    function getCurrentSelectedTypeFile() {
      const selectedSoftwareApp = getSelectedSoftwareApp();
      let fileType = '';
      if (selectedSoftwareApp) {
        fileType = selectedSoftwareApp.options ? getSelectedSoftwareAppOption(selectedSoftwareApp).fileType : selectedSoftwareApp.fileType;
      }
      return fileType;
    }

    function openPaymentModal() {
      if (me.currentMediaId) {
        const currentMedia = me.resolve.project.medias.find(media => media.id === me.currentMediaId);
        if (currentMedia.type !== 'video') {
          notificationService.warning('Current file is not a video. Please select another.');
          return;
        }
      }
      const burnInParams = getBurnInParams();
      const payload = {
        burnInType: getCurrentSelectedTypeFile(),
        currentMediaId: me.currentMediaId
      };
      mediaService.getProjectsPrice(me.resolve.project.id, burnInParams).then(price => {
        me.close();
        $uibModal.open({
          animation: true,
          component: 'burnInProjectModal',
          resolve: {
            price: () => price,
            projectId: () => me.resolve.project.id,
            planEnum: () => user.plan.planEnum,
            burnInParams: () => burnInParams,
            payload: () => payload
          }
        });
      });
    }

    function getBurnInParams() {
      const burnIn = {
        payType: 'BURNIN'
      };
      if (me.currentMediaId) {
        burnIn.currentMediaId = me.currentMediaId
      };
      return burnIn;
    }

    function isProjectTranscribed(project) {
      return !project.medias.some(media => media.status === 'transcribing');
    }

    function openTranslateModal() {
      if (isProjectTranscribed(me.resolve.project)) {
        me.close();
        $uibModal.open({
          animation: true,
          component: 'translateProjectModal',
          resolve: {
            projectId: () => me.resolve.project.id,
            availableTranslateLanguages: () => me.availableTranslateLanguages,
            user: user
          }
        });
      } else {
        notificationService.error('Please wait until all media files in this project are transcribed in order to translate this project.');
      }
    }

    function exportTranscription(payload, projectId) {
      me.isRequestInProcess = true;
      customAnalyticsService.trackEvent('Export', 'Click export button', null, null);
      projectService.export(payload, projectId).then(() => {
        showVisualSubtitleEditorOptionMessage();
        me.isRequestInProcess = false;
        me.close();
      }).catch(() => me.isRequestInProcess = false);
    }

    function exportSubtitle() {
      if (isProjectTranscribed(me.resolve.project)) {
        me.isRequestInProcess = true;
        const payload = {
          characterPerLine: parseInt(me.characterPerLine),
          withSingleLine: me.withSingleLine,
          withUppercase: me.withUppercase,
          withGapless: me.withGapless
        };
        projectService.exportSubtitle(payload, me.resolve.project.id)
          .then(() => {
            showVisualSubtitleEditorOptionMessage();
            me.close();
          }).catch(() => me.isRequestInProcess = false);
      } else {
        notificationService.error('Please wait until all media files in this project are transcribed in order to export this project to the Visual Subtitle Editor.');
      }
    }

    function showVisualSubtitleEditorOptionMessage() {
      const visualSubtitleEditorOptionMessage = `Your download has started. Next time consider first exporting to the
      <a href="https://help.simonsaysai.com/en/articles/3870087-visual-subtitle-editor" target="_blank" rel="noopener noreferrer">Visual Subtitle Editor</a> to have format and style control. 😎`;

      const selectedSoftwareApp = getSelectedSoftwareApp();
      if (selectedSoftwareApp.options) {
        const selectedOption = getSelectedSoftwareAppOption(selectedSoftwareApp);

        if (selectedSoftwareApp.name === me.mainAppNames.subtitles &&
          selectedOption.name !== 'visual' && me.resolve.project.status !== 'subtitled') {
          notificationService.warning(visualSubtitleEditorOptionMessage);
        }
      }
    }

    function isTeamMember() {
      return user && user.team && me.resolve.project.owner && me.resolve.project.owner.team ? user.team.id === me.resolve.project.owner.team.id : false;
    }

    function isProjectOwner() {
      return user && me.resolve.project && me.resolve.project.owner ? me.resolve.project.owner.id === user.id : false;
    }

    function openShareModal() {
      me.close();
      const modalInstance = $uibModal.open({
        animation: true,
        backdrop: 'static',
        component: 'shareProjectModal',
        resolve: {
          project: () => me.resolve.project,
          isProjectOwner: isProjectOwner,
          isTeamMember: isTeamMember
        }
      });

      modalInstance.result.then(response => {
        // In order to save the public description
        if (me.resolve.project.publicDescription !== response.publicDescription) {
          me.resolve.project.publicDescription = response.publicDescription;
          projectService.save(me.resolve.project);
        }
      });
    }

  }
})();
