(function () {
  'use strict';

  angular
    .module('ss.common.services')
    .factory('stateService', stateService);

  stateService.$inject = ['$timeout', '$state'];

  function stateService($timeout, $state) {
    const service = {
      goTo: goTo,
      reload: reload,
      getCurrentName: getCurrentName,
      href: href
    };
    return service;

    /////////////////

    function goTo(path, config = {}, reload = false) {
      $timeout(() => $state.go(path, config, {
        reload: reload
      }), 10);
    }

    function reload() {
      $timeout(() => $state.reload(), 20);
    }

    function getCurrentName() {
      return $state.current.name;
    }

    function href(path, config) {
      return $state.href(path, config);
    }

  }
})();
