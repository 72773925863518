(function () {
  'use strict';

  angular
    .module('ss.common.services')
    .factory('notificationService', notificationService);

  notificationService.$inject = ['$log', 'toastr', 'stringHelperService'];

  function notificationService($log, toastr, stringHelperService) {
    let notifications = [];
    const publicProperties = ['id', 'type', 'msg', 'metadata'];
    const metadata = {
      keepAliveAfterTransition: true
    };
    const service = {
      errorCatcher: errorCatcher,
      error: error,
      success: success,
      warning: warning,
      info: info,
      confirm: confirm,
      clearAll: clearAll,
      remove: remove,
      getAll: getAll,
      log: $log.log
    };
    return service;

    /////////////////////

    function errorCatcher(message, options) {
      return reason => error(message, reason, options);
    }

    function error(message, reason, options) {
      message = reason && reason.data && reason.data.message ? reason.data.message : message;
      $log.error('Error: ' + message, reason);
      return addNotification(constructMessage(toastr.error, message, options));
    }

    function success(message, options) {
      $log.info('Success: ' + message);
      return addNotification(constructMessage(toastr.success, message, options));
    }

    function warning(message, options) {
      $log.warn('Warning: ' + message);
      return addNotification(constructMessage(toastr.warning, message, options));
    }

    function info(message, options) {
      $log.info('Info: ' + message);
      return addNotification(constructMessage(toastr.info, message, options));
    }

    function constructMessage(toastrMethod, message, options) {
      return {
        toastrMethod: toastrMethod,
        message: message,
        metadata: typeof options === 'boolean' && options ? metadata : null,
        options: typeof options === 'number' ? { timeOut: options } : {}
      }
    }

    function confirm(message) {
      const htmlMessage = `
        ${message} <br><br>
        <div class='text-center'>
          <div class='row confirm-message'>
            <button type='button' id='confirmNo' class='btn clear'> No </button>
            <button type='button' id='confirmYes' class='btn clear'> Yes </button>
          </div>
        </div>`;

      return new Promise((resolve, reject) => {
        toastr.warning(htmlMessage, {
          onShown() {
            $('#confirmYes').click(() => {
              toastr.clear();
              resolve();
            });

            $('#confirmNo').click(() => {
              toastr.clear();
              reject();
            });
          }
        })
      });
    }

    function addNotification(messageConfig) {
      if (messageConfig.message !== '') {
        const newNotification = {
          id: stringHelperService.generateRandomString(10),
          type: messageConfig.toastrMethod.name,
          msg: messageConfig.message,
          metadata: messageConfig.metadata,
          toast: messageConfig.toastrMethod(messageConfig.message, messageConfig.options)
        };
        notifications.push(newNotification);

        return _.pick(newNotification, publicProperties);
      }
    }

    function remove(id) {
      const notificationToRemove = notifications.find(notification => notification.id === id);
      if (notificationToRemove) {
        if (notificationToRemove.toast) {
          toastr.clear(notificationToRemove.toast)
        }
        _.remove(notifications, notification => notificationToRemove.id === notification.id);
      }
    }

    function clearAll() {
      toastr.clear();
      notifications = [];
    }

    function getAll() {
      return notifications.map(notification => _.pick(notification, publicProperties));
    }

  }
}());
