(function () {
  'use strict';

  angular
    .module('ss.admin.services')
    .factory('metricsService', metricsService);

  metricsService.$inject = ['dataService', 'stateService'];

  function metricsService(dataService, stateService) {
    const endPoint = 'metrics';
    const service = {
      query: query
    };
    return service;

    ////////////////////

    function query(queryParams) {
      const path = dataService.httpParamSerializer(queryParams, '?');
      return dataService.get(endPoint, path).then(response => {
        if (!response) {
          stateService.goTo('app.dashboard');
        }
        return response;
      }).catch(error => {
        dataService.logException(error);
        stateService.goTo('app.dashboard');
      });
    }

  }
})();
