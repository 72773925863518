(function () {
  'use strict';

  angular
    .module('ss.client.pages', ['ngFileUpload'])
    .config(clientPagesConfig);

  clientPagesConfig.$inject = ['$stateProvider', 'APP_CONFIG'];

  function clientPagesConfig($stateProvider, APP_CONFIG) {
    $stateProvider
      .state('LandingPageComponent', {
        url: '/',
        component: 'landingPageComponent'
      })
      .state('AuthCallbackPageComponent', {
        url: '/callback',
        component: 'authCallbackPageComponent'
      })
      .state('signinRedirect', {
        url: '/signin',
        component: 'authRedirectPageComponent'
      })
      .state('signupRedirect', {
        url: '/signup',
        component: 'authRedirectPageComponent'
      })
      .state('app', {
        url: APP_CONFIG.ROUTE_PREFIX,
        abstract: true,
        component: 'appPageComponent',
        resolve: {
          user: usersService => usersService.getUserInfo()
        }
      })
      .state('app.dashboard', {
        url: '',
        component: 'dashboardPageComponent',
        params: {
          activeTab: 'my-projects',
          directory: null
        }
      })
      .state('app.noFound', {
        url: '/no-found',
        component: 'noFoundOops'
      })
      .state('app.accountSettings', {
        url: '/account-settings',
        reloadOnSearch: false,
        component: 'accountSettingsPageComponent'
      })
      .state('app.project', {
        url: '/projects/:projectId',
        component: 'projectDetailsPageComponent',
        resolve: {
          project: ($stateParams, projectService, storageService) => projectService.get($stateParams.projectId).then(response => setLanguageCode(response, storageService)),
          subState: (sharedService, project) => sharedService.redirectToProject(project)
        }
      })
      .state('app.projectUpload', {
        url: '/projects/upload/:projectId',
        component: 'projectOpenPageComponent',
        resolve: {
          project: ($stateParams, projectService, storageService) => projectService.get($stateParams.projectId).then(response => setLanguageCode(response, storageService)),
          state: () => 'app.projectUpload',
          subState: (sharedService, project) => sharedService.checkIfTranscribed(project)
        }
      })
      .state('app.projectTranscript', {
        url: '/projects/transcript/:projectId?fileId',
        reloadOnSearch: false,
        component: 'projectTranscriptsPageComponent',
        resolve: {
          project: ($stateParams, projectService, storageService) => projectService.get($stateParams.projectId).then(response => setLanguageCode(response, storageService)),
          state: () => 'app.projectTranscript'
        }
      });
  }

  function setLanguageCode(response, storageService) {
    storageService.set('languageRegionCode', response.languageRegionCode);
    return response;
  }

})();
