(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .factory('customAnalyticsService', customAnalyticsService);

  customAnalyticsService.$inject = ['$filter', 'notificationService'];

  function customAnalyticsService($filter, notificationService) {
    const service = {
      trackEvent: trackEvent,
      setUserId: setUserId,
      trackRegistrationEvent: trackRegistrationEvent,
      trackAmplitudeEvent: trackAmplitudeEvent,
      setAmplitudeUser: setAmplitudeUser,
      clearAmplitudeUser: clearAmplitudeUser
    };
    return service;

    ////////////////////////

    function trackEvent(eventCategory, eventAction, eventLabel, fieldsObject, eventValue) {
      const event = {
        eventCategory: eventCategory || 'default',
        eventAction: eventAction,
        eventLabel: eventLabel || 'default',
        fieldsObject: fieldsObject
      };
      if (eventValue) {
        event.eventValue = eventValue;
      }
      notificationService.log($filter('json')(event));
      ga('send', 'event', event);
    }

    function setUserId(userId) {
      notificationService.log('Set user for GA', userId);
      ga('set', 'userId', userId);
    }

    function trackRegistrationEvent(eventLabel) {
      const event = {
        eventCategory: 'Acquisition',
        eventAction: 'account created',
        eventLabel
      };
      notificationService.log($filter('json')(event));
      ga('send', 'event', event);
    }

    function trackAmplitudeEvent(user) {
      const event = {
        account_type: user.connectionStrategy,
        user_id: user.id,
        plan_name: user.plan.label,
        plan_term: user.plan.interval ? user.plan.interval : 'none',
        team: user.team ? user.team : 'none',
        email: user.email,
        company: user.email && user.email.includes('@')
          ? user.email.split('@')[1]
          : null,
      };

      amplitude.getInstance().logEvent('Sign In', event);
      var identify = new amplitude.Identify();
      identify.add("auth["+user.connectionStrategy+"]", 1);
      amplitude.identify(identify);
    }

    function setAmplitudeUser(user) {
      amplitude.getInstance().setUserId(user.id);
      var identify = new amplitude.Identify();
      identify.set("email", user.email);
      identify.set("user_id", user.id);
      identify.set("current timezone", new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]);
      identify.setOnce("initial product type", "web");
      identify.set("product[web]", true);
      identify.set("company", user.email && user.email.includes('@')
          ? user.email.split('@')[1]
          : null)
      identify.set(
        "plan_term",
        user.plan.interval ? user.plan.interval : "none");
      identify.set("plan_name", user.plan.label);
      identify.set("full_name", user.fullName);
      identify.set("team", user.team ? user.team : "none");
      amplitude.identify(identify);
    }

    function clearAmplitudeUser() {
      amplitude.getInstance().setUserId(null); 
      amplitude.getInstance().regenerateDeviceId();

    }

  }
})();
