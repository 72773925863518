(function () {
  'use strict';
  angular
    .module('ss.client.pages')
    .component('projectDetailsPageComponent', {
      controller: function () {},
      bindings: {
        project: '<'
      },
      templateUrl: 'app/client/pages/project-details-page/project-details-page.component.html'
    });
}());
