(function () {
  'use strict';
  angular
    .module('ss.client.components')
    .component('projectStructure', {
      controller: ProjectStructureComponent,
      bindings: {
        list: '=',
        action: '@',
        projects: '=?',
        directories: '=?',
        permissionOfProjectMap: '<'
      },
      templateUrl: 'app/client/components/project-structure/project-structure.component.html'
    });

  ProjectStructureComponent.$inject = ['sharedProjectsService', 'notificationService', 'projectService'];

  function ProjectStructureComponent(sharedProjectsService, notificationService, projectService) {
    const me = this;
    me.showConfirmDelete = showConfirmDelete;
    me.removeShareWithMe = removeShareWithMe;
    me.projectImageBackground = projectImageBackground;
    me.showPermissionTitle = showPermissionTitle;
    me.goToTranscript = goToTranscript;

    function projectImageBackground(projectImage) {
      const img = projectImage ? projectImage : 'img/poster-video.png';
      return {
        'background-image': `url(${img})`
      }
    }

    function removeShareWithMe(projectId) {
      notificationService.confirm('Are you sure you want to remove this project?').then(() =>
        sharedProjectsService.removeShareWithMe(projectId).then(() => {
          const sharedProjectIndex = _.findIndex(me.list, {
            id: projectId
          });
          if (sharedProjectIndex !== -1) {
            me.list.splice(sharedProjectIndex, 1);
          }
        })).catch(() => { });
    }

    function showConfirmDelete(projectId) {
      notificationService.confirm('Are you sure you want to delete this project?')
        .then(() => deleteProject(projectId))
        .catch(() => { });
    }

    function deleteProject(projectId) {
      projectService.removeFromDashboard(projectId, me.projects, me.list, me.directories);
    }

    function showPermissionTitle(projectId) {
      const sp = _.find(me.permissionOfProjectMap, {
        id: projectId
      })
      return sp ? sp.title : 'View';
    }

    function goToTranscript(mediaId) {
      sharedService.redirectToProject(me.project, mediaId);
    }

  }
})();
