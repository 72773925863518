(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .factory('boxAuthenticationService', boxAuthenticationService);

  boxAuthenticationService.$inject = ['dataService', 'notificationService', 'stateService'];

  function boxAuthenticationService(dataService, notificationService, stateService) {
    const endPoint = 'box-auth/oauth2/';
    const service = {
      authorize: authorize,
      deAuthorize: deAuthorize,
      complete: complete,
      getAuthToken: getAuthToken
    };
    return service;

    ////////////////////////

    function authorize() {
      return dataService.get(endPoint);
    }

    function deAuthorize() {
      return dataService.get(endPoint, 'deauthorize').then(() => notificationService.success('Your Box account is disconnected', true));
    }

    function complete(accesstoken, refreshtoken) {
      const queryParams = dataService.httpParamSerializer({
        accesstoken: accesstoken,
        refreshtoken: refreshtoken
      }, '?');
      const path = 'complete' + queryParams;

      return dataService.get(endPoint, path).then(() => {
        notificationService.success('Your Box account is connected successfully', true);
        stateService.goTo('app.accountSettings');
      });
    }

    function getAuthToken() {
      return dataService.get(endPoint, 'token').catch(error => dataService.logException(error));
    }

  }
})();
