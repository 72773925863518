(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('latestTransactionPageComponent', {
      controller: LatestTransactionPageComponent,
      bindings: {},
      templateUrl: 'app/admin/pages/latest-transaction-page/latest-transaction-page.component.html'
    })

  LatestTransactionPageComponent.$inject = ['stateService', '$window', 'adminUserService', 'projectAdminService'];

  function LatestTransactionPageComponent(stateService, $window, adminUserService, projectAdminService) {
    const me = this;

    me.$onInit = onInit;
    me.autoCompleteOptions = autoCompleteOptions;
    me.getName = getName;
    me.transactionHistory = transactionHistory;
    me.loadMoreData = loadMoreData;
    me.getDetailedTransactionData = getDetailedTransactionData;
    me.selectedUser = null;
    me.searchText = null;
    me.hideMoreDiv = true;
    me.transactions = [];
    me.pageSize = 50;
    me.filterDate = null;
    me.filterEndDate = null;
    let usersListing = [];
    let counter = 0;
    me.dateOptions = {
      showWeeks: false
    };
    me.dateStartOpen = { opened: false };
    me.dateFinishOpen = { opened: false };

    function onInit() {
      getFilteredTransaction();
      adminUserService.getAll().then(response => usersListing = response);
    }

    function autoCompleteOptions(searchText) {
      return searchText ? usersListing.filter(item => {
        const lowercaseQuery = toLowercase(searchText);
        return toLowercase(item.fullName).indexOf(lowercaseQuery) === 0 || toLowercase(item.email).indexOf(lowercaseQuery) === 0;
      }) : usersListing;
    }

    function toLowercase(text) {
      return text ? angular.lowercase(text) : '';
    }

    function getName(item) {
      return `${item.fullName} (${item.email})`;
    }

    function transactionHistory() {
      onInit();
      getFilteredTransaction();
    }

    function getFilteredTransaction() {
      let day = null;
      let day_ = null;
      let userId = null;
      if (me.filterDate !== null) {
        const date = new Date(me.filterDate);
        day = date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate();
      }
      if (me.filterEndDate !== null) {
        const date = new Date(me.filterEndDate);
        date.setDate(date.getDate() + 1);
        day_ = date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate();
      }
      if (me.selectedUser) {
        userId = me.selectedUser.id;
      }
      const payload = {
        userIdSearch: userId,
        fromDateSearch: day,
        toDateSearch: day_,
        pageNumber: me.pageNumber,
        pageSize: me.pageSize,
        enablePagination: true
      };

      projectAdminService.getProjects(payload).then(response => {
        const totalLength = response.countOfCall;
        me.transactions = response.projects;
        if (me.transactions.length > 0) {
          me.hideMoreDiv = false;
          counter = counter + 1;
        }
        const countShowHideLoadMore = me.pageSize * counter;
        if (countShowHideLoadMore >= totalLength) {
          me.hideMoreDiv = true;
        }
      });
    }

    function loadMoreData() {
      me.pageNumber = counter;
      getFilteredTransaction();
    }

    function getDetailedTransactionData(projectId) {
      if (projectId) {
        const url = stateService.href('app.admin.detailedTransaction', {
          projectId: projectId
        });
        $window.open(url, '_blank', 'noopener noreferrer');
      }
    }

  }
}());
