(function () {
  'use strict';

  angular
    .module('ss.client.filters')
    .filter('priceCentPerMinute', priceCentPerMinute);

  priceCentPerMinute.$inject = ['$filter'];

  function priceCentPerMinute($filter) {
    return pricePerHour => parseFloat($filter('number')(pricePerHour * 100 / 60, 1)) + '¢/minute';
  }

})();
