(function () {
  'use strict';

  angular
    .module('ss.common.services')
    .factory('plansService', plansService);

  plansService.$inject = ['dataService', 'notificationService'];

  function plansService(dataService, notificationService) {
    const endPoint = 'plans';
    const service = {
      getAll: getAll,
      updatePlan: updatePlan
    };
    return service;

    ////////////////

    function getAll() {
      return dataService.get(endPoint);
    }

    function updatePlan(id, payload) {
      return dataService.put(endPoint, payload, '/' + id)
        .then(() => notificationService.success('Plan has updated.'))
        .catch(reason => dataService.throwException(reason, 'Error updating plan info.'));
    }

  }
})();
