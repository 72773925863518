(function () {
  'use strict';

  angular
    .module('app')
    .run(appRun);

  appRun.$inject = ['$transitions', '$window', '$document', 'authService', 'mediaService', 'notificationService', '$stateParams', '$q'];

  function appRun($transitions, $window, $document, authService, mediaService, notificationService, $stateParams, $q) {

    function checkAndRenewToken() {
      if (authService.isAuthenticated()) {
        authService.renewTokens();
      } else {
        // Handle the authentication
        const query = $window.location.search.substring(1);
        if (!query.includes('callback') && query.includes('sharing')) {
          $window.location.replace('/signin');
        }
        // result in the hash
        authService.handleAuthentication();
      }
    }

    checkAndRenewToken();
    setInterval(checkAndRenewToken, 60 * 60 * 1000);

    $transitions.onStart({}, (transition) => {
      if (transition.from().name === 'app.projectUpload') {
        const d = $q.defer();
        mediaService.leaveProjectUpload($stateParams.projectId).then(() => d.resolve()).catch(() => d.reject());
        return d.promise;
      }
    });

    $transitions.onSuccess({}, () => {
      $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
      $window.Intercom('update');

      notificationService.getAll().forEach(notification => {
        if (!notification.metadata || !notification.metadata.keepAliveAfterTransition) {
          notificationService.remove(notification.id);
        }
      })
    });

    $transitions.onError({}, transition => {
      const err = transition.error();
      switch (err.type) {
        case 3: // ABORTED
        case 4: // INVALID
        case 6: // ERROR
          notificationService.log(transition.error());
          break;
        case 2: // SUPERSEDED:
        case 5: // IGNORED:
          // ignore
          break;
      }
    });

    mediaService.startListeningUploadEvents();

  }
})();
