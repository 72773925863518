(function () {
    'use strict';

    // workaround to mitigate slow ui-select with long options list
    // Source: https://github.com/angular-ui/ui-select/issues/389#issuecomment-416378819
    angular
        .module('ss.client.directives')
        .directive('uiSelectNoAnimate', uiSelectNoAnimate);

    function uiSelectNoAnimate() {
        return {
            restrict: 'A',
            require: 'uiSelect',
            link: (scope, element, attrs, $select) => {
                $select.$animate = null;
            }
        }
    }
})();
