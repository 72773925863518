(function () {
  'use strict';

  angular
    .module('ss.client.pages')
    .component('authRedirectPageComponent', {
      controller: AuthRedirectPageComponent,
      bindings: {},
      templateUrl: 'app/client/pages/auth-redirect-page/auth-redirect-page.component.html'
    });

  AuthRedirectPageComponent.$inject = ['authService'];

  function AuthRedirectPageComponent(authService) {
    authService.login();
  }

})();
