(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('translateProjectModal', {
      controller: TranslateProjectModalComponent,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/translate-project-modal/translate-project-modal.component.html'
    });

  TranslateProjectModalComponent.$inject = ['translationService'];

  function TranslateProjectModalComponent(translationService) {
    const me = this;
    let isRequestProcessing = false;

    me.$onInit = onInit;
    me.translate = translate;
    me.getProjectTranslationPrice = getProjectTranslationPrice;
    me.isSubmitButtonDisabled = isSubmitButtonDisabled;
    me.checkAllLanguages = checkAllLanguages;
    me.uncheckAllLanguages = uncheckAllLanguages;
    me.groupByFavorite = groupByFavorite;
    me.cancel = cancel;

    function onInit() {
      getProjectTranslationPrice();
      me.resolve.availableTranslateLanguages = buildLanguageRegionsToDisplay(me.resolve.availableTranslateLanguages);
    }

    function buildLanguageRegionsToDisplay(languages) {
      let languagesToDisplay = [];
      let lastUsedJson = null;
      if(me.resolve.user.lastUsedTranslationLanguage) {
        lastUsedJson =  JSON.parse(me.resolve.user.lastUsedTranslationLanguage);
     }
      languages.forEach(language =>
          languagesToDisplay.push({
            id: language.id,
            label: language.label,
            isLastUsed: lastUsedJson && lastUsedJson.indexOf(language.id) >= 0 ? true : false
          }));

      languagesToDisplay = _.orderBy(languagesToDisplay, ['label'])
      const lastUsed = _.filter(languagesToDisplay, {
        isLastUsed: true
      });
      const others = _.filter(languagesToDisplay, {
        isLastUsed: false
      });
      return _.concat(lastUsed, others);
    }

    function getProjectTranslationPrice() {
      translationService.getProjectPrice(me.resolve.projectId, getSelectedLanguages().length).then(price => me.priceDetails = price);
    }

    function translate(useSavedCard, card, shouldSaveCard) {
      isRequestProcessing = true;
      const payload = {
        paymentDetails: {
          useSavedCard: useSavedCard,
          card: card,
          shouldSaveCard: shouldSaveCard
        },
        targetLanguageIds: getSelectedLanguages().map(language => language.id)
      };
      translationService.translateProject(me.resolve.projectId, payload).then(() => me.close()).catch(() => isRequestProcessing = false);
    }

    function groupByFavorite(languageToDisplayItem) {
      return languageToDisplayItem.isLastUsed ? 'Last Used' : 'Others';
    }

    function isSubmitButtonDisabled() {
      return isRequestProcessing || !getSelectedLanguages().length;
    }

    function getSelectedLanguages() {
      return me.selectedLanguages || [];
    }

    function checkAllLanguages() {
      me.selectedLanguages = [].concat(me.resolve.availableTranslateLanguages);
      getProjectTranslationPrice();
    }

    function uncheckAllLanguages() {
      me.selectedLanguages = [];
      getProjectTranslationPrice();
    }

    function cancel() {
      me.dismiss();
    }

  }
})();
