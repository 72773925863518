(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('burnInProjectModal', {
      controller: BurnInProjectModalComponent,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/burn-in-project-modal/burn-in-project-modal.component.html'
    });

  BurnInProjectModalComponent.$inject = ['mediaService', 'customEcommerceAnalytics', 'projectService', 'customAnalyticsService', 'notificationService'];

  function BurnInProjectModalComponent(mediaService, customEcommerceAnalytics, projectService, customAnalyticsService, notificationService) {
    const me = this;

    me.$onInit = onInit;
    me.burnIn = burnIn;
    me.cancel = cancel;

    function onInit() {
      me.price = me.resolve.price;
      me.projectId = me.resolve.projectId;
    }

    function burnIn(useSavedCard, card, shouldSaveCard) {
      customAnalyticsService.trackEvent('BurnIn', 'Click pay', null, null);
      me.disabledSubmitButton = true;
      const params = {
        useSavedCard: useSavedCard,
        card: card,
        shouldSaveCard: shouldSaveCard
      };
      if (me.price.price && !useSavedCard) {
        customAnalyticsService.trackEvent('BurnIn', 'Starting payment - data verified', null, null);
        params.withQueryParams = true;
        params.transcribeError = 'BurnIn';
        burnInAction(params);
      } else {
        params.withQueryParams = false;
        params.transcribeError = 'Transcribe';
        burnInAction(params);
      }
    }

    function burnInAction(params) {
      mediaService.checkoutTranscribe(me.projectId, params.useSavedCard, params.shouldSaveCard, params.card, params.withQueryParams, me.resolve.burnInParams).then(response => {
        if (response.response === 'OK') {
          me.close();
          try {
            customEcommerceAnalytics.clearTrans();
            if (!params.withQueryParams) {
              customEcommerceAnalytics.addTransaction(me.projectId, me.resolve.planEnum, me.price, '0', '0');
            }
            if (me.price.credit === 0) {
              customEcommerceAnalytics.addItem(me.projectId, me.resolve.planEnum, me.price.price / me.price.totalTimeInMinutes, me.price.totalTimeInMinutes, '-TX');
            } else {
              const quantityToPass = getQuantityToPass();
              customEcommerceAnalytics.addItem(me.projectId, me.resolve.planEnum, 0, quantityToPass, '-SUB');
              if (me.price.chargeableTimeInMinutes !== null && me.price.chargeableTimeInMinutes > 0) {
                customEcommerceAnalytics.addItem(me.projectId, me.resolve.planEnum, me.price.price / me.price.chargeableTimeInMinutes, me.price.chargeableTimeInMinutes, '-TX');
              }
            }
            customEcommerceAnalytics.trackTrans();
          } catch (error) {
            notificationService.error(error);
          }
          customAnalyticsService.trackEvent('BurnIn', 'Payment success', null, null);
          me.resolve.payload.eventLogId = response.eventLogId;
          projectService.burnSubtitle(me.projectId, me.resolve.payload).catch(() => me.disabledSubmitButton = false);
        } else {
          transcribeError(params.transcribeError, response);
        }
      }).catch(reason => transcribeError(params.transcribeError, reason, '2'));
    }

    function transcribeError(type, reason, errorNumber) {
      customAnalyticsService.trackEvent(type, 'Payment error' + errorNumber, null, reason);
      me.close();
    }

    function getQuantityToPass() {
      let quantityToPass = '';
      if (me.price.totalTimeInMinutes > me.price.credit) {
        quantityToPass = me.price.credit;
      }
      if (me.price.totalTimeInMinutes === me.price.credit || me.price.totalTimeInMinutes < me.price.credit) {
        quantityToPass = me.price.totalTimeInMinutes;
      }
      return quantityToPass;
    }

    function cancel() {
      me.dismiss();
    }

  }
})();
