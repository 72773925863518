(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('defaultConfigPageComponent', {
      bindings: {},
      controller: DefaultConfigPageComponent,
      templateUrl: 'app/admin/pages/default-config-page/default-config-page.component.html'
    });

  DefaultConfigPageComponent.$inject = ['defaultConfigService', 'plansService', 'notificationService'];

  function DefaultConfigPageComponent(defaultConfigService, plansService, notificationService) {
    const me = this;
    let backupFormData;

    me.$onInit = onInit;
    me.updateConfig = updateConfig;
    me.cancelUpdate = cancelUpdate;
    me.updatePlanConfig = updatePlanConfig;
    me.cancelPlanUpdate = cancelPlanUpdate;
    me.dropdownSelected = dropdownSelected;
    me.planSelectedIndex = 0;
    me.subscriptionCredit = 0;

    function onInit() {
      defaultConfigService.getDefaultConfig().then(response => {
        me.defaultConfig = response;
        makeBackupData();
      });
      plansService.getAll().then(response => {
        me.plans = response;
        dropdownSelected(0);
      });
    }

    function updatePlanConfig() {
      if (me.subscriptionCredit >= 0) {
        const id = me.plans[me.planSelectedIndex].id;
        plansService.updatePlan(id, {
          subscriptionCredit: me.subscriptionCredit
        }).then(() => {
          me.plans[me.planSelectedIndex].creditInMinutes = me.subscriptionCredit;
          me.planConfigForm.$setPristine();
        }).catch(() => cancelPlanUpdate());
      } else {
        notificationService.error('Subscription credit number is invalid.');
      }
    }

    function cancelPlanUpdate() {
      me.subscriptionCredit = me.plans[me.planSelectedIndex].creditInMinutes;
      me.planConfigForm.$setPristine();
    }

    function dropdownSelected(index) {
      me.planSelectedIndex = index;
      me.subscriptionCredit = me.plans[me.planSelectedIndex].creditInMinutes;
      me.planConfigForm.$setPristine();
    }

    function updateConfig() {
      defaultConfigService.updateDefaultConfig(me.defaultConfig).then(response => {
        me.defaultConfig = response;
        makeBackupData();
        me.defaultConfigForm.$setPristine();
      });
    }

    function cancelUpdate() {
      me.defaultConfigForm.$setPristine();
      me.defaultConfig = Object.assign({}, backupFormData);
    }

    function makeBackupData() {
      backupFormData = Object.assign({}, me.defaultConfig);
    }

  }
})();
