(function (global) {
  'use strict';

  function queryStringFn() {
    // This function is anonymous, is executed immediately and
    // the return value is assigned to QueryString!
    const query_string = {};
    const query = global.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      // If first entry with this name
      if (typeof query_string[pair[0]] === 'undefined') {
        query_string[pair[0]] = decodeURIComponent(pair[1]);
        // If second entry with this name
      } else if (typeof query_string[pair[0]] === 'string') {
        const arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
        query_string[pair[0]] = arr;
        // If third or later entry with this name
      } else {
        query_string[pair[0]].push(decodeURIComponent(pair[1]));
      }
    }
    return query_string;
  };

  const queryString = queryStringFn();
  if (queryString.inviteCode) {
    console.log('Invite Code: ' + queryString.inviteCode);
    document.cookie = 'inviteCode=' + queryString.inviteCode + ';path=/';
  }
  if (queryString.sharing) {
    console.log('Sharing project: ' + queryString.sharing);
    document.cookie = 'sharing=' + queryString.sharing + ';path=/';
  }

})(window);
