(function () {
  'use strict';

  const audio = 'audio';
  const video = 'video';
  const image = 'image';

  const PROJECT_STATUS = {
    OPEN: 'open',
    UPLOADING: 'uploading',
    TRANSCRIBING: 'transcribing',
    READY: 'ready',
    TRANSLATING: 'translating',
    TRANSLATED: 'translated',
    SUBTITLING: 'subtitling',
    SUBTITLED: 'subtitled',
    TRANSCRIBED: 'transcribed',
    EMPTY: 'empty'
  };

  const MEDIA_THUMBNAILS_IMAGES = {
    audio: 'img/poster-audio.png',
    video: 'img/poster-video.png'
  };

  const PLANS = {
    NO_SUBSCRIPTION_PLAN: 'PAY_AS_YOU_GO',
    INTERVAL_TYPES: {
      MONTH: 'month',
      YEAR: 'year'
    },
    PEOPLE_TYPES: {
      INDIVIDUAL: 'INDIVIDUAL',
      COMPANY: 'COMPANY',
    },
  };

  const SPEECH_API_SPEED = {
    '1': 1,
    '2': 1,
    '3': 1,
    '4': 0.5
  };

  const SUPPORTED_IMAGE_FILES = {
    ico: {
      type: image,
      mimeType: image + '/x-icon'
    },
    jpeg: {
      type: image,
      mimeType: image + '/jpeg'
    },
    jpg: {
      type: image,
      mimeType: image + '/jpeg'
    },
    png: {
      type: image,
      mimeType: image + '/png'
    }
  };

  const SUPPORTED_MEDIA_FILES = {
    '3g2': {
      type: video,
      mimeType: video + '/3gpp2'
    },
    aac: {
      type: audio,
      mimeType: audio + '/aac'
    },
    aif: {
      type: audio,
      mimeType: audio + '/aiff'
    },
    aiff: {
      type: audio,
      mimeType: audio + '/aiff'
    },
    asf: {
      type: video,
      mimeType: video + '/x-ms-asf'
    },
    avchd: {
      type: video
    },
    avi: {
      type: video,
      mimeType: video + '/x-msvideo'
    },
    iff: {
      type: audio
    },
    flac: {
      type: audio,
      mimeType: audio + '/flac'
    },
    flv: {
      type: video,
      mimeType: video + '/x-flv'
    },
    m3u: {
      type: audio,
      mimeType: audio + '/x-mpegurl'
    },
    m4a: {
      type: audio,
      mimeType: audio + '/mp4'
    },
    m4v: {
      type: video,
      mimeType: audio + '/mp4'
    },
    mxf: {
      type: video,
      mimeType: 'application/mxf'
    },
    mid: {
      type: audio,
      mimeType: audio + '/midi'
    },
    mkv: {
      type: video,
      mimeType: video + '/x-matroska'
    },
    mov: {
      type: video,
      mimeType: video + '/quicktime'
    },
    mp2: {
      type: audio,
      mimeType: audio + '/mpeg'
    },
    mp3: {
      type: audio,
      mimeType: audio + '/mp3'
    },
    mp4: {
      type: video,
      mimeType: video + '/mp4'
    },
    mpa: {
      type: audio,
      mimeType: audio + '/mpeg'
    },
    mpeg: {
      type: video,
      mimeType: video + '/mpeg'
    },
    mpg: {
      type: video,
      mimeType: video + '/mpeg'
    },
    mts: {
      type: video,
      mimeType: video + '/MP2T'
    },
    ogg: {
      type: video,
      mimeType: audio + '/ogg'
    },
    opus: {
      type: audio,
      mimeType: audio + '/ogg'
    },
    rm: {
      type: video
    },
    srt: {
      type: video
    },
    swf: {
      type: video,
      mimeType: 'application/x-shockwave-flash'
    },
    vob: {
      type: video
    },
    wav: {
      type: audio,
      mimeType: audio + '/wav'
    },
    wma: {
      type: audio,
      mimeType: audio + '/x-ms-wma'
    },
    wmv: {
      type: video,
      mimeType: video + '/x-ms-wmv'
    }
  };

  const MIME_TYPES = Object.keys(SUPPORTED_MEDIA_FILES).reduce((mimeTypes, SUPPORTED_MEDIA_FILE_KEY) => {
    const SUPPORTED_MEDIA_FILE = SUPPORTED_MEDIA_FILES[SUPPORTED_MEDIA_FILE_KEY];
    if (SUPPORTED_MEDIA_FILE.mimeType && !mimeTypes.includes(SUPPORTED_MEDIA_FILE.mimeType)) {
      mimeTypes.push(SUPPORTED_MEDIA_FILE.mimeType);
    }
    return mimeTypes;
  }, []);

  const SUPPORTED_CHECK_DURATION = {
    '3g2': {
      type: video,
      mimeType: video + '/3gpp2'
    },
    aac: {
      type: audio,
      mimeType: audio + '/aac'
    },
    mp4: {
      type: video,
      mimeType: video + '/mp4'
    },
    mp3: {
      type: audio,
      mimeType: audio + '/mp3'
    },
    mkv: {
      type: video,
      mimeType: video + '/x-matroska'
    },
    m4a: {
      type: audio,
      mimeType: audio + '/mp4'
    },
    mov: {
      type: video,
      mimeType: video + '/quicktime'
    },
    wav: {
      type: audio,
      mimeType: audio + '/wav'
    },
    ogg: {
      type: video,
      mimeType: audio + '/ogg'
    },
  }

  const CUSTOM_DICTIONARIES = {
    starter: { allowedQuantity: 1, wordsPerDictionary: 4 },
    pro: { allowedQuantity: 10, wordsPerDictionary: 40 }
  }

  const DICTIONARIES_LIMITS_INFO_LINK = 'http://help.simonsaysai.com/en/articles/4535020-custom-dictionaries-limits-per-plan';
  //////////////////////////////

  const SS_CONSTANT = {
    PROJECT_STATUS: PROJECT_STATUS,
    MEDIA_THUMBNAILS_IMAGES: MEDIA_THUMBNAILS_IMAGES,
    PLANS: PLANS,
    SPEECH_API_SPEED: SPEECH_API_SPEED,
    SUPPORTED_MEDIA_FILES: SUPPORTED_MEDIA_FILES,
    SUPPORTED_IMAGE_FILES: SUPPORTED_IMAGE_FILES,
    MIME_TYPES: MIME_TYPES,
    SUPPORTED_CHECK_DURATION: SUPPORTED_CHECK_DURATION,
    CUSTOM_DICTIONARIES: CUSTOM_DICTIONARIES,
    DICTIONARIES_LIMITS_INFO_LINK: DICTIONARIES_LIMITS_INFO_LINK
  };

  angular
    .module('ss.common.constants')
    .constant('SS_CONSTANT', SS_CONSTANT);

})();
