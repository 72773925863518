(function () {
  'use strict';

  angular
    .module('ss.common.services')
    .factory('subscriptionService', subscriptionService);

  subscriptionService.$inject = ['dataService', 'notificationService'];

  function subscriptionService(dataService, notificationService) {
    const endPoint = 'subscription';
    const service = {
      cancelUserPlan: cancelUserPlan,
      undoDontCancelUserPlan: undoDontCancelUserPlan,
      fetchCurrentUserSubscription: fetchCurrentUserSubscription,
      subscriptionAction: subscriptionAction
    };
    return service;

    /////////////////////////////////////////

    function cancelUserPlan() {
      dataService.startSpinning();
      return dataService.delete(endPoint, '/user').then(response => {
        notificationService.success('Your plan will downgrade at the end of the current term. If you change your mind and decide you want to keep your plan: click the undo option.', true);
        dataService.stopSpinning();
        return response;
      }).catch(reason => {
        dataService.throwException(reason, 'Error processing your request.', true);
        dataService.stopSpinning();
      });
    }

    function undoDontCancelUserPlan() {
      return dataService.post(endPoint, '/user').then(response => {
        notificationService.success('The cancellation is rescinded and your original subscription will continue.', true);
        return response;
      }).catch(reason => dataService.throwException(reason, 'Error processing your request.', true));
    }

    function fetchCurrentUserSubscription() {
      return dataService.get(endPoint, '/user');
    }

    function subscriptionAction(plan, card, type, options) {
      dataService.startSpinning();
      const errorMessage = 'Boo. Your payment failed.';
      let path = `/${type}/${plan}`;
      const queryParams = {
        use_saved_card: options.useSavedCard
      };
      if (type === 'updatePayment' || (type === 'subscribe' && !options.useSavedCard)) {
        queryParams.cardHolderName = card.cardHolderName;
        queryParams.number = card.cardNumber;
        queryParams.cvc = card.cvv;
        queryParams.exp_month = card.expirationMonth;
        queryParams.exp_year = card.expirationYear;
      }

      if (type === 'updatePayment') {
        delete queryParams.use_saved_card;
        queryParams.updatePayment = options.shouldUpdatePayment;
        queryParams.updateSubscriptionPayment = options.shouldUpdateSubscriptionPayment;
      }

      const queryString = dataService.httpParamSerializer(queryParams, '?');
      path += queryString;
      if (type === 'subscribe') {
        return dataService.post(endPoint, path).then(() => dataService.stopSpinning()).catch(reason => {
          dataService.stopSpinning();
          dataService.throwException(reason, reason.data && reason.data.message ? reason.data.message : errorMessage);
        });
      } else if (type === 'updatePayment') {
        return dataService.put(endPoint, path).then(() => dataService.stopSpinning()).catch(reason => {
          dataService.stopSpinning();
          dataService.throwException(reason, reason.data && reason.data.message ? reason.data.message : errorMessage);
        });
      }
    }

  }
})();
