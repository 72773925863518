(function (global) {
  'use strict';

  function getSupportedBrowsersConfiguration() {
    return {
      chrome: {
        pattern: /(chrome|crios)/i,
        minVersion: null
      },
      firefox: {
        pattern: /firefox/i,
        minVersion: null
      },
      opera: {
        pattern: /opera/i,
        minVersion: null
      },
      safari: {
        pattern: /safari/i,
        minVersion: null
      },
      phantom: {
        pattern: /phantom/i,
        minVersion: null
      },
      ie: {
        pattern: /ie/i,
        minVersion: 12
      }
    };
  }

  function getBrowser() {
    var userAgent = navigator.userAgent;
    var tempInfo;
    var agentInfo = userAgent.match(/(opera|chrome|crios|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    var browserInfo = {name: '', version: ''};

    // Parsing for IE browsers
    if (/trident/i.test(agentInfo[1])) {
      tempInfo = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      browserInfo.name = 'IE';
      browserInfo.version = (tempInfo[1] || '');
      return browserInfo;
    }

    // Parsing for chrome and opera browsers
    if (agentInfo[1] === 'Chrome') {
      tempInfo = userAgent.match(/\bOPR\/(\d+)/);
      if (tempInfo !== null) {
        browserInfo.name = 'Opera';
        browserInfo.version = tempInfo[1];
        return browserInfo;
      }
    }

    // Parsing for Safari and phantomjs
    if (agentInfo[1] === 'Safari') {
      tempInfo = userAgent.match(/\bPhantomJS\/(\d+)/);
      if (tempInfo !== null) {
        browserInfo.name = 'Phantom';
        browserInfo.version = tempInfo[1];
        return browserInfo;
      }
    }

    agentInfo = agentInfo[2] ? [agentInfo[1], agentInfo[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tempInfo = userAgent.match(/version\/(\d+)/i);

    if (tempInfo !== null) {
      agentInfo.splice(1, 1, tempInfo[1]);
    }

    browserInfo.name = agentInfo[0];
    browserInfo.version = agentInfo[1];
    return browserInfo;
  }

  function isUnsupportedTemplate(url) {
    return url.indexOf('unsupported.html') !== -1;
  }

  function isBrowserSupported() {
      var supportedBrowsersConfiguration = getSupportedBrowsersConfiguration();
      var browserName = getBrowser().name;
      var browserVersion = getBrowser().version;
      var key;
      for (key in supportedBrowsersConfiguration) {
          if (supportedBrowsersConfiguration[key].pattern.test(browserName) &&
              browserVersion >= supportedBrowsersConfiguration[key].minVersion) {
              return true;
          }
      }
      return false;
  }

  function showUnsupportedBrowserPageIfIsNeeded() {
      var url = document.URL.toString();
      if (!isBrowserSupported()) {
          if (!isUnsupportedTemplate(url)) {
              if (!window.location.origin) {
                  window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
              }
              window.location = window.location.origin + '/unsupported.html';
          }
      } else if (isUnsupportedTemplate(url)) {
        window.location = url.split('unsupported.html')[0];
      }
  }

  function isIOS() {
      var iDevices = [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
      ];

      if (!!navigator.platform) {
          while (iDevices.length) {
              if (navigator.platform === iDevices.pop()){ return true; }
          }
      }

      return false;
  }

  function isSafari() {
    var browser= getBrowser();
    return !!browser && browser.name === 'Safari';
  }

  global.showUnsupportedBrowserPageIfIsNeeded = showUnsupportedBrowserPageIfIsNeeded;
  global.isIOS = isIOS;
  global.isSafari = isSafari;
})(window);
