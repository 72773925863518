(function () {
  'use strict';

  angular
    .module('ss.client.pages')
    .component('projectOpenPageComponent', {
      controller: ProjectOpenPageComponent,
      bindings: {
        project: '<'
      },
      templateUrl: 'app/client/pages/project-open-page/project-open-page.component.html'
    });

  ProjectOpenPageComponent.$inject = ['$rootScope', 'stateService', '$uibModal', '$window', 'mediaService', '$timeout',
    'projectService', 'usSpinnerService', 'customAnalyticsService', 'SS_CONSTANT', 'webSocketProxy', 'errorService',
    'notificationService', 'boxAuthenticationService', '$scope', 'sharedService', 'fileHelperService', 'usersService'
  ];

  function ProjectOpenPageComponent($rootScope, stateService, $uibModal, $window, mediaService, $timeout,
    projectService, usSpinnerService, customAnalyticsService, SS_CONSTANT, webSocketProxy, errorService,
    notificationService, boxAuthenticationService, $scope, sharedService, fileHelperService, usersService) {

    const me = this;
    let filePicker;
    let dropBoxUploadingInProgress = false;
    let boxUploadingInProgress = false;
    let progressChangeListener;
    let isTranscribedOpen = false;

    const MAXIMUM_FILE_DURATION = 14400;

    me.$onInit = onInit;
    me.$onDestroy = onDestroy;
    me.openQuickTips = openQuickTips;
    me.hasProjectUploads = hasProjectUploads;
    me.getMediaProgress = getMediaProgress;
    me.getMediaProgressInKb = getMediaProgressInKb;
    me.saveProject = saveProject;
    me.encodeFramePath = encodeFramePath;
    me.hasMedias = hasMedias;
    me.uploadLocalFiles = uploadLocalFiles;
    me.uploadDropboxFiles = uploadDropboxFiles;
    me.deleteMedia = deleteMedia;
    me.startTranscribeFlow = startTranscribeFlow;
    me.getStatusLabel = getStatusLabel;
    me.onPickedGoogleFiles = onPickedGoogleFiles;
    me.uploadBoxFiles = uploadBoxFiles;
    me.showTranscribeHintMessageOnClick = showTranscribeHintMessageOnClick;
    const cancellableUploaders = {};
    me.lastMessage = null;
    me.user = sharedService.getUser();
    me.shownTranscribeHintMessage = {};

    function onInit() {
      me.notConnectedToBox = false;
      usersService.accountVerified().then(response => me.accountVerified = response);
      subscribeToEvents();
      initBox();
      resetWebsocketMonitorTimer();
      me.websocketMonitor = setInterval(websocketMonitorPoll, 10000);
    }

    function onDestroy() {
      clearInterval(me.websocketMonitor);
      unsubscribeFromEvents();
    }

    function resetWebsocketMonitorTimer() {
      me.lastMessage = new Date();
    }

    function websocketMonitorPoll() {
      if (hasNonReadyMedias()) {
        const diff = new Date() - me.lastMessage;
        // one minute since last message. Refresh
        if (diff > 60000 && !mediaService.hasProjectLocalUploads(me.project.id)) {
          notificationService.log('No local uploads, and more than 60 seconds since last message. Forcing full reload')
          resetWebsocketMonitorTimer();
          stateService.reload();
        } else if (diff > 30000) { // 30 seconds since last message. Try reconnecting
          notificationService.log('More than 30 seconds since last message. Forcing websocket reconnection');
          mediaService.stopListeningUploadProgress(me.project.id);
          mediaService.startListeningUploadProgress(me.project.id);
        }
      }
    }

    function subscribeToEvents() {
      webSocketProxy.subscribe(webSocketProxy.type.update, me.project.id, updateForProject => {
        updateForProject.medias = updateForProject.medias.filter(media => media.status !== 'empty');
        angular.merge(me.project, updateForProject);
      });

      webSocketProxy.subscribe(webSocketProxy.type.statusUpdate, me.project.id, data => {
        if (data && data.status) {
          if (data.status === 'failed') {
            stateService.reload();
          }
          if (me.project.status !== data.status && data.status === 'translated') {
            notificationService.success('Project is translated 🚀');
          }
          if (me.project.status !== data.status && data.status === 'translated') {
            notificationService.success('Project has been subtitled.');
          }
          me.project.status = data.status;
          sharedService.redirectToProject(me.project);
        }
      });

      mediaService.startListeningUploadProgress(me.project.id);
      progressChangeListener = $rootScope.$on('mediaProgressChange', (event, mediaProgressUpdate) => {
        me.lastMessage = new Date();
        const mediaToUpdate = _.find(me.project.medias, {
          id: mediaProgressUpdate.mediaId
        });
        if (mediaToUpdate) {
          mediaToUpdate.status = mediaProgressUpdate.status;
        }
        if (isMediaUploaded()) {
          me.startTranscribeFlow(true);
        }
      });

      webSocketProxy.subscribe(webSocketProxy.type.mediaUpdate, me.project.id, updateForMedia => {
        const media = me.project.medias.find(mediaItem => updateForMedia.id === mediaItem.id);
        if (media) {
          angular.merge(media, updateForMedia);
        }
      });

      errorService.subscribeMediaErrors(me.project.id, error => {
        notificationService.error(error.message);
        if (error.mediaId && error.deleteMedia) {
          me.deleteMedia(error.mediaId);
        }
      });
    }

    function isMediaUploaded() {
      return !mediaService.hasProjectUploads(me.project.id) && me.hasMedias();
    }

    function uploadCallResponse(response) {
      const mediaToUpdate = _.find(me.project.medias, {
        id: response.updatedMediaId
      });
      if (mediaToUpdate) {
        mediaToUpdate.status = 'preparing'
      }
    }

    function unsubscribeFromEvents() {
      if (angular.isFunction(progressChangeListener)) {
        progressChangeListener();
      }
      mediaService.stopListeningUploadProgress(me.project.id);
      webSocketProxy.unsubscribe(webSocketProxy.type.mediaUpdate, me.project.id);
      errorService.unsubscribeFromMediaErrors(me.project.id);
      if (me.project && me.project.id) {
        webSocketProxy.unsubscribe(webSocketProxy.type.update, me.project.id);
        webSocketProxy.unsubscribe(webSocketProxy.type.statusUpdate, me.project.id);
      }
    }

    function generatePayload(file) {
      let fileName = file.name || file.fileName;
      fileName = getFileName(fileName);
      const fileExtension = fileHelperService.getFileExtension(fileName);
      if (SS_CONSTANT.SUPPORTED_MEDIA_FILES[fileExtension]) {
        const mediaType = SS_CONSTANT.SUPPORTED_MEDIA_FILES[fileExtension].type;
        return {
          name: fileName,
          project: me.project,
          frameFilePath: SS_CONSTANT.MEDIA_THUMBNAILS_IMAGES[mediaType]
        };
      } else {
        handleUploadError(`Gosh. ${fileName} is an unsupported format. See FAQ for acceptable formats.`);
      }
    }

    function supportCheckDuration(file) {
      const fileName = file.name || file.fileName;
      const fileExtension = fileHelperService.getFileExtension(fileName);
      if (SS_CONSTANT.SUPPORTED_CHECK_DURATION[fileExtension]) {
        return true
      }
      return false;
    }

    function saveProject() {
      return projectService.save(me.project);
    }

    function encodeFramePath(framePath) {
      return {
        'background-image': `url(${mediaService.encodeFramePath(framePath)})`
      };
    }

    function hasMedias() {
      if (angular.isArray(me.project.medias) && me.project.medias.length) {
        const emptyMedias = me.project.medias.filter(media => media.status === 'empty');
        const completedMedias = me.project.medias.filter(media => ['empty', 'ready'].indexOf(media.status) !== -1);
        return completedMedias.length > emptyMedias.length;
      }
      return false;
    }

    function hasNonReadyMedias() {
      if (angular.isArray(me.project.medias) && me.project.medias.length) {
        const completedMedias = me.project.medias.filter(media => ['empty', 'ready', 'failed'].indexOf(media.status) !== -1);
        return completedMedias.length < me.project.medias.length;
      }
      return false;
    }

    function uploadLocalFiles(files) {
      resetWebsocketMonitorTimer();
      customAnalyticsService.trackEvent('Upload', 'Load local files', 'Local', null);
      angular.forEach(files, file => {
        const payload = generatePayload(file);
        if (supportCheckDuration(file)) {
          var mediaFile = document.createElement('video');
          mediaFile.preload = 'metadata';
          mediaFile.onerror = function () {
            uploadLocalProcess(file, payload);
          };
          mediaFile.onloadedmetadata = function () {
            window.URL.revokeObjectURL(mediaFile.src);
            var duration = mediaFile.duration;
            if (duration <= MAXIMUM_FILE_DURATION) {
              if (payload) {
                uploadLocalProcess(file, payload);
              }
            } else {
              notificationService.error(`Error: maximum per file duration is 4 hours. Shorten and then reimport.`);
            }
          }
          mediaFile.src = URL.createObjectURL(file);
        } else {
          uploadLocalProcess(file, payload);
        }
      });
    }

    function uploadLocalProcess(file, payload) {
      mediaService.create(payload).then(media => {
        customAnalyticsService.trackEvent('Upload', 'upload file success', 'Local', null);
        resetWebsocketMonitorTimer();
        me.project.medias.push(media);
        const fileName = getFileName(file.name);
        const preSignedUrlParams = {
          fileName: fileName,
          mimeType: file.type,
          mediaId: media.id
        };
        mediaService.getSignedUrl(me.project.id, preSignedUrlParams).then(signedResponse => {
          if (file.size <= mediaService.maxLocalSize) {
            cancellableUploaders[media.id] = mediaService.uploadLocalFile(file, signedResponse.key, media.id, me.project.id, signedResponse.url);
            cancellableUploaders[media.id].promise.then(response => {
              if (response) {
                const mediaToUpdate = _.find(me.project.medias, {
                  id: response.mediaId
                });
                if (mediaToUpdate) {
                  mediaToUpdate.status = 'preparing';
                }
                mediaService.removeUploadedFile(response.mediaId);
              }
            }).catch(reason => removeMedia(reason.mediaId));
          } else {
            handleUploadError(mediaService.errorMessageLocalUploader, media.id);
          }
        });
      });
    }

    function uploadDropboxFiles() {
      resetWebsocketMonitorTimer();
      customAnalyticsService.trackEvent('Upload', 'Loading Dropbox files', 'Dropbox', null);
      $window.Dropbox.choose({
        linkType: 'direct',
        multiselect: true,
        success: files => {
          if (!dropBoxUploadingInProgress) {
            let filesCount = files.length;
            dropBoxUploadingInProgress = true;
            customAnalyticsService.trackEvent('Upload', 'Selected Dropbox files', 'Dropbox', null);
            angular.forEach(files, file => {
              const payload = generatePayload(file);
              if (payload) {
                mediaService.create(payload).then(media => {
                  customAnalyticsService.trackEvent('Project Open', 'uploaded dropbox files success', null, null);
                  me.project.medias.push(media);
                  mediaService.prepareRemoteFile(mediaService.uploaderType.dropbox, me.project.id, media.id, file).then(response => {
                    const uploadPayload = {
                      preparedFile: response,
                      media: media,
                      projectId: me.project.id
                    };
                    filesCount--;
                    if (filesCount === 0) {
                      dropBoxUploadingInProgress = false;
                    }
                    uploadFileEvent(uploadPayload);
                  }).catch(reason => {
                    filesCount--;
                    if (filesCount === 0) {
                      dropBoxUploadingInProgress = false;
                    }
                    handleUploadError(reason.message, reason.mediaId);
                  });
                }).catch(() => {
                  filesCount--;
                  if (filesCount === 0) {
                    dropBoxUploadingInProgress = false;
                  }
                });
              } else {
                dropBoxUploadingInProgress = false;
              }
            });
          }
        },
        cancel: () => dropBoxUploadingInProgress = false
      });
    }

    function onPickedGoogleFiles(docs, accessToken) {
      resetWebsocketMonitorTimer();
      const files = [];
      angular.forEach(docs, doc =>
        files.push({
          name: doc.name,
          bytes: doc.sizeBytes,
          link: doc.id,
          accessToken: accessToken
        }));

      angular.forEach(files, file => {
        const payload = generatePayload(file);
        if (payload) {
          mediaService.create(payload).then(media => {
            me.project.medias.push(media);
            mediaService.prepareRemoteFile(mediaService.uploaderType.drive, me.project.id, media.id, file).then(response => {
              const uploadPayload = {
                preparedFile: response,
                media: media,
                projectId: me.project.id
              };
              uploadFileEvent(uploadPayload);
            }).catch(reason => handleUploadError(reason.message, reason.mediaId));
          });
        }
      });
    }

    function uploadBoxFiles() {
      resetWebsocketMonitorTimer();
      if (me.notConnectedToBox) {
        const message = `First connect your Box Account from your <a class='white-link' href='/app/account-settings#connectedApps'>Profile page.</a>`
        notificationService.error(message);
      } else {
        $('.ui-box-file-picker').trigger('click');
        handleBoxPicker();
      }
    }

    function deleteMedia(mediaId) {
      customAnalyticsService.trackEvent('Project Open', 'click delete media button', null, null);
      // we need to check if project is uploading, and if so, delete it
      if (cancellableUploaders[mediaId]) {
        cancellableUploaders[mediaId].canceler.resolve('abort by user');
      }
      mediaService.delete(me.project.id, mediaId).then(() => removeFromUI(mediaId)).catch(() => removeFromUI(mediaId));
      mediaService.removeUploadedFile(mediaId);
    }

    function removeFromUI(mediaId) {
      const projectMedias = me.project.medias;
      for (let i = 0; i < projectMedias.length; ++i) {
        if (projectMedias[i].id === mediaId) {
          projectMedias.splice(i, 1);
          break;
        }
      }
      // check we're ready to transcribe if a still-working project has been removed.
      if (isMediaUploaded()) {
        me.startTranscribeFlow(true);
      }
      $scope.$digest();
    }

    function startTranscribeFlow(skipWarnings) {
      if (hasMedias()) {
        if (me.user && me.user.accountActivationPending) {
          notificationService.error(`Please confirm your email address before proceeding. Then refresh the page. (Check your spam box if you can't find it or contact support.)`);
        } else {
          if (!isTranscribedOpen) {
            usSpinnerService.spin('spinner-ss');
            isTranscribedOpen = true;
            updateProjectInfo().then(() => {
              if (areAllFilesReady()) {
                mediaService.getProjectsPrice(me.project.id).then(price => {
                  usSpinnerService.stop();
                  const transcribeModal = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'transcribeProjectModal',
                    resolve: {
                      price: () => price,
                      project: () => me.project,
                      planEnum: () => me.user.plan.planEnum
                    }
                  });
                  transcribeModal.result.catch(() => isTranscribedOpen = false);
                });
              } else {
                if (!skipWarnings) {
                  usSpinnerService.stop();
                  notificationService.warning('There are some files that still need to be processed/uploaded before continue transcribing.');
                }
              }
            });
          }
        }
      } else if (!skipWarnings) {
        notificationService.warning('Upload a file first and I can transcribe it.');
      }
    }

    function updateProjectInfo() {
      return projectService.get(me.project.id).then(response => me.project = response);
    }

    function areAllFilesReady() {
      return me.project.medias && me.project.medias.length > 0 ? me.project.medias.length === me.project.medias.filter(media => ['empty', 'ready'].indexOf(media.status) !== -1).length : false;
    }

    function getStatusLabel(projectId, mediaId) {
      return mediaService.getStatusLabel(projectId, mediaId, me.project.medias);
    }

    function openQuickTips() {
      $uibModal.open({
        animation: true,
        component: 'quickTipsForUploadModal'
      });
    }

    function hasProjectUploads() {
      return mediaService.hasProjectUploads(me.project.id);
    }

    function getMediaProgress(mediaId) {
      return mediaService.getMediaProgress(mediaId, me.project.id);
    }

    function getMediaProgressInKb(mediaId) {
      return mediaService.getMediaProgressInKb(mediaId, me.project.id);
    }

    function initBox() {
      boxAuthenticationService.getAuthToken().then(response => {
        me.notConnectedToBox = false;
        const token = response.accessToken;
        filePicker = new Box.FilePicker(token);
        filePicker.addListener('choose', files => {
          $('.main-app-header-container').show();
          $('.navbar-fixed-top').show();
          $('.bg-footer-login').show();
          if (boxUploadingInProgress) {
            return;
          }
          let filesCount = files.length;
          boxUploadingInProgress = true;
          customAnalyticsService.trackEvent('Upload', 'Selected Box files', 'Box', null);

          angular.forEach(files, file => {
            const payload = generatePayload(file);
            if (payload) {
              mediaService.create(payload).then(media => {
                customAnalyticsService.trackEvent('Project Open', 'uploaded box files success', null, null);
                me.project.medias.push(media);
                mediaService.prepareRemoteFile(mediaService.uploaderType.box, me.project.id, media.id, file).then(response => {
                  response.boxFileId = file.id;
                  const uploadPayload = {
                    preparedFile: response,
                    media: media,
                    projectId: me.project.id
                  };
                  filesCount--;
                  if (filesCount === 0) {
                    boxUploadingInProgress = false;
                  }
                  uploadFileEvent(uploadPayload);
                }).catch(reason => {
                  filesCount--;
                  if (filesCount === 0) {
                    boxUploadingInProgress = false;
                  }
                  handleUploadError(reason.message, reason.mediaId);
                });
              });
            }
          });
        });
        filePicker.addListener('cancel', () => handleBoxPicker(true));
        filePicker.show('0', token, {
          container: '.preview-container',
          modal: {
            buttonLabel: 'Content Tree',
            buttonClassName: 'ui-box-file-picker'
          }
        });
      }).catch(() => me.notConnectedToBox = true);
    }

    function handleBoxPicker(show) {
      if (show) {
        $('.main-app-header-container').show();
        $('.navbar-fixed-top').show();
        $('.bg-footer-login').show();
      } else {
        $('.main-app-header-container').hide();
        $('.navbar-fixed-top').hide();
        $('.bg-footer-login').hide();
      }
    }

    function uploadFileEvent(payload) {
      if (payload.preparedFile.fileSize < mediaService.maxSize) {
        mediaService.uploadFile(payload).then(response => uploadCallResponse(response)).catch(reason => removeMedia(reason.mediaId));
      } else {
        handleUploadError(mediaService.errorMessageRemoteFileMaxSize, media.id);
      }
    }

    function removeMedia(mediaId) {
      if (mediaId) {
        const media = _.find(me.project.medias, {
          id: mediaId
        });
        if (media) {
          me.deleteMedia(mediaId);
        }
      }
    }

    function showTranscribeHintMessageOnClick(mediaId) {
      if (mediaId) {
        const media = _.find(me.project.medias, {
          id: mediaId,
          status: 'ready'
        });

        if (media && !me.shownTranscribeHintMessage[mediaId]) {
          me.shownTranscribeHintMessage[mediaId] = $timeout(() => {
            delete me.shownTranscribeHintMessage[mediaId];
          }, 5000);
        }
      }
    }

    function handleUploadError(message, mediaId) {
      customAnalyticsService.trackEvent('Upload', 'Error upload file', message, null);
      notificationService.error(message);
      if (mediaId) {
        removeMedia(mediaId);
      }
    }

    function getFileName(fileName) {
      const names = fileName.split('.');
      if (names.length === 2) {
        return fileName;
      } else {
        for (let i = 1; i < names.length - 1; i++) {
          if (SS_CONSTANT.SUPPORTED_MEDIA_FILES[names[i]]) {
            names.splice(i, 1);
          }
        }
        return names.join('.');
      }
    }

  }
})();
