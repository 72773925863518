(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('updatePaymentDetails', {
      controller: UpdatePaymentDetailsComponent,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/update-payment-details-modal/update-payment-details-modal.component.html'
    });

  UpdatePaymentDetailsComponent.$inject = ['customEcommerceAnalytics', 'sharedService', 'subscriptionService', 'usersService'];

  function UpdatePaymentDetailsComponent(customEcommerceAnalytics, sharedService, subscriptionService, usersService) {
    const me = this;

    me.$onInit = onInit;
    me.update = update;
    me.cancel = cancel;
    me.user = sharedService.getUser();

    function onInit() {
      me.userStripe = me.resolve.planInfo.stripePlanId ? true : false;
    }

    function update(plan, card, shouldUpdatePayment, shouldUpdateSubscriptionPayment) {
      const planData = me.resolve.planInfo;
      const updatePaymentOptions = {
        shouldUpdatePayment,
        shouldUpdateSubscriptionPayment
      };
      subscriptionService.subscriptionAction(plan, card, 'updatePayment', updatePaymentOptions).then(() => {
        const userRandomId = me.user.id + (Math.random() * 10);
        // TODO: CC Please verify ecommerce analytics entry.
        customEcommerceAnalytics.clearTrans();
        customEcommerceAnalytics.addTransaction(userRandomId, planData.planEnum, planData.subscriptionCharge, '0', '0');
        customEcommerceAnalytics.addItem(userRandomId, planData.planEnum, planData.subscriptionCharge, 1, '-SUB');
        customEcommerceAnalytics.trackTrans();

        return usersService.reloadUser();
      })
      .then(() => {
        me.close({
          $value: {
            planName: me.resolve.planInfo.label
          }
        });
      });
    }

    function cancel() {
      me.dismiss();
    }

  }
})();
