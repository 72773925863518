(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .factory('transcriptionService', transcriptionService);

  transcriptionService.$inject = ['dataService'];

  function transcriptionService(dataService) {
    const endPoint = 'media';
    const service = {
      saveTranscript: saveTranscript,
      delete: deleteTranscription,
      save: save,
      getByMedia: getByMedia,
      getTranscriptHistories
    };
    return service;

    ///////////////////////////////////

    function saveTranscript(payload, mediaId, urlParams = new URLSearchParams({})) {
      const path = `/${mediaId}/transcript${ urlParams.toString() ? `?${urlParams.toString()}` : ""}`;
      return dataService.post(endPoint, payload, path).catch(reason => {
        if (reason.status === -1 || reason.status === 0) {
          if (!me.isReconnecting) { // we don't need an alert if we're already telling the user we're reconnecting
            dataService.logException('Internet connection is lost. Any changes or edits will not save. Please reconnect to the Internet to continue.');
          }
        }
        dataService.throwException(reason, 'An error occurred while saving the transcript and your latest edits were not saved. Try again by clicking "Save Now" or logout and log back in as your session may have expired.');
      });
    }

    function deleteTranscription(mediaId, id) {
      const path = `/${mediaId}/transcription/${id}`;
      return dataService.delete(endPoint, path);
    }

    function save(transcription, mediaId) {
      const payload = angular.copy(transcription);
      delete payload.owner;

      const path = `/${mediaId}/transcription`;
      return dataService.post(endPoint, payload, path);
    }

    function getByMedia(mediaId, urlParams = new URLSearchParams({})) {
      const path = `/${mediaId}/transcription${ urlParams.toString() ? `?${urlParams.toString()}` : ""}`;
      return dataService.get(endPoint, path).catch(reason => dataService.logException(reason));
    }

    function getTranscriptHistories(mediaId) {
      const path = `/${mediaId}/transcript-history`;
      return dataService.get(endPoint, path)
    }
  }
})();
