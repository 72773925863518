(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .factory('usersService', usersService);

  usersService.$inject = ['$document', 'stateService', 'dataService', 'notificationService', 'sharedService',
    'webSocketProxy', 'fullStoryWrapperService', '$window', 'customAnalyticsService', 'authService'];

  function usersService($document, stateService, dataService, notificationService, sharedService,
    webSocketProxy, fullStoryWrapperService, $window, customAnalyticsService, authService) {

    const endPoint = 'user';
    const service = {
      getUserInfo: getUserInfo,
      getHash: getHash,
      getCrispHash: getCrispHash,
      getTransactions: getTransactions,
      getInviteCode: getInviteCode,
      getUserTags: getUserTags,
      createUserTags: createUserTags,
      updateUserTags: updateUserTags,
      deleteUserTags: deleteUserTags,
      getUserProjectTags: getUserProjectTags,
      associateTagToProject: associateTagToProject,
      desassociateTagFromProject: desassociateTagFromProject,
      deleteAccount: deleteAccount,
      addAuth0UsernamePasswordIdentity: addAuth0UsernamePasswordIdentity,
      accountVerified: accountVerified,
      saveUserContact: saveUserContact,
      exportInvoice: exportInvoice,
      reloadUser: reloadUser,
      saveUserFullNameAndAvatar: saveUserFullNameAndAvatar,
      getDefaultAvatars: getDefaultAvatars,
      getSignedUrlForImage: getSignedUrlForImage,
      uploadAvatarToServer: uploadAvatarToServer,
      updateEmail: updateEmail,
      logout: logout
    };
    return service;

    /////////////////////////////////////

    function getUserInfo() {
      let inviteCode;
      if ($document[0].cookie) {
        inviteCode = $document[0].cookie.split(';').filter(item => item.includes('inviteCode'));
        if (inviteCode && inviteCode.length > 0) {
          inviteCode = inviteCode[0].trim();
        }
      }
      // TODO: Change enpoint url this when you are going to be refatoring tokens
      return dataService.get(endPoint, '?' + inviteCode).then(response => {
        if (response && response.error) {
          throw new Error(response.error);
        }
        return response;
      }).catch(reason => {
        stateService.goTo('app.dashsboard');
        dataService.logException(reason);
      });
    }

    function reloadUser() {
      return getUserInfo().then(user => sharedService.setUser(user));
    }

    function getHash() {
      return dataService.get(endPoint, '/hash');
    }

    function getCrispHash() {
      return dataService.get(endPoint, '/hash-crisp');
    }

    function getTransactions() {
      return dataService.get(endPoint, '/transactions');
    }

    function getInviteCode() {
      return dataService.get(endPoint, '/inviteCode');
    }

    function getUserTags() {
      return dataService.get(endPoint, '/tags');
    }

    function createUserTags(payload) {
      return dataService.post(endPoint, payload, '/tags').catch(reason => {
        let errorMessage = 'There was an error creating the label';
        if (reason && reason.status === 422 && reason.data && reason.data.message) {
          errorMessage = reason.data.message;
        }
        dataService.throwException(reason, errorMessage);
      });
    }

    function updateUserTags(id, payload) {
      const path = `/tags/${id}`;
      return dataService.put(endPoint, payload, path).catch(reason => dataService.throwException(reason, 'There was an error updating the label'));
    }

    function deleteUserTags(id) {
      const path = `/tags/${id}`;
      return dataService.delete(endPoint, path).catch(reason => dataService.throwException(reason, 'There was an error deleting the label'));
    }

    function getUserProjectTags() {
      return dataService.get(endPoint, '/project-tags');
    }

    function associateTagToProject(payload) {
      return dataService.post(endPoint, payload, '/project-tags').catch(reason => dataService.throwException(reason, 'There was an error assigning the label'));
    }

    function desassociateTagFromProject(id) {
      const path = `/project-tags/${id}`;
      return dataService.delete(endPoint, path).catch(reason => dataService.throwException(reason, 'There was an error assigning the label'));
    }

    function deleteAccount() {
      return dataService.delete(endPoint).then(response => {
        notificationService.success('Your account has been deleted.', true);
        return response;
      }).catch(reason => dataService.throwException(reason, 'There was a proplem deleting your account.', true));
    }

    function addAuth0UsernamePasswordIdentity(plainPassword) {
      return dataService.post(endPoint, plainPassword, '/set-password').catch(reason => dataService.throwException(reason, reason.data.massage));
    }

    function accountVerified() {
      return dataService.get(endPoint, '/account-verified');
    }

    function saveUserContact(payload) {
      return dataService.put(endPoint, payload, '/contact');
    }

    function exportInvoice(queryParams) {
      const config = { responseType: 'arraybuffer' };
      const path = '/export-invoice' + dataService.httpParamSerializer(queryParams, '?').split('Z').join('');

      return dataService.postExport(endPoint, null, path, config)
        .then(response => dataService.downloadFileLocally(response))
        .catch(reason => onExportError(reason));
    }

    function onExportError(reason) {
      let message = '';
      if (reason.status === 422) {
        const arr = new Uint8Array(reason.data);
        const str = String.fromCharCode.apply(String, arr);
        if (/[\u0080-\uffff]/.test(str)) {
          message = 'This string seems to contain (still encoded) multibytes';
        }
        message = angular.fromJson(str).message;
      } else {
        message = 'There was an error exporting the invoice. Try again or contact support if the issue persists.';
      }
      dataService.throwException(reason, message);
    }

    function saveUserFullNameAndAvatar(payload) {
      return dataService.put(endPoint, payload)
        .catch(reason => dataService.throwException(reason, `There was a problem updating your profile.`));
    }

    function getDefaultAvatars() {
      return dataService.get(endPoint, '/defaultProfilePic');
    }

    function getSignedUrlForImage(payload) {
      return dataService.post(endPoint, payload, '/signed-url-to-upload')
        .catch(reason => dataService.throwException(reason, 'There was a problem updating your profile pic.'));
    }

    function uploadAvatarToServer(url, file, config) {
      return dataService.uploadFile(url, file, config)
        .catch(reason => dataService.throwException(reason, 'There was a problem updating your profile pic.'));
    }

    function updateEmail(payload) {
      return dataService.post(endPoint, payload, '/updateEmail')
        .catch(reason => {
          if (reason.status === 400) {
            notificationService.error('An account registered with that address already exists');
          } else if (reason.status === 500) {
            notificationService.error('We encountered an issue while updating your email address. Please contact support');
          }
          throw reason;
        });
    }

    function logout(user) {
      webSocketProxy.unsubscribe(webSocketProxy.type.userData, user.id);
      fullStoryWrapperService.logout();
      sharedService.setIntercomInitialized(false);
      sharedService.setCrispInitialized(false);
      $window.Intercom('shutdown');
      $window.$crisp.push(["do", "session:reset"]);
      customAnalyticsService.clearAmplitudeUser();
      authService.logout();
    }

  }
})();
