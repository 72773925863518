(function () {
  'use strict';
  angular
    .module('ss.client.components')
    .component('noFoundOops', {
      controller: function () {},
      bindings: {},
      templateUrl: 'app/client/components/no-found-oops/no-found-oops.component.html'
    });
})();
