(function () {
  'use strict';
  angular
    .module('ss.client.pages')
    .component('noAuthorizePageComponent', {
      controller: function () {},
      bindings: {},
      templateUrl: 'app/client/pages/no-authorize-page/no-authorize-page.component.html'
    });
})();
