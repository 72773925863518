(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('projectDictionaryModal', {
      controller: ProjectDictionaryModalComponent,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/client/modals/project-dictionary-modal/project-dictionary-modal.component.html'
    });

  ProjectDictionaryModalComponent.$inject = ['sharedService', 'notificationService', 'dictionaryService', 'SS_CONSTANT'];

  function ProjectDictionaryModalComponent(sharedService, notificationService, dictionaryService, SS_CONSTANT) {
    const me = this;
    const user = sharedService.getUser();
    const planLimitReachedServerErrorMessage = "You need to upgrade your account to save more dictionary";
    const planLimitReachedUserMessage = `You're at your <a href="${SS_CONSTANT.DICTIONARIES_LIMITS_INFO_LINK}" target="_blank" rel="noopener noreferrer">plan's limit.</a>`;

    me.$onInit = onInit;
    me.save = saveDictionary;
    me.cancel = cancel;
    me.onLanguageSelected = onLanguageSelected;
    me.isDictionaryDataInvalid = isDictionaryDataInvalid;
    me.isAddTagAllowed = isAddTagAllowed;
    me.onTagRemoved = onTagRemoved;
    me.groupByFavorite = groupByFavorite;

    const placeholderDictionary = {
      name: 'My new custom dictionary',
      languageId: null,
      tags: [],
      tagsLabel: buildTagsLabels([]),
    }

    function onInit() {
      me.dictionary = me.resolve.dictionary || placeholderDictionary;
      me.languages = me.resolve.languages;
      me.isNew = !me.resolve.dictionary;
      me.dictionariesConfig = SS_CONSTANT.CUSTOM_DICTIONARIES[user.plan.name];

      if (!me.isNew) {
        me.dictionary.tagsLabel = buildTagsLabels(me.dictionary.tags);
      }

      me.selectedLanguageRegion = {};
      me.languageRegions = buildLanguageRegionsToDisplay(me.languages);
      if (me.dictionary.languageId) {
        me.selectedLanguageRegion.value = _.find(me.languageRegions, {
          id: me.dictionary.languageId
        });
      }
    }

    function buildLanguageRegionsToDisplay(languages) {
      let languagesToDisplay = [];
      languages.forEach(language =>
        language.regions.forEach(region =>
          languagesToDisplay.push({
            id: language.id,
            label: language.label + ' (' + region.name + ')',
            code: language.code + (region.code ? '-' + region.code : ''),
            defaultSpeechApi: region.defaultSpeechApi,
            isFavorite: region.favorite
          })));

      languagesToDisplay = _.orderBy(languagesToDisplay, ['label'])
      const frequentlyUsed = _.filter(languagesToDisplay, {
        isFavorite: true
      });
      const others = _.filter(languagesToDisplay, {
        isFavorite: false
      });
      return _.concat(frequentlyUsed, others);
    }

    function buildTagsLabels(tags) {
      return tags.sort().map(t => ({ text: t }));
    }

    function onLanguageSelected(selectedLanguage) {
      me.dictionary.languageId = selectedLanguage.id;
      resetHasDataInputErrorsFlag();
    }

    function saveDictionary(dictionaryModel, isNew) {
      if (isDictionaryDataInvalid()) {
        return;
      }
      const dictionary = _.pick(dictionaryModel, ['id', 'name', 'languageId']);

      dictionary.tags = dictionaryModel.tagsLabel.map(t => t.text);

      const requestPromise = isNew ? dictionaryService.create(dictionary) : dictionaryService.update(dictionary);

      return requestPromise
        .then(() => me.close())
        .catch((response) => {
          let errorMessage;
          if (response.status === 422 && response.data.message === planLimitReachedServerErrorMessage) {
            errorMessage = planLimitReachedUserMessage;
          } else {
            const reason = response.data.message || '';
            errorMessage = `Failed to ${isNew ? 'create': 'update'} your dictionary. ${reason}`;
          }
          notificationService.error(errorMessage);
        });
    }

    function isDictionaryDataInvalid() {
      me.hasDataInputErrors = !me.dictionary.name || !me.dictionary.languageId || me.dictionary.tagsLabel.length === 0;

      return me.hasDataInputErrors;
    }

    function resetHasDataInputErrorsFlag() {
      me.hasDataInputErrors = false;
    }

    function isWordsLimitReached() {
      if (!me.dictionariesConfig) {
        return false;
      }
      return me.dictionary.tagsLabel.length >= me.dictionariesConfig.wordsPerDictionary
    }

    function isAddTagAllowed() {
      resetHasDataInputErrorsFlag();
      me.wordsLimitReached = isWordsLimitReached();

      return !me.wordsLimitReached;
    }

    function onTagRemoved() {
      resetHasDataInputErrorsFlag();
      me.wordsLimitReached = isWordsLimitReached();
    }

    function groupByFavorite(languageRegion) {
      // if (languageToDisplayItem.isLastUsed) return "Last Used";
      return languageRegion.isFavorite ? 'Frequently Used' : 'Others';
    }

    function cancel() {
      me.dismiss();
    }

  }
})();
