(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .factory('errorService', errorService);

  errorService.$inject = ['webSocketProxy'];

  function errorService(webSocketProxy) {
    let errors = [];
    const service = {
      errorMessages: {
        RECORD_NOT_FOUND: 'com.exceptions.RecordNotFoundException'
      },
      subscribeMediaErrors: subscribeMediaErrors,
      unsubscribeFromMediaErrors: unsubscribeFromMediaErrors,
      subscribeUnexpectedErrors: subscribeUnexpectedErrors
    };
    return service;

    //////////////////////////////

    function handleErrorResponse(response, callback) {
      const error = JSON.parse(response.content);
      errors.push(error);

      if (callback) {
        callback(error);
      }
    }

    function subscribeMediaErrors(projectId, callback) {
      errors = [];
      webSocketProxy.subscribe(webSocketProxy.type.errors, projectId, response => handleErrorResponse(response, callback));
    }

    function unsubscribeFromMediaErrors(projectId) {
      webSocketProxy.unsubscribe(webSocketProxy.type.errors, projectId);
    }

    function subscribeUnexpectedErrors(userId, callback) {
      errors = [];
      webSocketProxy.subscribe(webSocketProxy.type.unexpectedErrors, userId, response => handleErrorResponse(response, callback));
    }

  }
})();
