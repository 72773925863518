(function () {
  'use strict';

  angular
    .module('ss.admin.services')
    .factory('projectAdminService', projectAdminService);

  projectAdminService.$inject = ['dataService', 'notificationService', 'stateService'];

  function projectAdminService(dataService, notificationService, stateService) {
    const endPoint = 'admin/projects/';
    const endPointMedia = 'media/';
    const service = {
      retryJobRequest: retryJobRequest,
      updateMedia: updateMedia,
      deleteMediaFile: deleteMediaFile,
      getProject: getProject,
      updateProject: updateProject,
      updateProjectStatus: updateProjectStatus,
      getProjects: getProjects,
      mergeProjectRequest: mergeProjectRequest,
      retryTranscoding: retryTranscoding
    };
    return service;

    ////////////////

    function retryJobRequest(projectId) {
      const path = `${projectId}/retry-job`;
      return dataService.get(endPoint, path)
        .then(() => notificationService.success('Retry job process has been started successfully.'))
        .catch(reason => dataService.throwException(reason, reason.data.msg));
    }

    function updateMedia(id, payload) {
      return dataService.put(endPointMedia, payload, id).then(() => stateService.reload());
    }

    function deleteMediaFile(id) {
      return dataService.delete(endPointMedia, id).then(() => {
        notificationService.success('File Deleted Successfully.', true);
        stateService.reload();
      }).catch(reason => dataService.throwException(reason, 'Error processing your request.', true));
    }

    function getProject(id) {
      return dataService.get(endPoint, id);
    }

    function updateProject(payload, id) {
      return dataService.put(endPoint, payload, id);
    }

    function updateProjectStatus(payload, id) {
      const path = `update-status/${id}`
      return dataService.post(endPoint, payload, path).then(() => notificationService.success('Project Status has been updated successfully.', true));
    }

    function getProjects(payload) {
      return dataService.post(endPoint, payload, 'project-transactions').catch(reason => dataService.logException(reason));
    }

    function mergeProjectRequest(payload) {
      return dataService.post(endPoint, payload, 'project/merge');
    }

    function retryTranscoding(mediaId) {
      const path = `${mediaId}/retry-transcoding`;
      return dataService.post(endPointMedia, path)
        .then(() => notificationService.success('Retry transcoding process has been started successfully.'))
        .catch(reason => dataService.throwException(reason, reason.data.msg));
    }

  }
})();
