(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .factory('projectService', projectService);

  projectService.$inject = ['dataService', 'notificationService', 'customAnalyticsService', 'sharedService', 'stateService'];

  function projectService(dataService, notificationService, customAnalyticsService, sharedService, stateService) {
    const endPoint = 'projects';
    const directoryEndPoint = 'directory';

    const service = {
      newProject: newProject,
      save: save,
      get: get,
      transcribe: transcribe,
      burnSubtitle: burnSubtitle,
      export: exportProject,
      exportSubtitle: exportSubtitle,
      getUserProjects: getUserProjects,
      getUserDirectories: getUserDirectories,
      newDirectory: newDirectory,
      moveProject: moveProject,
      removeDirectory: removeDirectory,
      updateToAllMedias: updateToAllMedias,
      remove: remove,
      removeFromDashboard: removeFromDashboard,
      newSyncProject: newSyncProject,
      removeProject: removeProject,
      buyCredit: buyCredit
    };
    return service;

    /////////////////////////////////////////

    function newProject(payload) {
      dataService.startSpinning();
      return dataService.post(endPoint, payload)
        .then(response => {
          dataService.stopSpinning();
          sharedService.redirectToProject(response);
        }).catch(reason => {
          dataService.stopSpinning();
          dataService.throwException(reason, 'There was an error creating the project.');
        });
    }

    function save(project) {
      const payload = angular.copy(project);
      delete payload.owner;
      return dataService.put(endPoint, payload, '/' + project.id)
        .catch(reason => dataService.throwException(reason, reason && reason.data && reason.data.message ? reason.data.message : 'There was an error saving the project.'));
    }

    function get(id) {
      return dataService.get(endPoint, '/' + id).catch(error => dataService.logException(error));
    }

    function transcribe(id, payload) {
      const path = `/${id}/transcribing`;
      const transcribeReqPromise = payload ? dataService.post(endPoint, payload, path) : dataService.post(endPoint, path);
      return transcribeReqPromise.then(() => {
        customAnalyticsService.trackEvent('Transcribe', 'Starting transcription success', null, null);
        $('#modal-success').modal('hide');
        stateService.reload();
      }).catch(reason => {
        customAnalyticsService.trackEvent('Transcribe', 'Payment success', null, reason);
        $('#modal-success').modal('hide');
        dataService.throwException(reason, 'Something wrong happened. The transcribing request could not be processed.');
      });
    }

    function burnSubtitle(id, payload) {
      const path = `/${id}/burn-in`;
      return dataService.post(endPoint, payload, path).then(() => {
        customAnalyticsService.trackEvent('BurnIn', 'Starting burn success', null, null);
        let videoType = 'subtitled';
        if (payload.burnInType === 'burnInTimecode') {
          videoType = 'burned-in TC';
        }
        const message = `Thank you. In a few minutes you will receive an email with a link to download the ${videoType} video(s). Please check your spam box if you do not see it.`;
        notificationService.success(message);
      }).catch(reason => {
        customAnalyticsService.trackEvent('BurnIn', 'Payment success', null, reason);
        dataService.throwException(reason, 'Something wrong happened. The burning request could not be processed.');
      });
    }

    function exportProject(payload, projectId) {
      dataService.startSpinning();
      const path = `/${projectId}/exported-file/`;
      const config = {};
      if (!payload.destinationTarget || payload.destinationTarget === 'local') {
        config.responseType = 'arraybuffer';
      }
      return dataService.postExport(endPoint, payload, path, config)
        .then(response => {
          dataService.downloadFileLocally(response);
          dataService.stopSpinning();
        }).catch(reason => onExportError(reason));
    }

    function exportSubtitle(payload, projectId) {
      dataService.startSpinning();
      const path = `/${projectId}/exported-subtitle/`;
      return dataService.post(endPoint, payload, path)
        .then(response => {
          dataService.stopSpinning();
          get(response.projectId).then(response => sharedService.redirectToProject(response));
        }).catch(reason => onExportError(reason));
    }

    function onExportError(reason) {
      dataService.stopSpinning();
      let message = '';
      if (reason.status === 422) {
        const arr = new Uint8Array(reason.data);
        const str = String.fromCharCode.apply(String, arr);
        if (/[\u0080-\uffff]/.test(str)) {
          message = 'This string seems to contain (still encoded) multibytes';
        } else {
          let errorJson;
          try {
            errorJson = angular.fromJson(str);
            reason.data = errorJson;
          } catch (e) {
            // invalid json
          }

          if (errorJson && errorJson.message) {
            message = 'Transcription export failed due to ' + errorJson.message;
          }
        }
      } else {
        message = 'Your selected media is not transcribed.';
      }
      if (message !== '') {
        notificationService.error(message, reason);
      }
      throw reason;
    }

    function getUserProjects() {
      return dataService.get(`${endPoint}?all=true`).catch(reason => onGettingData(reason, 'projects'));
    }

    function getUserDirectories() {
      return dataService.get(directoryEndPoint).catch(reason => onGettingData(reason, 'directories'))
    }

    function onGettingData(reason, type) {
      let message = '';
      if (!reason.xhrStatus || reason.xhrStatus !== 'abort') {
        message = `There was an error getting the user ${type}.`;
      }
      dataService.throwException(reason, message);
    }

    function newDirectory(payload) {
      return dataService.post(directoryEndPoint, payload)
        .catch(reason => dataService.throwException(reason, 'There was an error creating or editing the folder.'));
    }

    function moveProject(payload) {
      return dataService.post(directoryEndPoint, payload, '/move').catch(reason => dataService.throwException(reason));
    }

    function removeDirectory(id) {
      return dataService.delete(directoryEndPoint, '/remove/' + id)
        .catch(reason => dataService.throwException(reason, 'Error deleting folder.'));
    }

    function updateToAllMedias(id, payload) {
      let type = 'Video Dimensions';
      if (payload.fps) {
        type = 'Frame Rate';
      }
      const path = `/${id}/medias`;
      return dataService.put(endPoint, payload, path)
        .catch(reason => dataService.throwException(reason, `Error updating ${type} to all Project Files. Refresh the page and try again.`));
    }

    function remove(id) {
      return dataService.delete(endPoint, '/' + id)
        .catch(reason => dataService.throwException(reason, 'Error deleting project.'));
    }

    function removeFromDashboard(id, projects, sharedProjects, directories) {
      return remove(id).then(() => {
        const myProjectIndex = _.findIndex(projects, {
          id: id
        });
        const sharedProjectIndex = _.findIndex(sharedProjects, {
          id: id
        });
        if (myProjectIndex !== -1) {
          // remove from folder in UI, if it contains a folder
          if (projects[myProjectIndex].directory) {
            alterDirectoryProjectsCount(directories, projects[myProjectIndex].directory.id, -1);
          }
          projects.splice(myProjectIndex, 1);
        }
        if (sharedProjectIndex !== -1) {
          sharedProjects.splice(sharedProjectIndex, 1);
        }
      });
    }

    function alterDirectoryProjectsCount(directories, directoryId, change) {
      const myDirectoryIndex = _.findIndex(directories, {
        id: directoryId
      });
      if (myDirectoryIndex !== -1) {
        directories[myDirectoryIndex].totalProjectCount += change;
      }
    }

    function removeProject(id) {
      return dataService.get(endPoint, '/remove/' + id)
        .then(() => notificationService.success('Project has been deleted successfully.', true));
    }

    function newSyncProject(payload) {
      return dataService.post(endPoint, payload, '/mobile')
        .then(response => sharedService.redirectToProject(response))
        .catch(reason => dataService.throwException(reason));
    }

    function buyCredit(payload) {
      return dataService.post(endPoint + '/checkout', payload).catch(reason => dataService.throwException(reason));
    }

  }
})();
